
	import {reactive, computed, onMounted} from 'vue'
	import {useStore} from 'vuex'
	import {useToast} from 'vue-toastification'
	import Modal from '../../components/Modal'
	import Validar from '../../util/Validadores'
	import {useI18n} from 'vue-i18n'

	export default {
		components: {Modal},
		setup(props, context) {
			const {tm} = useI18n()
			const store = useStore()
			const toast = useToast()
			const stateLocal = reactive({
				isLoadingBot: false,
				showModalCompra: false,
				inicioCompra: true,
				isLoadingCompra: false,
				formasPagamento: [],
				pagamento: 7,
				terceiro: '',
				cpfTerceiro: '',
				nota: false,
				loja: store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId],
				idClient: store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].idClient,
				endereco: {},
			})

			function enviarLinkPagamento(link) {
				var message = tm('msgPedido01') + link
				context.emit('enviarMsgChat', message)
			}

			function formataMoeda(value) {
				return new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency: 'BRL',
					minimumFractionDigits: 2,
				}).format(value)
			}

			const listaPedidos = computed(() => {
				return store.state.ath.listaPedidos[store.state.selectedChatQueue.chatId]
					? store.state.ath.listaPedidos[store.state.selectedChatQueue.chatId]
					: {}
			})

			const totalCarrinho = computed(() => {
				let arr = store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId]
				let soma = 0
				if (arr) {
					soma = arr.reduce(function (acumulador, el) {
						return acumulador + el.valor * el.qtd || 0
					}, 0)
				}
				return new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency: 'BRL',
					minimumFractionDigits: 2,
				}).format(soma)
			})

			const cpfCliente = computed(() => {
				return Validar.formatCnpjCpf(store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].cpf_Cnpj)
			})

			const nomeCLiente = computed(() => {
				return (
					store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].nameClient +
					' ' +
					store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].lastNameClient
				)
			})

			const celularCliente = computed(() => {
				return store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].cellphoneNumber
			})

			const enderecoCliente = computed(() => {
				if (stateLocal.endereco) {
					return (
						stateLocal.endereco.addressName +
						', ' +
						stateLocal.endereco.addressNumber +
						' - ' +
						stateLocal.endereco.complement +
						', ' +
						stateLocal.endereco.district +
						', ' +
						stateLocal.endereco.city +
						' - ' +
						stateLocal.endereco.state +
						'. CEP: ' +
						stateLocal.endereco.zipCode
					)
				} else {
					return ''
				}
			})

			const enderecoLoja = computed(() => {
				if (stateLocal.loja) {
					return stateLocal.loja.originAddress
				} else {
					return ''
				}
			})
			const lojaCliente = computed(() => {
				if (stateLocal.loja) {
					return stateLocal.loja.restaurantName
				} else {
					return ''
				}
			})

			const formaPagamento = computed(() => {
				let pag = stateLocal.formasPagamento.find(x => x.idPaymentMethod === stateLocal.pagamento)
				return pag.descriptionPaymentMethod || ''
			})

			function limparDados() {
				stateLocal.pagamento = 7
				stateLocal.terceiro = ''
				stateLocal.cpfTerceiro = ''
				stateLocal.nota = false
			}

			const getChats = async () => {
				await store.dispatch('getChats')
			}

			onMounted(() => {
				stateLocal.isLoading = false
			})

			return {
				stateLocal,
				listaPedidos,
				formataMoeda,
				enviarLinkPagamento,
				tm,
			}
		},
	}
