import Api from './Api'
import {environment} from '../environments/env'
import {route} from './routeDictionary'
import {routeV2} from './routes/bot.js'

export default {
	getToken(number) {
		if (number.startsWith('55')) {
			var response = Api.post(
				`${environment.apiBot}${route.AutenticateBot}`,
				{cellphoneNumber: number},
				{params: {loginClienteExterno: true}},
			)
			return response
		} else {
			var response = Api.post(
				`${environment.apiBot}${route.AutenticateBot}`,
				{deviceId: number},
				{params: {IdentificationChannelCode: 3, loginClienteExterno: true}},
			)
			return response
		}
	},
	GetClientBot(token) {
		return Api.get(`${environment.apiBot}${route.GetClientBot}`, {headers: {botToken: token}})
	},

	postPreCheckoutAddress(idExternalCart, idAddress, token, body, param) {
		console.info('passed info to postPreCheckoutAddress =>', {
			idExternalCart: idExternalCart,
			idAddress: idAddress,
			token: token,
			body: body,
			param: param,
		})

		try {
			const url = `${environment.apiBot}${route.postPreCheckoutAddress
				.replace('{id}', idExternalCart)
				.replace('{idAddress}', idAddress)}`

			const response = Api.post(url, body, {headers: {botToken: token}, params: param})

			return response
		} catch (error) {
			console.error('ApiBotService | postPreCheckoutAddress | error => ', error)
		}
	},
	PostMetodoEntregaEndereco(token, idPreorder, idAddress, arr, param) {
		return Api.post(
			`${environment.apiBot}${route.PostMetodoEntregaEndereco.replace('{id}', idPreorder).replace(
				'{idAddress}',
				idAddress,
			)}`,
			arr,
			{headers: {botToken: token}, params: param},
		)
	},
	GetClientAdressesBot(token) {
		return Api.get(`${environment.apiBot}${route.GetClientAdressesBot}`, {headers: {botToken: token}})
	},
	GetSessionsFromStore(token, idStore, param) {
		return Api.get(`${environment.apiBot}${route.GetSessionsFromStore.replace('{idStore}', idStore)}`, {
			headers: {botToken: token},
			params: param,
		})
	},
	GetStoresByRede(token, idRede, param) {
		return Api.get(`${environment.apiBot}${route.GetStoresByRede.replace('{id}', idRede)}`, {
			headers: {botToken: token},
			params: param,
		})
	},
	GetPedidoAbandono(token, idPedido) {
		return Api.get(`${environment.apiBot}${route.GetPedidoAbandono}/${idPedido}`, {headers: {botToken: token}})
	},
	GetPedidoFinalizado(token, idPedido) {
		return Api.get(`${environment.apiBot}${route.GetPedidoFinalizado}/${idPedido}`, {headers: {botToken: token}})
	},
	GetLoja(token, id) {
		return Api.get(`${environment.apiBot}${route.GetStores}/${id}`, {headers: {botToken: token}})
	},

	GetPedido(token, idLoja, idCartExterno) {
		try {
			const response = Api.get(`${environment.apiBot}${route.GetPedido}`, {
				headers: {botToken: token},
				params: {storeId: idLoja, idCartExterno: idCartExterno, carrinhoExterno: true},
			})

			return response
		} catch (error) {
			console.error('ApiBotService | GetPedido | error => ', error)
		}
	},

	GetProduto(token, idLoja, idProduto, param) {
		return Api.get(
			`${environment.apiBot}${route.GetProduto.replace('{id}', idLoja).replace('{productId}', idProduto)}`,
			{
				headers: {botToken: token},
				params: param,
			},
		)
	},
	GetLoja(token, idLoja) {
		return Api.get(`${environment.apiBot}${route.GetLoja.replace('{id}', idLoja)}`, {headers: {botToken: token}})
	},
	GetCampanha(token, idCampanha) {
		return Api.get(`${environment.apiBot}${route.GetCampanha.replace('{id}', idCampanha)}`, {
			headers: {botToken: token},
		})
	},
	GetCartonista(token, idRede) {
		return Api.get(`${environment.apiBot}${route.GetCartonista}`, {
			headers: {botToken: token},
			params: {chainStoreId: idRede},
		})
	},
	GetCampanhaCarrinho(token, idPreorder, idCampanha) {
		return Api.get(
			`${environment.apiBot}${route.GetItemCarrinhoCampanha.replace('{id}', idPreorder).replace(
				'{idCampaign}',
				idCampanha,
			)}`,
			{headers: {botToken: token}},
		)
	},
	GetDadosAdicionaisPedido(token, idPedido) {
		return Api.get(`${environment.apiBot}${route.GetDadosAdicionaisPedido.replace('{id}', idPedido)}`, {
			headers: {botToken: token},
		})
	},
	GetProductsBySessionAndStore(token, param) {
		return Api.get(
			`${environment.apiBot}${route.GetProductsBySessionAndStore.replace('{idStore}', 0).replace(
				'{idSession}',
				0,
			)}`,
			{
				headers: {botToken: token},
				params: param,
			},
		)
	},
	ChangeLoja(token, idPedido, idLoja) {
		return Api.put(
			`${environment.apiBot}${route.ChangeLoja.replace('{id}', idPedido).replace('{storeId}', idLoja)}`,
			null,
			{
				headers: {botToken: token},
			},
		)
	},
	DeleteProduto(id, token, param, body) {
		return Api.delete(`${environment.apiBot}${route.GetPedido}/${id}`, {
			headers: {botToken: token},
			params: param,
			data: body,
		})
	},
	GetCarrinhoPre(token, idStore, idPreOrder, idRetailer) {
		return Api.get(
			`${environment.apiBot}${route.GetPedido.replace('{idStore}', idStore)
				.replace('{idPreOrder}', idPreOrder)
				.replace('{idRetailer}', idRetailer)}`,
			{headers: {botToken: token}},
		)
	},
	AddProduto(token, body, param) {
		return Api.post(`${environment.apiBot}${route.GetPedido}`, body, {headers: {botToken: token}, params: param})
	},
	GetProductsBySessionAndStoreQuery(token, idStore, idSession, param) {
		return Api.get(
			`${environment.apiBot}${route.GetProductsBySessionAndStoreQuery.replace('{idStore}', idStore).replace(
				'{idSession}',
				idSession,
			)}`,
			{headers: {botToken: token}, params: param},
		)
	},
	DeletePedido(token, idLoja) {
		return Api.delete(`${environment.apiBot}${route.GetPedido}`, {
			headers: {botToken: token},
			params: {storeId: idLoja},
		})
	},
	GetPrePedido(token, idLoja, webSession, PreOrder, Retailer) {
		return Api.get(
			`${environment.apiBot}${route.GetPedido.replace('{storeId}', idLoja)
				.replace('{idPreOrder}', PreOrder)
				.replace('{idWebSession}', webSession)
				.replace('{idRetailer}', Retailer)}`,
			{headers: {botToken: token}},
		)
	},
	GetProductDetails(token, idLoja, productId) {
		return Api.get(
			`${environment.apiBot}${route.GetProdutDetails.replace('{idLoja}', idLoja).replace(
				'{productId}',
				productId,
			)}`,
			{
				headers: {botToken: token},
				params: {produtoExterno: true},
			},
		)
	},
	FinalizarPedido(token, id, arr) {
		return Api.put(`${environment.apiBot}${route.GetPedido2}/${id}`, arr, {
			headers: {botToken: token},
			params: {pdvOrderCreate: false, idChannel: 2},
		})
	},
	GetPaymentMethods(token, idLoja) {
		return Api.get(`${environment.apiBot}${route.GetPaymentMethods.replace('{idLoja}', idLoja)}`, {
			headers: {botToken: token},
		})
	},
	GetProductsByQuery(token, idLoja, param) {
		return Api.get(`${environment.apiBot}${route.GetProductsByQuery.replace('{id}', idLoja)}`, {
			headers: {botToken: token},
			params: {...param, produtoExterno: true},
		})
	},
	AddOrderAddendum(token, idPedido, arr) {
		return Api.post(`${environment.apiBot}${route.OrderAddendum.replace('{id}', idPedido)}`, arr, {
			headers: {botToken: token},
		})
	},
	PostEnderecoCliente(token, arr) {
		return Api.post(`${environment.apiBot}${route.PostEnderecoCliente}`, arr, {headers: {botToken: token}})
	},
	FinalizarPedidoVetx(token, arr) {
		return Api.get(`${environment.apiBot}${route.GetPedido}`, {headers: {botToken: token}, params: arr})
	},
	PostShortenerUrlVtex(token, body) {
		return Api.post(`${environment.apiBot}${route.PostUrlCheckout}`, body, {headers: {botToken: token}})
	},

	async getDeliveryMethods({token, externalVtexId, zipCode}) {
		try {
			const {data} = await Api.post(
				`${environment.apiBot}${routeV2.simulations.replace('{id}', externalVtexId)}`,
				null,
				{
					headers: {botToken: token},
					params: {zipCode: zipCode},
				},
			)

			return {deliverys: data.deliverys, withdrawals: data.withdrawals}
		} catch (error) {
			console.error('getDeliveryMethods | error => ', error)
		}
	},
}
