import {environment} from '../environments/env'
import Api from './Api'
import {route} from './routeDictionary'

export default {
	ConsultaEndereco(cep) {
		return Api.get(`${environment.apiUrl}${route.ConsultaEndereco}`, {params: {cep: cep}})
	},
	GetEstados(id) {
		return Api.get(`${environment.apiUrl}${route.GetEstados}`, {params: {codPais: id}})
	},
	GetCidades(id) {
		return Api.get(`${environment.apiUrl}${route.GetListaCidades}`, {params: {codEstado: id}})
	},
	GetPaises() {
		return Api.get(`${environment.apiUrl}${route.GetPaises}`)
	},
}
