
	import {ref} from 'vue'
	import {useToast} from 'vue-toastification'
	import {useI18n} from 'vue-i18n'

	export default {
		name: 'InputChips',
		props: {
			chips: {
				type: Array,
				default: [],
			},
			set: {
				type: Boolean,
				default: true,
			},
		},
		setup(props) {
			const {tm} = useI18n()
			const toast = useToast()
			const currentInput = ref('')

			function saveChip($event) {
				if ($event.key === 'Tab' && !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(currentInput.value)) {
					currentInput.value = null
					return
				}
				if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(currentInput.value)) {
					toast.warning(tm('msgEmail01'))
					return
				}
				;((props.set && props.chips.indexOf(currentInput.value) === -1) || !set) &&
					props.chips.push(currentInput.value)
				currentInput.value = null
			}

			function deleteChip(index) {
				props.chips.splice(index, 1)
			}

			function backspaceDelete({which}) {
				which == 8 && currentInput.value === '' && props.chips.splice(props.chips.length - 1)
			}

			return {
				tm,
				props,
				currentInput,
				saveChip,
				deleteChip,
				backspaceDelete,
			}
		},
	}
