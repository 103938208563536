
	import ApiBotService from '../../services/ApiBotService'
	import constants from '../../util/constants'
	import validation from '../../util/Validadores'

	import Secoes from './Secoes'
	import Produtos from './Produtos'
	import Carrinho from './Carrinho'
	import Pedido from './Pedido'
	import Modal from '../../components/Modal'

	
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { ref, reactive, computed, watch, onMounted } from 'vue';
export default {
		components: {Secoes, Produtos, Carrinho, Pedido, Modal},
		props: {
			nomeSecao: {
				type: String,
				default: '',
			},
			idSecao: {
				type: Number,
				default: 0,
			},
		},
		setup(props, context) {
			const {tm} = useI18n()
			const store = useStore()
			const toast = useToast()

			const productToAddOnCart = ref(null)
			const loading = reactive({
				addToCart: false,
			})

			const options = reactive([
				{label: 'Seções', value: 'secoes'},
				{label: 'Produtos', value: 'produtos'},
				{label: 'Carrinho', value: 'carrinho'},
				{label: 'Pedido', value: 'pedido'},
			])

			const stateLocal = reactive({
				termoBusca: '',
				semEstoque: sessionStorage.getItem('isEmbedded')
					? JSON.parse(sessionStorage.getItem('isEmbedded'))
					: false,
				sortimentoSelecionado: -1,
				listaProdutos: [],
				isLoading: false,
				listaSortimento: null,
				showModalAdicionarCarrinho: false,
				showModalImagem: false,
				showModalEstoque: false,
				showModalConfirmacaoEnviarTodos: false,
				propriedadesModalAdicionarCarrinho: null,
				propriedadesModalImagem: null,
				propriedadesModalEstoque: null,
				estoqueSelecionado: null,
				paginaBusca: 1,
				paginaFinal: 0,
				totalRegistrosBusca: 0,
				restoLista: 0,
				showPagina: 10,
				CanalVendas: 2,
				produtosBusca: [],
				idProduto: 0,
				isEmbedded: sessionStorage.getItem('isEmbedded')
					? JSON.parse(sessionStorage.getItem('isEmbedded'))
					: false,
			})
			const componentKeySecoes = ref(0)
			const componentKeyCarrinho = ref(0)
			const sortimentoSelected = ref(null)

			const mensagemCampanhaUsuario = computed(() => {
				if (store.state.campanhaUsuario && store.state.campanhaUsuario.length > 0) {
					return store.state.campanhaUsuario[0].msg_Atendimento_Campanha_var.trim()
				}
				return ''
			})

			watch(sortimentoSelected, newVal => {
				handleFiltrarSortimento(newVal)
			})

			function handleCancelAddCart() {
				stateLocal.showModalAdicionarCarrinho = false
				stateLocal.isLoading = false
			}

			function validateQuantity() {
				const enteredQuantity = parseInt(stateLocal.estoqueSelecionado.quantidadeSolicitada)
				const maxQuantity = parseInt(stateLocal.estoqueSelecionado.quantidadeEstoque)

				if (enteredQuantity === 0 || isNaN(enteredQuantity)) {
					stateLocal.estoqueSelecionado.quantidadeSolicitada = 1
				} else if (enteredQuantity > maxQuantity) {
					stateLocal.estoqueSelecionado.quantidadeSolicitada = maxQuantity
				}
			}

			async function getSortProduct(currentProduct) {
				const token = store.state.ath.apiBotHub[store.state.selectedChatQueue.chatId].token
				const params = {
					codSessao: `skuRefId=${currentProduct.codigoProduto.substring(0, 6)}`,
					nomeSessao: null,
					descSessao: null,
					produtosExternos: true,
					incluirSortimento: true,
					from: 0,
					show: 49,
				}

				const sortedProducts = await ApiBotService.GetProductsBySessionAndStore(token, params)

				const selectedProduct = sortedProducts.data.products.find(
					product => product.idProduct == currentProduct.idProduto,
				)

				return selectedProduct
			}

			async function handleCarregarModalAdicionarItemCarrinho(produto) {
				try {
					stateLocal.isLoading = true

					stateLocal.produtosBusca = [await getSortProduct(produto)]

					productToAddOnCart.value = stateLocal.produtosBusca[0]

					const listaEstoqueSortimento = stateLocal.produtosBusca.map(produtoInterno => ({
						quantidadeEstoque: produtoInterno.quantityProductStock,
						sortimento: produtoInterno.productSpecification,
						quantidadeSolicitada: 1,
						precoProduto: produto.precoProduto,
						produto: produtoInterno,
					}))

					stateLocal.estoqueSelecionado = listaEstoqueSortimento.find(
						estoqueSortimento => estoqueSortimento.sortimento === produto.sortimento,
					)

					stateLocal.showModalAdicionarCarrinho = true
				} catch (error) {
					console.error('handleCarregarModalAdicionarItemCarrinho | error => ', error)
				} finally {
					stateLocal.isLoading = false
				}
			}

			function setPropriedadesModalProduto(propriedades) {
				stateLocal.propriedadesModalProdutos = propriedades
				stateLocal.paginaFinal = stateLocal.propriedadesModalProdutos.paginaFinal
				stateLocal.totalRegistrosBusca = stateLocal.propriedadesModalProdutos.totalRegistrosBusca
				stateLocal.paginaBusca = stateLocal.propriedadesModalProdutos.paginaBusca
				sortimentoSelected.value = null
				if (stateLocal.propriedadesModalProdutos.listaProduto.length > 0) {
					stateLocal.showModalEnviarProduto = true
				} else {
					toast.warning(
						'Poxa, nesse momento não temos produtos nessa seção... Mas não se preocupe, temos uma variedade de outros produtos e ofertas para você!',
					)
				}
			}

			async function handleAdicionarItemCarrinho() {
				const chatId = store.state.selectedChatQueue.chatId
				const token = store.state.ath.apiBotHub[chatId].token
				const idLojaSelecionada = store.state.ath.selectedLoja[chatId]

				try {
					loading.addToCart = true

					let response = await ApiBotService.GetProductDetails(token, idLojaSelecionada, stateLocal.idProduto)
					let sortementoItem = null

					if (response.data !== '') {
						response.data.productSubItems.forEach(subItem => {
							if (stateLocal.estoqueSelecionado.sortimento === subItem.productSubItemDescription) {
								sortementoItem = subItem
							}
						})
					}

					let produtoAdicionar = {
						id: productToAddOnCart.value.idProduct,
						sku: productToAddOnCart.value.productCode,
						desc: productToAddOnCart.value.productItemName,
						valor: productToAddOnCart.value.productValue,
						previousValue: productToAddOnCart.value.previousValue,
						estoque: productToAddOnCart.value.quantityProductStock,
						qtd: parseInt(stateLocal.estoqueSelecionado.quantidadeSolicitada),
						idItem: 0,
						idSubItem: 0,
						desconto: 0,
						sortimento: sortementoItem,
					}

					if (response.status === 200) {
						for (const produtoInterno of response.data.productSubItems) {
							if (stateLocal.estoqueSelecionado.sortimento === produtoInterno.productSubItemDescription) {
								produtoAdicionar.idItem = produtoInterno.idProductItem
								produtoAdicionar.idSubItem = produtoInterno.idProductSubItem

								break
							}
						}
					}

					const hasIdExternoVtexProperty = !!store.state.selectedChatQueue.customVariables['@idExternoVtex']

					await store.dispatch('ath/addProdutoCarrinho', {
						produto: produtoAdicionar,
						chatId: chatId,
						preOrder: true,
						idExterno: hasIdExternoVtexProperty
							? store.state.selectedChatQueue.customVariables['@idExternoVtex']
							: null,
					})

					stateLocal.showModalAdicionarCarrinho = false

					toast.info('Item adicionado ao carrinho.')
				} catch (error) {
					console.error('handleAdicionarItemCarrinho | error: ', error)

					if (error.status === 403) {
						toast.warning('Quantidade é maior que estoque disponível.')
					} else if (error.status === 401) {
						toast.warning('Solicitação não aplicada porque não possui credenciais de autenticação válidas!')
					} else {
						toast.error('Ocorreu um erro ao incluir o produto no carrinho!')
					}
				} finally {
					loading.addToCart = false
				}
			}

			function handleSelectProduct(productId) {
				stateLocal.propriedadesModalProdutos.listaProduto.forEach(produto => {
					if (produto.idProduto == productId) {
						produto.selecionado = !produto.selecionado
					}
				})
			}

			function handleEnviarTodosProdutosConfirmacao() {
				stateLocal.showModalConfirmacaoEnviarTodos = true
			}

			function handleEnviarTodosProdutos() {
				var produtosSelecionados = stateLocal.propriedadesModalProdutos.listaProduto.filter(function (produto) {
					return produto.visivel == true
				})
				context.emit('enviarDescricaoProduto', produtosSelecionados)
				stateLocal.showModalConfirmacaoEnviarTodos = false
				stateLocal.showModalEnviarProduto = false
			}

			function handleEnviarProdutoSelecionado(produto) {
				var produtos = []
				produtos.push(produto)
				context.emit('enviarDescricaoProduto', produtos)
			}

			function handleEnviarProdutosSelecionados() {
				var produtosSelecionados = stateLocal.propriedadesModalProdutos.listaProduto.filter(function (produto) {
					return produto.selecionado == true
				})

				if (produtosSelecionados.length > 0) {
					context.emit('enviarDescricaoProduto', produtosSelecionados)
					stateLocal.showModalEnviarProduto = false
				} else {
					toast.warning(tm('msgAth14'))
				}
			}

			function handleFiltrarSortimento(sortimento) {
				stateLocal.propriedadesModalProdutos.listaProduto.forEach(produto => {
					if (sortimento === null || produto.sortimento === sortimento) {
						produto.visivel = true
					} else {
						produto.visivel = false
						produto.selecionado = false
					}
				})
			}

			const lojasCliente = computed(() => {
				return store.state.ath.lojasCliente[store.state.selectedChatQueue.chatId]
			})

			let nomeLoja = computed(() => {
				if (store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId]) {
					return store.state.ath.selectedLojaDados[store.state.selectedChatQueue.chatId].restaurantName
				} else {
					return ''
				}
			})
			function nomeLojaVtex() {
				return store.state.selectedChatQueue.customVariables['@lojaAtendimentoNome']
			}
			async function buscarItensAdicionaisFrente() {
				if (
					!stateLocal.isLoading &&
					stateLocal.totalRegistrosBusca > stateLocal.propriedadesModalProdutos.listaProduto.length &&
					stateLocal.sortimentoSelecionado == -1
				) {
					stateLocal.isLoading = true
					stateLocal.paginaBusca += 1

					let chatId = store.state.selectedChatQueue.chatId
					let idLoja = store.state.ath.selectedLoja[chatId]
					let token = store.state.ath.apiBotHub[chatId].token
					let idSession = stateLocal.propriedadesModalProdutos.idSession
					let from = stateLocal.paginaBusca
					let show = stateLocal.showPagina
					let idCanalVendas = stateLocal.CanalVendas
					stateLocal.produtosBusca = []

					const response = await ApiBotService.GetProductsBySessionAndStore(
						token,
						idLoja,
						idSession,
						idCanalVendas,
						from,
						show,
						(produtoExterno = true),
					)
					stateLocal.isLoading = false

					var produtos = []
					const listaProduto = response.data.products
					for (var indice = 0; indice < listaProduto.length; indice++) {
						if (listaProduto[indice].quantityProductStock > 0) {
							produtos[produtos.length] = {
								idProduto: listaProduto[indice].idProduct,
								codigoProduto: listaProduto[indice].productCode,
								descricaoProduto: listaProduto[indice].sessionDescription,
								nomeProduto: listaProduto[indice].productName,
								imagemProduto: listaProduto[indice].imageProduct,
								precoProduto: listaProduto[indice].productValue.toLocaleString('pt-br', {
									minimumFractionDigits: 2,
								}),
								sortimento: listaProduto[indice].productItemName,
								visivel: true,
								selecionado: false,
							}
						}
					}
					var sortimento = [...new Set(listaProduto.map(item => item.productItemName))]

					if (sortimento.length == 1 && sortimento[0] == null) {
						sortimento = null
					} else {
						sortimento.sort()
					}

					var propriedades = {
						nomeSecao: stateLocal.propriedadesModalProdutos.nomeSecao,
						idSession: idSession,
						listaSortimento: sortimento,
						listaProduto: produtos,
						totalRegistrosBusca: stateLocal.totalRegistrosBusca,
						restoLista: stateLocal.restoLista,
						paginaFinal: stateLocal.paginaFinal,
						paginaBusca: stateLocal.paginaBusca,
					}

					setPropriedadesModalProduto(propriedades)
					stateLocal.isLoading = false
				}
			}

			async function buscarItensAdicionaisFrenteUltimo() {
				if (
					!stateLocal.isLoading &&
					stateLocal.totalRegistrosBusca > stateLocal.propriedadesModalProdutos.listaProduto.length &&
					stateLocal.sortimentoSelecionado == -1
				) {
					stateLocal.isLoading = true
					stateLocal.paginaBusca = stateLocal.paginaFinal

					let chatId = store.state.selectedChatQueue.chatId
					let idLoja = store.state.ath.selectedLoja[chatId]
					let token = store.state.ath.apiBotHub[chatId].token
					let idSession = stateLocal.propriedadesModalProdutos.idSession
					let idCanalVendas = stateLocal.CanalVendas
					let show = stateLocal.showPagina
					let from = stateLocal.paginaBusca

					stateLocal.produtosBusca = []
					const response = await ApiBotService.GetProductsBySessionAndStore(
						token,
						idLoja,
						idSession,
						idCanalVendas,
						from,
						show,
					)

					var produtos = []
					const listaProduto = response.data.products
					for (var indice = 0; indice < listaProduto.length; indice++) {
						if (listaProduto[indice].quantityProductStock > 0) {
							produtos[produtos.length] = {
								idProduto: listaProduto[indice].idProduct,
								codigoProduto: listaProduto[indice].productCode,
								descricaoProduto: listaProduto[indice].sessionDescription,
								nomeProduto: listaProduto[indice].productName,
								imagemProduto: listaProduto[indice].imageProduct,
								precoProduto: listaProduto[indice].productValue.toLocaleString('pt-br', {
									minimumFractionDigits: 2,
								}),
								sortimento: listaProduto[indice].productItemName,
								visivel: true,
								selecionado: false,
							}
						}
					}
					var sortimento = [...new Set(listaProduto.map(item => item.productItemName))]

					if (sortimento.length == 1 && sortimento[0] == null) {
						sortimento = null
					} else {
						sortimento.sort()
					}

					var propriedades = {
						nomeSecao: stateLocal.propriedadesModalProdutos.nomeSecao,
						idSession: idSession,
						listaSortimento: sortimento,
						listaProduto: produtos,
						totalRegistrosBusca: stateLocal.totalRegistrosBusca,
						restoLista: stateLocal.restoLista,
						paginaFinal: stateLocal.paginaFinal,
						paginaBusca: stateLocal.paginaBusca,
					}

					setPropriedadesModalProduto(propriedades)
					stateLocal.isLoading = false
				}
			}

			async function buscarItensAdicionaisVoltaPrimeiro() {
				if (
					!stateLocal.isLoading &&
					stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
					stateLocal.sortimentoSelecionado == -1
				) {
					stateLocal.isLoading = true
					stateLocal.paginaBusca = 1

					let chatId = store.state.selectedChatQueue.chatId
					let idLoja = store.state.ath.selectedLoja[chatId]
					let token = store.state.ath.apiBotHub[chatId].token
					let idSession = stateLocal.propriedadesModalProdutos.idSession
					let idCanalVendas = stateLocal.CanalVendas
					let show = stateLocal.showPagina
					let from = stateLocal.paginaBusca

					stateLocal.produtosBusca = []
					const response = await ApiBotService.GetProductsBySessionAndStore(
						token,
						idLoja,
						idSession,
						idCanalVendas,
						from,
						show,
					)

					var produtos = []
					const listaProduto = response.data.products
					for (var indice = 0; indice < listaProduto.length; indice++) {
						if (listaProduto[indice].quantityProductStock > 0) {
							produtos[produtos.length] = {
								idProduto: listaProduto[indice].idProduct,
								codigoProduto: listaProduto[indice].productCode,
								descricaoProduto: listaProduto[indice].sessionDescription,
								nomeProduto: listaProduto[indice].productName,
								imagemProduto: listaProduto[indice].imageProduct,
								precoProduto: listaProduto[indice].productValue.toLocaleString('pt-br', {
									minimumFractionDigits: 2,
								}),
								sortimento: listaProduto[indice].productItemName,
								visivel: true,
								selecionado: false,
							}
						}
					}
					var sortimento = [...new Set(listaProduto.map(item => item.productItemName))]

					if (sortimento.length == 1 && sortimento[0] == null) {
						sortimento = null
					} else {
						sortimento.sort()
					}

					var propriedades = {
						nomeSecao: stateLocal.propriedadesModalProdutos.nomeSecao,
						idSession: idSession,
						listaSortimento: sortimento,
						listaProduto: produtos,
						totalRegistrosBusca: stateLocal.totalRegistrosBusca,
						restoLista: stateLocal.restoLista,
						paginaFinal: stateLocal.paginaFinal,
						paginaBusca: stateLocal.paginaBusca,
					}

					setPropriedadesModalProduto(propriedades)
					stateLocal.isLoading = false
				}
			}

			async function buscarItensAdicionaisVolta() {
				if (
					!stateLocal.isLoading &&
					stateLocal.totalRegistrosBusca > stateLocal.produtosBusca.length &&
					stateLocal.sortimentoSelecionado == -1
				) {
					stateLocal.isLoading = true
					stateLocal.paginaBusca -= 1

					let chatId = store.state.selectedChatQueue.chatId
					let idLoja = store.state.ath.selectedLoja[chatId]
					let token = store.state.ath.apiBotHub[chatId].token
					let idSession = stateLocal.propriedadesModalProdutos.idSession
					let idCanalVendas = stateLocal.CanalVendas
					let show = stateLocal.showPagina
					let from = stateLocal.paginaBusca

					stateLocal.produtosBusca = []
					const response = await ApiBotService.GetProductsBySessionAndStore(
						token,
						idLoja,
						idSession,
						idCanalVendas,
						from,
						show,
					)

					var produtos = []
					const listaProduto = response.data.products
					for (var indice = 0; indice < listaProduto.length; indice++) {
						if (listaProduto[indice].quantityProductStock > 0) {
							produtos[produtos.length] = {
								idProduto: listaProduto[indice].idProduct,
								codigoProduto: listaProduto[indice].productCode,
								descricaoProduto: listaProduto[indice].sessionDescription,
								nomeProduto: listaProduto[indice].productName,
								imagemProduto: listaProduto[indice].imageProduct,
								precoProduto: listaProduto[indice].productValue.toLocaleString('pt-br', {
									minimumFractionDigits: 2,
								}),
								sortimento: listaProduto[indice].productItemName,
								visivel: true,
								selecionado: false,
							}
						}
					}
					var sortimento = [...new Set(listaProduto.map(item => item.productItemName))]

					if (sortimento.length == 1 && sortimento[0] == null) {
						sortimento = null
					} else {
						sortimento.sort()
					}

					var propriedades = {
						nomeSecao: stateLocal.propriedadesModalProdutos.nomeSecao,
						idSession: idSession,
						listaSortimento: sortimento,
						listaProduto: produtos,
						totalRegistrosBusca: stateLocal.totalRegistrosBusca,
						restoLista: stateLocal.restoLista,
						paginaFinal: stateLocal.paginaFinal,
						paginaBusca: stateLocal.paginaBusca,
					}

					setPropriedadesModalProduto(propriedades)
					stateLocal.isLoading = false
				}
			}

			onMounted(() => {
				stateLocal.inicioTroca = true
				stateLocal.isLoadingTroca = false
				stateLocal.isEmbedded = sessionStorage.getItem('isEmbedded')
					? JSON.parse(sessionStorage.getItem('isEmbedded'))
					: false
			})

			function setOpcao(value) {
				store.commit('ath/setOpcao', value)
			}

			async function verificaEstoque() {
				stateLocal.nomeTroca = stateLocal.lojaSelecionada.restaurantName
				stateLocal.isLoadingTroca = true
				stateLocal.carrinhoCliente = store.state.ath.carrinhoCliente[store.state.selectedChatQueue.chatId]
				if (
					stateLocal.lojaSelecionada.restaurantId !=
						store.state.ath.selectedLoja[store.state.selectedChatQueue.chatId] &&
					stateLocal.carrinhoCliente
				) {
					for (let [i, item] of stateLocal.carrinhoCliente.entries()) {
						item.estoque = await store.dispatch('ath/getEstoque', {
							produto: item,
							chatId: store.state.selectedChatQueue.chatId,
							idLoja: stateLocal.lojaSelecionada.restaurantId,
						})
					}
				}
				stateLocal.isLoadingTroca = false
				stateLocal.inicioTroca = false
			}

			async function buscarLoja() {
				let chatId = store.state.selectedChatQueue.chatId
				let token = store.state.ath.apiBotHub[chatId].token

				stateLocal.isLoadingTroca = true
				try {
					let idrede = process.env.VUE_APP_COD_REDE ? process.env.VUE_APP_COD_REDE : constants.ID_REDE
					const response = await ApiBotService.GetStoresByRede(token, idrede, {
						zipCode: stateLocal.cepBusca,
					})
					stateLocal.listaLojaPesquisa = response.data
				} catch (ex) {
					if (ex.status === 404) {
						toast.warning(tm('msgAth15'))
					}
				}
				stateLocal.isLoadingTroca = false
			}

			function enviarMsgChat(msg) {
				context.emit('enviarMsgChat', msg)
			}

			async function enviarCatalogo(secao) {
				await context.emit('enviarCatalogo', secao)
			}

			function forceRerender(opcao) {
				if (opcao) {
					if (opcao === 'carrinho') {
						componentKeyCarrinho.value += 1
					} else if (opcao === 'secoes') {
						componentKeySecoes.value += 1
					}
				}
			}

			return {
				mensagemCampanhaUsuario,
				store,
				stateLocal,
				setOpcao,
				lojasCliente,
				nomeLoja,
				nomeLojaVtex,
				props,
				setPropriedadesModalProduto,
				handleSelectProduct,
				handleFiltrarSortimento,
				handleEnviarProdutosSelecionados,
				handleCarregarModalAdicionarItemCarrinho,
				handleEnviarTodosProdutos,
				handleEnviarTodosProdutosConfirmacao,
				handleAdicionarItemCarrinho,
				enviarMsgChat,
				verificaEstoque,
				handleEnviarProdutoSelecionado,
				buscarLoja,
				enviarCatalogo,
				componentKeySecoes,
				componentKeyCarrinho,
				forceRerender,
				buscarItensAdicionaisVoltaPrimeiro,
				buscarItensAdicionaisFrente,
				buscarItensAdicionaisFrenteUltimo,
				buscarItensAdicionaisVolta,
				tm,
				sortimentoSelected,
				handleCancelAddCart,
				productToAddOnCart,
				loading,
				options,
				validation,
				validateQuantity,
			}
		},
	}
