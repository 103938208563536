import {useToast} from 'vue-toastification'
import {createStore} from 'vuex'
import ApiBotService from '../services/ApiBotService'
import CampanhaService from '../services/CampanhaService'
import ChatService from '../services/ChatService'
import HermesApiService from '../services/HermesApiService'
import ListService from '../services/ListService'
import NumeroEnterpriseService from '../services/NumeroEnterpriseService'
import UsuarioService from '../services/UsuarioService'
import ClienteService from '../services/ClienteService'
import CalendarioService from '../services/CalendarioService'
import Decipher from '../util/Decipher'
import constants from '../util/constants'
import ath from './modules/ath'
import cliente from './modules/cliente'
import oms from './modules/oms'
import {mutationsTypes} from './mutation-types'

const signalR = require('@microsoft/signalr')
const toast = useToast()

export default createStore({
	state: {
		usuario: null,
		navTab: mutationsTypes.CHATS,
		appBar: '',
		mainTab: false,
		chatsTableData: [],
		messages: [],
		hermesClientsHub: [],
		selectedChatQueue: null,
		minhasCampanhas: [],
		listConfiguracoes: [],
		hermesClientsHub: [],
		wsChatsHub: [],
		numeroEnterpriseMap: [],
		numeroEnterpriseMapMedia: [],
		selectedHubConnection: null,
		listStatus: [],
		messagesOld: [],
		minhasAgendas: [],
		clientesNotFound: [],
		campanhaUsuario: null,
		cartonista: false,
		corTema: '',
		loading: {
			token: false,
		},
	},
	mutations: {
		[mutationsTypes.CHATS](state) {
			state.navTab = mutationsTypes.CHATS
		},
		[mutationsTypes.PROFILE](state) {
			state.navTab = mutationsTypes.PROFILE
		},
		[mutationsTypes.TRANSLATOR](state) {
			state.appBar = mutationsTypes.TRANSLATOR
		},
		[mutationsTypes.NOTES](state) {
			state.appBar = mutationsTypes.NOTES
		},
		[mutationsTypes.TODO](state) {
			state.appBar = mutationsTypes.TODO
		},
		[mutationsTypes.SETTINGS](state) {
			state.appBar = mutationsTypes.SETTINGS
		},
		[mutationsTypes.ATENDIMENTO](state) {
			state.appBar = mutationsTypes.ATENDIMENTO
		},
		[mutationsTypes.HISTORICO](state) {
			state.appBar = mutationsTypes.HISTORICO
		},
		[mutationsTypes.CLIENTE](state) {
			state.appBar = mutationsTypes.CLIENTE
		},
		[mutationsTypes.AGENDA](state) {
			state.appBar = mutationsTypes.AGENDA
		},
		[mutationsTypes.MARKETPLACE](state) {
			state.appBar = mutationsTypes.MARKETPLACE
		},
		appBarClear(state) {
			state.appBar = ''
		},
		setUsuario(state, usuario) {
			state.usuario = usuario
		},
		setChatsTable(state, chats) {
			state.chatsTableData = chats
		},
		setChatBody(state, chatQueue) {
			state.selectedChatQueue = chatQueue
		},
		setChatMessages(state, messages) {
			state.messages = messages
		},
		setMainTab(state, value) {
			state.mainTab = value
		},
		setMinhasCampanhas(state, campanhas) {
			state.minhasCampanhas = campanhas
		},
		setStatusUsuario(state, id) {
			state.usuario.statusId = id
		},
		setListConfiguracoes(state, listConfiguracoes) {
			state.listConfiguracoes = listConfiguracoes
		},
		setHubConnection(state, hubaddr) {
			state.selectedHubConnection = hubaddr
		},
		setWsChatsHub(state, chatsHubs) {
			state.wsChatsHub = chatsHubs
		},
		setlistStatus(state, list) {
			state.listStatus = list
		},
		setChatMessagesOld(state, messages) {
			state.messagesOld = messages
		},
		setMinhasAgendas(state, agendas) {
			state.minhasAgendas = agendas
		},
		setClientesNotFound(state, clientes) {
			state.clientesNotFound = clientes
		},
		setCampanhaUsuario(state, campanha) {
			state.campanhaUsuario = campanha
		},
		setCartonista(state, cartonista) {
			state.cartonista = cartonista
		},
		setCorTema(state, data) {
			state.corTema = data
		},
	},
	actions: {
		clearStore(context) {
			context.commit('setChatsTable', [])
			context.commit('setChatBody', null)
			context.commit('appBarClear')
			context.commit('setMainTab', false)
			context.commit('setMinhasCampanhas', [])
			context.commit('setChatMessages', [])
			context.commit('setChatMessagesOld', [])
			context.commit('setUsuario', null)
			context.commit(mutationsTypes.CHATS)
			context.commit('setWsChatsHub', [])
			context.commit('setHubConnection', null)
			context.commit('setlistStatus', [])
			context.commit('ath/setApiBotHub', [])
			context.commit('ath/setEnderecosCliente', [])
			context.commit('ath/setSecoesLoja', [])
			context.commit('ath/setLojaList', [])
			context.commit('ath/setLojasCliente', [])
			context.commit('ath/setCarrinhoList', [])
			context.commit('ath/setIdExternoVtex', [])
			context.commit('ath/setListaPedidos', [])
			context.commit('ath/setPedidos', [])
			context.commit('ath/setPedidoFinalizado', [])
			context.commit('ath/setLojaDadosList', [])
			context.commit('cliente/setDadosCliente', [])
			context.commit('setMinhasAgendas', [])
			context.commit('setClientesNotFound', [])
			context.commit('oms/setClienteProfile', [])
		},

		async verificaStatus(context, param) {
			if (context.state.listStatus) {
				let stausDisp = context.state.listStatus.find(x => x.nome.toLowerCase() == 'disponível')
				if (stausDisp) {
					await context.dispatch('updateStatus', {id: stausDisp.id, tm: param.tm})
				}
			}
		},

		async getListStatus(context) {
			context.state.listStatus = []
			if (context.state.usuario.empresaId) {
				try {
					const response = await ListService.getListStatus(context.state.usuario.empresaId)
					context.state.listStatus = response.data
				} catch (error) {
					console.debug(error)
				}
			}
		},

		async getCampanha(context, chatId) {
			//let campanha = context.state.minhasCampanhas.find(x => x.nome == context.state.selectedChatQueue.queueId);
			context.commit('setCampanhaUsuario', null)
			let idrede = process.env.VUE_APP_COD_REDE ? process.env.VUE_APP_COD_REDE : constants.ID_REDE
			let token = context.state.ath.apiBotHub[chatId] ? context.state.ath.apiBotHub[chatId].token : null
			try {
				let cartonista = await ApiBotService.GetCartonista(token, idrede)

				if (cartonista.status === 200) {
					context.commit('setCartonista', true)
					context.commit('setCampanhaUsuario', null)
				}
			} catch (error) {
				console.error('GetCartonista | error => ', error)
				try {
					let camp = await ApiBotService.GetCampanha(token, idrede)
					context.commit('setCartonista', false)
					context.commit('setCampanhaUsuario', camp.data.campain_Exists_List)
				} catch (error) {
					console.error('getCampanha | error => ', error)
				}
			}
		},

		async updateStatus(context, param) {
			try {
				const response = await UsuarioService.UpdateStatus({
					usuarioId: context.state.usuario.id,
					empresaId: context.state.usuario.empresaId,
					statusId: param.id,
				})
				context.commit('setStatusUsuario', param.id)
				localStorage.setItem('usuario', JSON.stringify(context.state.usuario))
			} catch (error) {
				console.debug(error)
				toast.warning(param.tm('erro06'))
			}
		},

		async getChats(context, param) {
			if (localStorage.getItem('usuario')) {
				try {
					let usuario = JSON.parse(localStorage.getItem('usuario'))
					const chatResponse = await ChatService.chatQueueActiveAgent(usuario.id)
					let fila = chatResponse.data
					let selectedChat = fila.find(
						x =>
							x.uuid ==
							(context.state.selectedChatQueue ? context.state.selectedChatQueue.uuid : undefined),
					)
					context.state.selectedChatQueue = selectedChat

					if (param.cliente) {
						for (let i = 0; i < fila.length; i++) {
							if (!fila[i].contactName) {
								if (context.state.cliente.dadosCliente[fila[i].chatId]) {
									fila[i].contactName = context.state.cliente.dadosCliente[fila[i].chatId].nome
								} else {
									if (!context.state.clientesNotFound[fila[i].chatId]) {
										try {
											const response = await ClienteService.getClienteByChannel(
												usuario.empresaId,
												fila[i].chatId,
												fila[i].channel,
											)
											if (response.status == 204) {
												context.state.clientesNotFound[fila[i].chatId] = true
											}
											context.state.cliente.dadosCliente[fila[i].chatId] = response.data
											fila[i].contactName = response.data.nome
										} catch (error) {
											console.error('getChats | error => ', error)
											fila[i].contactName = ''
										}
									}
								}
							}
						} //for
					}
					context.commit('setChatsTable', fila)
				} catch (error) {
					//Ivanildo - Se houver erro, retorna o que estiver no status atual.
					//context.commit('setChatsTable', []);
					context.commit('setChatsTable', context.state.chatsTableData)
				}
			}
		},

		async getChatMessages(context) {
			let hasMessages = false
			if (context.state.selectedChatQueue) {
				let isEmbedded = sessionStorage.getItem('isEmbedded')
					? JSON.parse(sessionStorage.getItem('isEmbedded'))
					: false
				if (isEmbedded) {
					if (!context.state.messagesOld[context.state.selectedChatQueue.chatId]) {
						context.state.messagesOld[context.state.selectedChatQueue.chatId] = []
						try {
							let items = 100
							const chatResponse = await ChatService.getHistoryByChatIdCompany(
								context.state.selectedChatQueue.chatId,
								context.state.selectedChatQueue.empresaId,
								items,
							)
							if (chatResponse.data && chatResponse.data.length > 0) {
								context.state.messagesOld[context.state.selectedChatQueue.chatId] = chatResponse.data
							}
						} catch (error) {
							console.error('getHistoryByChatIdCompany | error => ', error)
						}
					}
				} else {
					context.state.messagesOld[context.state.selectedChatQueue.chatId] = []
				}
				try {
					if (context.state.selectedChatQueue.chatUUID) {
						const chatResponse = await ChatService.getHistoryByChatUUI(
							context.state.selectedChatQueue.chatUUID,
							context.state.selectedChatQueue.chatId,
						)
						if (chatResponse.data && chatResponse.data.length > 0) {
							hasMessages = true
							if (isEmbedded) {
								context.commit(
									'setChatMessages',
									context.state.messagesOld[context.state.selectedChatQueue.chatId].concat(
										chatResponse.data,
									),
								)
							} else {
								context.commit('setChatMessages', chatResponse.data)
							}
						}
					}
				} catch (error) {
					console.error('getHistoryByChatUUI | error => ', error)
					if (isEmbedded) {
						context.commit(
							'setChatMessages',
							context.state.messagesOld[context.state.selectedChatQueue.chatId],
						)
					} else {
						context.commit('setChatMessages', [])
					}
				}
				if (!hasMessages) {
					try {
						const chatLastResponse = await ChatService.getLast48hHistory(
							context.state.selectedChatQueue.chatId,
							context.state.selectedChatQueue.flowUUID,
						)
						if (isEmbedded) {
							context.commit(
								'setChatMessages',
								context.state.messagesOld[context.state.selectedChatQueue.chatId].concat(
									chatLastResponse.data,
								),
							)
						} else {
							context.commit('setChatMessages', chatLastResponse.data)
						}
					} catch (error) {
						console.error('getLast48hHistory | error => ', error)
						if (isEmbedded) {
							context.commit(
								'setChatMessages',
								context.state.messagesOld[context.state.selectedChatQueue.chatId],
							)
						} else {
							context.commit('setChatMessages', [])
						}
					}
				}
			}
		},

		async getMinhasCampanhas(context) {
			try {
				const response = await CampanhaService.GetCampaignsByUser(context.state.usuario.id)
				context.commit('setMinhasCampanhas', response.data)
			} catch (error) {
				console.error('getMinhasCampanhas | error => ', error)
				context.commit('setMinhasCampanhas', [])
			}
		},
		async getListConfiguracoes(context) {
			try {
				let args = {
					empresaId: context.state.usuario.empresaId,
					campanhaIds: context.state.minhasCampanhas.map(x => x.id),
				}
				const response = await UsuarioService.LoadConfigurations(args)
				context.commit('setListConfiguracoes', response.data)
			} catch (error) {
				console.error('getListConfiguracoes | error => ', error)
				context.commit('setListConfiguracoes', [])
			}
		},
		async getClienteAdress(context) {
			if (!context.state.ath.apiBotHub[context.state.selectedChatQueue.chatId]) {
				const response = await ApiBotService.getToken(context.state.selectedChatQueue.chatId)
				context.state.ath.apiBotHub[context.state.selectedChatQueue.chatId] = response.data
			}
			if (context.state.ath.apiBotHub[context.state.selectedChatQueue.chatId].hasAddress) {
				await context.dispatch('ath/getCustomerAddresses')
			}
		},

		async getConection(context, param) {
			const {selectedChatQueue, numeroEnterpriseMap, hermesClientsHub} = context.state
			const {customVariables} = selectedChatQueue

			const chatId = selectedChatQueue.chatId
			const idLoja = customVariables?.['@idLoja'] ?? null

			if (param.cliente) {
				if (!context.state.cliente.dadosCliente[chatId]) {
					await context.dispatch('cliente/getDadosCliente')
				}
			}

			if (param.ath) {
				if (!context.state.ath.selectedLoja[chatId]) {
					context.commit('ath/setLojaSelected', {
						loja: parseInt(idLoja),
						chatId: chatId,
					})
				}

				if (!context.state.ath.apiBotHub[chatId] || context.state.ath.apiBotHub[chatId].idClient === -1) {
					context.state.ath.loading.token = true

					try {
						const {data: token} = await ApiBotService.getToken(chatId)

						context.state.ath.apiBotHub[chatId] = token

						context.commit('ath/setLojaSelectedDados', {
							dados: await context.dispatch('ath/getStore', idLoja),
							chatId,
						})

						if (customVariables && customVariables['@idExternoVtex']) {
							await context.dispatch('ath/getPedidoCliente')
						}

						if (token.hasAddress) {
							await context.dispatch('ath/getCustomerAddresses')
							await context.dispatch('ath/getLojasCliente')
						} else {
							await context.dispatch('ath/getLojasClienteDefault')
						}

						if (!context.state.ath.secoesLoja[idLoja]) {
							await context.dispatch('ath/getSessoesLoja')
						}
					} catch (error) {
						console.error('getToken | error => ', error)
					}

					context.state.ath.loading.token = false
				} else {
					context.state.ath.carrinhoCliente[chatId] = []

					await context.dispatch('ath/getPedidoCliente')
				}
			}

			if (selectedChatQueue && selectedChatQueue.channel === constants.TipoCanal.indexOf('WHATSAPP')) {
				// Hermes connection - WHATSAPP
				if (customVariables && customVariables['MediaId']) {
					const mediaId = customVariables['MediaId']

					if (!context.state.numeroEnterpriseMapMedia[mediaId] || !hermesClientsHub[mediaId]) {
						try {
							if (!context.state.numeroEnterpriseMapMedia[mediaId]) {
								const {data: numberByMediaId} =
									await NumeroEnterpriseService.GetNumberByMediaId(mediaId)

								context.state.numeroEnterpriseMapMedia[mediaId] = numberByMediaId
							}

							const numeroEnterprise = context.state.numeroEnterpriseMapMedia[mediaId]

							if (numeroEnterprise) {
								let hermesClient = {
									url: numeroEnterprise.url,
									mediaId: numeroEnterprise.mediaId,
									usuario: numeroEnterprise.usuario,
									tokenTimestamp: new Date(),
									token: '',
									numero: numeroEnterprise.numero,
								}

								const {data: hermesToken} = await HermesApiService.getToken(
									numeroEnterprise.url,
									numeroEnterprise.usuario,
									Decipher.decrypt(numeroEnterprise.senha),
								)

								hermesClient.token = hermesToken.jwt

								hermesClientsHub[numeroEnterprise.mediaId] = hermesClient
							} else {
								toast.warning(
									'Ocorrreu um erro tentar conectar com o Cliente, favor entrar em contato com o suporte.',
								)
							}
						} catch (error) {
							console.error('GetNumberByMediaId | Erro conexão Hermes(' + mediaId + ') => ', error)
						}
					}
				} else {
					const filter = {
						queueId: selectedChatQueue.queueId,
						empresaId: selectedChatQueue.empresaId,
					}

					const clienteHub =
						hermesClientsHub?.[numeroEnterpriseMap[selectedChatQueue.queueId]]?.mediaId ?? null

					if (!numeroEnterpriseMap[selectedChatQueue.queueId] || !clienteHub) {
						try {
							if (!numeroEnterpriseMap[selectedChatQueue.queueId]) {
								const response = await NumeroEnterpriseService.GetNumberByCampanhaOrEmpresa(filter)

								numeroEnterpriseMap[selectedChatQueue.queueId] = response.data
							}

							const numeroEnterprise = numeroEnterpriseMap[selectedChatQueue.queueId]

							if (numeroEnterprise) {
								let hermesClient = {
									url: numeroEnterprise.url,
									mediaId: numeroEnterprise.mediaId,
									usuario: numeroEnterprise.usuario,
									tokenTimestamp: new Date(),
									token: '',
									numero: numeroEnterprise.numero,
								}

								const {data: hermesToken} = await HermesApiService.getToken(
									numeroEnterprise.url,
									numeroEnterprise.usuario,
									Decipher.decrypt(numeroEnterprise.senha),
								)

								hermesClient.token = hermesToken.jwt

								hermesClientsHub[numeroEnterprise.mediaId] = hermesClient
							} else {
								toast.warning(
									'Ocorrreu um erro tentar conectar com o Cliente, favor entrar em contato com o suporte.',
								)
							}
						} catch (error) {
							console.error(
								`GetNumberByCampanhaOrEmpresa | Hermes(' ${filter.queueId} '-' ${filter.empresaId}'): `,
								error,
							)
						}
					}
				}
			} else if (selectedChatQueue && selectedChatQueue.channel === constants.TipoCanal.indexOf('WEBCHAT')) {
				async function start(connection) {
					if (
						connection.connectionState === 'Disconnected' ||
						(!connection.connectionStarted && connection.connectionState !== 'Connecting')
					) {
						try {
							await connection.start()
						} catch (error) {
							console.error('getConection | function start error => ', error)
						}
					}
				}
				let websocketAddr = selectedChatQueue.websocketAddr
				if (/(?<hubaddr>wss?\:\/\/[^\/:]+(:\d+)?\/[^\?]+(\?|\&)([^=]+)\=([^&]+))/gi.test(websocketAddr)) {
					let match = websocketAddr.match(
						/(?<hubaddr>wss?\:\/\/[^\/:]+(:\d+)?\/[^\?]+(\?|\&)([^=]+)\=([^&]+))/gi,
					)
					let hubaddr = null
					if (match.length > 0 && match[0] != null) {
						let newUrl = new URL(match[0])
						hubaddr = newUrl.origin + newUrl.pathname
						hubaddr = hubaddr.replace('ws', 'http')
					}
					if (hubaddr == null) {
						console.error(
							'ERRO: chatQueue.getWebsocketAddr() não eh valido! ==> ' +
								(selectedChatQueue != null ? selectedChatQueue.websocketAddr : 'chatQueue == null'),
						)
						return
					}
					context.commit('setHubConnection', hubaddr)
					if (!context.state.wsChatsHub[hubaddr]) {
						let connection = (context.state.wsChatsHub[hubaddr] = new signalR.HubConnectionBuilder()
							.withUrl(hubaddr + '?interage=true')
							.build())
						connection.onclose(await start(connection))
						await start(connection)
					} else {
						let connection = context.state.wsChatsHub[hubaddr]
						await start(connection)
					}
				} else {
					console.error('URL de conexão inválida. URL:' + websocketAddr)
				}
			}
		},

		async sendMessageChatHub(context, msg) {
			if (context.state.selectedHubConnection && context.state.selectedChatQueue) {
				let checkSendMessage = context.state.listConfiguracoes.filter(
					y => y.chave == 'webchat.sendmessage.json' && y.empresaId == context.state.usuario.empresa.id,
				)
				let connection = context.state.wsChatsHub[context.state.selectedHubConnection]
				let id = context.state.selectedChatQueue.websocketAddr.split('id=')[1]
				let message
				if (checkSendMessage != [] && checkSendMessage[0].valor == 'true') {
					message = JSON.stringify({Message: msg, From: 'Agent'})
				} else {
					message = msg
				}
				try {
					await connection.invoke('SendPrivate', id, message)
					return true
				} catch (err) {
					return false
				}
			} else {
				return false
			}
		},
		sendMessageHermesClient(context, param) {
			let mediaId =
				param.chatQueue.customVariables && param.chatQueue.customVariables['MediaId']
					? param.chatQueue.customVariables['MediaId']
					: localStorage.getItem('mediaId')

			let whatsAppChatId = param.chatQueue.customVariables['@ExternalChatId'] || param.chatQueue.chatId

			let canal = param.chatQueue.channel //constants.TipoCanal.indexOf("WHATSAPP")
			if (canal === constants.TipoCanal.indexOf('WHATSAPPBUSINESS')) {
				return HermesApiService.sendMessage(null, whatsAppChatId, param.msg, canal, mediaId)
			} else {
				// let filter = {
				//   queueId: param.chatQueue.queueId,
				//   empresaId: param.chatQueue.empresaId
				// };
				let numeroEnterprise = null
				if (param.chatQueue.customVariables && param.chatQueue.customVariables['MediaId']) {
					numeroEnterprise =
						context.state.numeroEnterpriseMapMedia[param.chatQueue.customVariables['MediaId']]
				} else {
					numeroEnterprise = context.state.numeroEnterpriseMap[param.chatQueue.queueId]
				}
				if (numeroEnterprise) {
					let client = context.state.hermesClientsHub[numeroEnterprise.mediaId]
					if (client) {
						return HermesApiService.sendMessage(
							client,
							whatsAppChatId,
							param.msg,
							canal,
							numeroEnterprise.mediaId,
						)
					} else {
						//console.log("Erro: Sem HermesclientToken")
					}
				} else {
					//console.log("Erro: Sem Numero enterprise")
				}
			}
		},
		sendFileHermesClient(context, param) {
			let canal = param.chatQueue.channel //constants.TipoCanal.indexOf("WHATSAPP")
			let mediaId =
				param.chatQueue.customVariables && param.chatQueue.customVariables['MediaId']
					? param.chatQueue.customVariables['MediaId']
					: localStorage.getItem('mediaId')

			let whatsAppChatId = param.chatQueue.customVariables['@ExternalChatId'] || param.chatQueue.chatId
			if (canal === constants.TipoCanal.indexOf('WHATSAPPBUSINESS')) {
				return HermesApiService.sendFile(null, whatsAppChatId, param.file, canal, mediaId)
			} else {
				// let filter = {
				//   queueId: param.chatQueue.queueId,
				//   empresaId: param.chatQueue.empresaId
				// };
				let numeroEnterprise = null
				if (param.chatQueue.customVariables && param.chatQueue.customVariables['MediaId']) {
					numeroEnterprise =
						context.state.numeroEnterpriseMapMedia[param.chatQueue.customVariables['MediaId']]
				} else {
					numeroEnterprise = context.state.numeroEnterpriseMap[param.chatQueue.queueId]
				}
				if (numeroEnterprise) {
					let client = context.state.hermesClientsHub[numeroEnterprise.mediaId]
					if (client) {
						return HermesApiService.sendFile(
							client,
							whatsAppChatId,
							param.file,
							canal,
							numeroEnterprise.mediaId,
						)
					} else {
						//console.log("Erro: Sem HermesclientToken")
					}
				} else {
					//console.log("Erro: Sem Numero enterprise")
				}
			}
		},

		async sendMessageTelegram(context, param) {
			var msg = param.msg
			let msgTelegram = {}

			if (msg.startsWith('IMAGE')) {
				msgTelegram = {
					chatId: param.chatQueue.chatId,
					content: param.msg,
				}
			} else {
				msgTelegram = {
					chatId: param.chatId.chatId,
					content: param.msg,
				}
			}
			const chatResponse = await ChatService.SendTelegramMessage(msgTelegram)
		},
		clearIsNew(context, chatId) {
			let isNew = localStorage.getItem('isNew') ? JSON.parse(localStorage.getItem('isNew')) : []
			if (isNew.length > 0) {
				let ind = isNew.findIndex(x => x.chave == chatId)
				if (ind != -1) {
					isNew.splice(ind, 1)
					localStorage.setItem('isNew', JSON.stringify(isNew))
				}
			}
		},

		setIsNewFalse(context, chatId) {
			let isNew = localStorage.getItem('isNew') ? JSON.parse(localStorage.getItem('isNew')) : []
			if (isNew.length > 0) {
				let ind = isNew.findIndex(x => x.chave == chatId)
				if (ind != -1) {
					isNew[ind].valor = false
					localStorage.setItem('isNew', JSON.stringify(isNew))
				}
			}
		},

		async getConectionChat(context, param) {
			if (
				param.chatQueue.channel === constants.TipoCanal.indexOf('WHATSAPP')
				//|| context.state.selectedChatQueue.channel === constants.TipoCanal.indexOf("WHATSAPPBUSINESS")
			) {
				// Conexão Hermes WHATSAPP
				if (param.chatQueue.customVariables && param.chatQueue.customVariables['MediaId']) {
					let mediaId = param.chatQueue.customVariables['MediaId']
					if (!context.state.numeroEnterpriseMapMedia[mediaId] || !context.state.hermesClientsHub[mediaId]) {
						try {
							if (!context.state.numeroEnterpriseMapMedia[mediaId]) {
								const resMedia = await NumeroEnterpriseService.GetNumberByMediaId(mediaId)
								context.state.numeroEnterpriseMapMedia[mediaId] = resMedia.data
							}
							let numeroEnterprise = context.state.numeroEnterpriseMapMedia[mediaId]
							if (numeroEnterprise) {
								let hermesClient = {
									url: numeroEnterprise.url,
									mediaId: numeroEnterprise.mediaId,
									usuario: numeroEnterprise.usuario,
									tokenTimestamp: new Date(),
									token: '',
									numero: numeroEnterprise.numero,
								}
								const r = await HermesApiService.getToken(
									numeroEnterprise.url,
									numeroEnterprise.usuario,
									Decipher.decrypt(numeroEnterprise.senha),
								)
								hermesClient.token = r.data.jwt
								context.state.hermesClientsHub[numeroEnterprise.mediaId] = hermesClient
							} else {
								//console.log('Erro conexão Hermes:' + mediaId)
							}
						} catch (e) {
							//console.log(e)
							//console.log('Erro conexão Hermes:' + mediaId)
						}
					}
				} else {
					let filter = {queueId: param.chatQueue.queueId, empresaId: param.chatQueue.empresaId}
					let clienteHub =
						context.state.numeroEnterpriseMap[param.chatQueue.queueId] &&
						context.state.hermesClientsHub[context.state.numeroEnterpriseMap[param.chatQueue.queueId]]
							? context.state.hermesClientsHub[
									context.state.numeroEnterpriseMap[param.chatQueue.queueId].mediaId
							  ]
							: null
					if (!context.state.numeroEnterpriseMap[param.chatQueue.queueId] || !clienteHub) {
						try {
							if (!context.state.numeroEnterpriseMap[param.chatQueue.queueId]) {
								const d = await NumeroEnterpriseService.GetNumberByCampanhaOrEmpresa(filter)
								context.state.numeroEnterpriseMap[param.chatQueue.queueId] = d.data
							}
							let numeroEnterprise = context.state.numeroEnterpriseMap[param.chatQueue.queueId]
							if (numeroEnterprise) {
								let hermesClient = {
									url: numeroEnterprise.url,
									mediaId: numeroEnterprise.mediaId,
									usuario: numeroEnterprise.usuario,
									tokenTimestamp: new Date(),
									token: '',
									numero: numeroEnterprise.numero,
								}
								const r = await HermesApiService.getToken(
									numeroEnterprise.url,
									numeroEnterprise.usuario,
									Decipher.decrypt(numeroEnterprise.senha),
								)
								hermesClient.token = r.data.jwt
								context.state.hermesClientsHub[numeroEnterprise.mediaId] = hermesClient
							} else {
								//console.log('Erro conexão Hermes:' + filter.queueId + '-' + filter.empresaId)
							}
						} catch (e) {
							//console.log(e)
							//console.log('Erro conexão Hermes:' + filter.queueId + '-' + filter.empresaId)
						}
					}
				}
			} else if (param.chatQueue.channel === constants.TipoCanal.indexOf('WEBCHAT')) {
				async function start(connection) {
					if (
						connection.connectionState === 'Disconnected' ||
						(!connection.connectionStarted && connection.connectionState !== 'Connecting')
					) {
						try {
							await connection.start()
							//console.debug('Conexão estabelecida com sucesso!');
						} catch (e) {
							console.debug(e)
						}
					}
				}
				let websocketAddr = param.chatQueue.websocketAddr
				if (/(?<hubaddr>wss?\:\/\/[^\/:]+(:\d+)?\/[^\?]+(\?|\&)([^=]+)\=([^&]+))/gi.test(websocketAddr)) {
					let match = websocketAddr.match(
						/(?<hubaddr>wss?\:\/\/[^\/:]+(:\d+)?\/[^\?]+(\?|\&)([^=]+)\=([^&]+))/gi,
					)
					let hubaddr = null
					if (match.length > 0 && match[0] != null) {
						let newUrl = new URL(match[0])
						hubaddr = newUrl.origin + newUrl.pathname
						hubaddr = hubaddr.replace('ws', 'http')
					}
					if (hubaddr == null) {
						console.debug(
							'ERRO: chatQueue.getWebsocketAddr() não eh valido! ==> ' +
								(context.state.selectedChatQueue != null
									? context.state.selectedChatQueue.websocketAddr
									: 'chatQueue == null'),
						)
						return
					}

					if (!context.state.wsChatsHub[hubaddr]) {
						let connection = (context.state.wsChatsHub[hubaddr] = new signalR.HubConnectionBuilder()
							.withUrl(hubaddr + '?interage=true')
							.build())
						connection.onclose(await start(connection))
						await start(connection)
					} else {
						let connection = context.state.wsChatsHub[hubaddr]
						await start(connection)
					}
					return hubaddr
				} else {
					console.debug('URL de conexão inválida. URL:' + websocketAddr)
				}
			}
		},

		async sendMessageChatHubChatId(context, param) {
			let checkSendMessage = context.state.listConfiguracoes.filter(
				y => y.chave == 'webchat.sendmessage.json' && y.empresaId == context.state.usuario.empresa.id,
			)
			let connection = context.state.wsChatsHub[param.hub]
			let id = param.chatQueue.websocketAddr.split('id=')[1]
			let message
			if (checkSendMessage.length != 0 && checkSendMessage[0].valor == 'true') {
				message = JSON.stringify({Message: param.msg, From: 'Agent'})
			} else {
				message = param.msg
			}
			try {
				await connection.invoke('SendPrivate', id, message)
				return true
			} catch (err) {
				return false
			}
		},

		clearMsgAlarme(context, chatId) {
			let msgAlarme = localStorage.getItem('msgAlarme') ? JSON.parse(localStorage.getItem('msgAlarme')) : []
			if (msgAlarme.length > 0) {
				let ind = msgAlarme.findIndex(x => x.chave == chatId)
				if (ind != -1) {
					msgAlarme.splice(ind, 1)
					localStorage.setItem('msgAlarme', JSON.stringify(msgAlarme))
				}
			}
		},

		async getMinhasAgendas(context) {
			try {
				const response = await CalendarioService.GetAgendaByAgent(context.state.usuario.id)
				context.commit('setMinhasAgendas', response.data)
			} catch (error) {
				console.debug(error)
				context.commit('setMinhasAgendas', [])
			}
		},

		getCorTema(context) {
			let usuario = JSON.parse(localStorage.getItem('usuario'))
			const temaMutant = sessionStorage.getItem('temaMutant')
				? JSON.parse(sessionStorage.getItem('temaMutant'))
				: false
			const isEmbedded = sessionStorage.getItem('isEmbedded')
				? JSON.parse(sessionStorage.getItem('isEmbedded'))
				: false
			let codDefault = '#665dfe'
			if (isEmbedded) {
				codDefault = temaMutant ? '#000000' : '#20C9DC'
			}
			let corTema =
				usuario && usuario.empresa && usuario.empresa.corEstiloPrincipal
					? usuario.empresa.corEstiloPrincipal.toLowerCase()
					: codDefault
			context.commit('setCorTema', corTema)
		},
	}, //

	modules: {
		ath,
		cliente,
		oms,
	},
})
