import {environment} from '../environments/env'
import Api from './Api'
import {route} from './routeDictionary'

export default {
	GeTagByEmpresa(param) {
		return Api.get(`${environment.apiUrl}${route.GeTagByEmpresa}`, {params: param})
	},
	AddTag(empresaId, arr) {
		return Api.post(`${environment.apiUrl}${route.InsertTag}`, arr, {params: {empresaId: empresaId}})
	},
	GetTagChatList(param) {
		return Api.get(`${environment.apiUrl}${route.GeTagChatList}`, {params: param})
	},
	UpdateTagChat(chatUUID, tags) {
		return Api.put(`${environment.apiUrl}${route.UpdateTagChat}`, tags, {params: {chatUuid: chatUUID}})
	},
}
