const origin = process.env.NODE_ENV === 'development' ? window.location.origin : ''

export const environment = {
	apiUrl: `${origin}${process.env.VUE_APP_API_URL}`,
	apiBot: `${origin}${process.env.VUE_APP_API_BOT_URL}`,
	apiWebhook: `${origin}${process.env.VUE_APP_API_WEBHOOK}`,
	apiOmsHub: `${origin}${process.env.VUE_APP_API_OMSHUB_URL}`,
	apiClient: `${origin}${process.env.VUE_APP_API_CLIENT_URL}`,
	apiMarketplace: `${origin}${process.env.VUE_APP_API_MARKETPLACE_URL}`,
	apiMessenger: `${origin}${process.env.VUE_APP_API_MESSENGER_URL}`,
	apiInstagram: `${origin}${process.env.VUE_APP_API_INSTAGRAM_URL}`,
}
