
	import Validar from '../util/Validadores'
	export default {
		props: {
			message: String,
			from: String,
			background: String,
		},

		setup(props) {
			return {
				Validar,
				props,
			}
		},
	}
