import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pedido" }
const _hoisted_2 = {
  class: "scrolling",
  style: {"overflow":"auto","height":"100%","width":"100%"}
}
const _hoisted_3 = {
  key: 0,
  class: "table table-sm"
}
const _hoisted_4 = { class: "thead-light" }
const _hoisted_5 = { style: {"text-align":"left"} }
const _hoisted_6 = { style: {"text-align":"left"} }
const _hoisted_7 = { style: {"text-align":"left"} }
const _hoisted_8 = { style: {"text-align":"left"} }
const _hoisted_9 = { style: {"text-align":"left"} }
const _hoisted_10 = { style: {"text-align":"left"} }
const _hoisted_11 = { style: {"text-align":"left"} }
const _hoisted_12 = { style: {"text-align":"left"} }
const _hoisted_13 = { style: {"text-align":"left"} }
const _hoisted_14 = { class: "modal-footer" }
const _hoisted_15 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!!$setup.listaPedidos.id)
        ? (_openBlock(), _createElementBlock("table", _hoisted_3, [
            _createElementVNode("thead", _hoisted_4, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_5, [
                      _createElementVNode("b", null, _toDisplayString($setup.tm('numeroPedido')) + ": ", 1),
                      _createTextVNode(_toDisplayString($setup.listaPedidos.id), 1)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_6, [
                      _createElementVNode("b", null, _toDisplayString($setup.tm('itensPedido')) + ": ", 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.listaPedidos.itens, (item) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: item.itemId
                        }, [
                          _createElementVNode("td", _hoisted_7, _toDisplayString(item.itemQuantidade) + "X", 1),
                          _createElementVNode("td", _hoisted_8, _toDisplayString(item.itemDesc), 1),
                          _createElementVNode("td", _hoisted_9, "- " + _toDisplayString($setup.formataMoeda(item.itemValor)), 1)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_10, [
                      _createElementVNode("b", null, _toDisplayString($setup.tm('dataHoraPedido')) + ": ", 1),
                      _createTextVNode(_toDisplayString($setup.listaPedidos.dataHora), 1)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_11, [
                      _createElementVNode("b", null, _toDisplayString($setup.tm('valorFinal')) + ": ", 1),
                      _createTextVNode(_toDisplayString($setup.formataMoeda($setup.listaPedidos.valorFinal)), 1)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_12, [
                      _createElementVNode("b", null, _toDisplayString($setup.tm('metodoPagamento')) + ": ", 1),
                      _createTextVNode(_toDisplayString($setup.listaPedidos.metodoPagamento), 1)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_13, [
                      _createElementVNode("b", null, _toDisplayString($setup.tm('linkPagamento')) + ": ", 1),
                      _createTextVNode(_toDisplayString($setup.listaPedidos.linkPagamento), 1)
                    ])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary js-btn-step pr-1 pl-1",
          disabled: $setup.stateLocal.isLoadingBot || $setup.stateLocal.idClient == -1 || !$setup.listaPedidos.id,
          onClick: _cache[0] || (_cache[0] = $event => ($setup.enviarLinkPagamento($setup.listaPedidos.linkPagamento)))
        }, _toDisplayString($setup.tm('enviarLinkPagamento')), 9, _hoisted_15)
      ])
    ])
  ]))
}