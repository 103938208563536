
	import moment from 'moment'
	import Validar from '../../util/Validadores'
	import {reactive} from 'vue'
	import {useToast} from 'vue-toastification'
	import {useStore} from 'vuex'
	import constants from '../../util/constants'
	import OmsHubService from '../../services/OmsHubService'
	import Modal from '../../components/Modal'
	import {useI18n} from 'vue-i18n'

	const toast = useToast()
	export default {
		components: {Modal},
		props: {
			timezoneDefault: String,
		},
		setup(props, context) {
			const {tm, locale} = useI18n()
			const store = useStore()
			const stateLocal = reactive({
				isLoadingPedidos: false,
				isLoadingListaPedidos: false,
				showConsulta: false,
				showDetalhe: false,
				showListaPedido: false,
				showHistorico: false,
				showOcorrencia: false,
				showModalCancelar: false,
				showModalHitorico: false,
				pedidoBusca: '',
				pedidoBuscaCpf: '',
				numeroPedido: '',
				dataPedido: '',
				cpfCliente: '',
				nomeCliente: '',
				totalPedido: '',
				totalGeral: '',
				formaPagamento: '',
				frete: '',
				situacaoPedido: '',
				statusPedido: '',
				itens: [],
				dataOcorrencia: '',
				prioridade: '',
				motivo: '',
				historico: [],
				listaPedidos: [],
				produtoItem: {},
				order: null,
				timezoneDefault2: props.timezoneDefault,
			})

			//methods
			function limparDados() {
				stateLocal.numeroPedido = ''
				stateLocal.dataPedido = ''
				stateLocal.cpfCliente = ''
				stateLocal.itens = []
				stateLocal.nomeCliente = ''
				stateLocal.situacaoPedido = ''
				stateLocal.statusPedido = ''
				stateLocal.totalPedido = ''
				stateLocal.totalGeral = ''
				stateLocal.formaPagamento = ''
				stateLocal.frete = ''
				stateLocal.dataOcorrencia = ''
				stateLocal.prioridade = ''
				stateLocal.motivo = ''
				stateLocal.produtoItem = {}
			}

			async function cancelar() {
				if (!stateLocal.numeroPedido) {
					return false
				}
				try {
					let mediaId = ''
					if (store.state.selectedChatQueue && store.state.selectedChatQueue.customVariables['MediaId']) {
						mediaId = store.state.selectedChatQueue.customVariables['MediaId']
					} else {
						let pluginAtivo = store.state.listConfiguracoes.filter(
							y => y.chave == 'plugin.marketplace' && y.empresaId == store.state.usuario.empresa.id,
						)
						if (pluginAtivo.length > 0 && pluginAtivo[0].valor.trim() != '') {
							mediaId = pluginAtivo[0].valor
						}
					}
					if (mediaId) {
						if (!store.state.oms.clienteProfile[mediaId]) {
							await store.dispatch('oms/getDadosOmsHub', {
								mediaId: mediaId,
								channelId: 1005,
							})
						}
						if (store.state.oms.clienteProfile[mediaId] && stateLocal.order) {
							let order = Object.assign({}, stateLocal.order)
							let transformation = {
								type: 'Cancel',
								arguments: [],
							}
							let transformations = []
							transformations.push(transformation)
							order.transformations = transformations
							//console.log("updade", order);
							const response = await OmsHubService.UpdateOrder(
								mediaId,
								store.state.oms.clienteProfile[mediaId].channelId,
								store.state.oms.clienteProfile[mediaId].userData,
								stateLocal.numeroPedido,
								stateLocal.order.origin,
								order,
							)
							toast.info(tm('msgSucessoCancelamento'))
						}
					} else {
						//console.log("Erro: mediaId não infomado.");
						toast.warning(tm('erroCancelamento'))
					}
				} catch (err) {
					//console.log(err);
					toast.warning(tm('erroCancelamento'))
				} finally {
					this.buscarPedido()
					stateLocal.showModalCancelar = false
				}
			}

			function setPedido() {
				limparDados()
				if (store.state.selectedChatQueue && store.state.selectedChatQueue.customVariables['@orderId']) {
					//console.log("chatId:", store.state.selectedChatQueue.chatId);
					//console.log("chatUUID:", store.state.selectedChatQueue.chatUUID);
					//console.log("customVariables:", store.state.selectedChatQueue.customVariables);
					stateLocal.pedidoBusca = store.state.selectedChatQueue.customVariables['@orderId']
					buscarPedido()
				} else {
					stateLocal.pedidoBusca = ''
					stateLocal.pedidoBuscaCpf = ''
					stateLocal.showConsulta = true
				}
			}

			function convertDataPedido(data) {
				return moment(data).tz(props.timezoneDefault).format('DD/MM/YYYY HH:mm')
			}

			async function buscarPedido() {
				if (!stateLocal.pedidoBusca && !stateLocal.pedidoBuscaCpf) {
					document.getElementById('pedidoBusca').focus()
					toast.warning(tm('erroPedido'))
					return false
				}
				try {
					limparDados()
					stateLocal.showConsulta = false
					let mediaId = ''
					if (store.state.selectedChatQueue && store.state.selectedChatQueue.customVariables['MediaId']) {
						mediaId = store.state.selectedChatQueue.customVariables['MediaId']
					} else {
						let pluginAtivo = store.state.listConfiguracoes.filter(
							y => y.chave == 'plugin.marketplace' && y.empresaId == store.state.usuario.empresa.id,
						)
						//console.log("chave", pluginAtivo);
						if (pluginAtivo.length > 0 && pluginAtivo[0].valor.trim() != '') {
							mediaId = pluginAtivo[0].valor
						}
					}
					let cpf = document.getElementById('pedidoBuscaCpf').value
					//console.log(stateLocal.pedidoBuscaCpf, cpf);
					if (mediaId) {
						if (!store.state.oms.clienteProfile[mediaId]) {
							await store.dispatch('oms/getDadosOmsHub', {
								mediaId: mediaId,
								channelId: 1005,
							})
						}
						//console.log(mediaId);
						//console.log(store.state.oms.clienteProfile);
						//console.log(store.state.oms.clienteProfile[mediaId]);
						if (store.state.oms.clienteProfile[mediaId]) {
							let orderResponse = ''
							if (cpf == '') {
								stateLocal.isLoadingPedidos = true
								stateLocal.showDetalhe = true
								const response = await OmsHubService.GetOrderByUid(
									mediaId,
									store.state.oms.clienteProfile[mediaId].userData,
									store.state.oms.clienteProfile[mediaId].channelId,
									{
										orderUid: stateLocal.pedidoBusca,
									},
								)
								orderResponse = response.data ? response.data : ''
								//console.log("pedido", orderResponse);
								if (orderResponse != '') {
									stateLocal.order = orderResponse
									stateLocal.numeroPedido = orderResponse.uid
									stateLocal.cpfCliente = Validar.formatCnpjCpf(orderResponse.invoiceCpfCnpj)
									stateLocal.nomeCliente = orderResponse.invoiceName
									stateLocal.totalPedido = formataMoeda(orderResponse.totalAsDecimal)
									stateLocal.totalGeral = formataMoeda(
										orderResponse.totalAsDecimal + orderResponse.deliveryFeeAsDecimal,
									)
									stateLocal.formaPagamento = orderResponse.payment
										? orderResponse.payment.description
											? orderResponse.payment.description
											: orderResponse.payment.name
										: ''
									stateLocal.frete = formataMoeda(orderResponse.deliveryFeeAsDecimal)
									stateLocal.dataPedido = orderResponse.issuedAt
										? moment(orderResponse.issuedAt)
												.tz(props.timezoneDefault)
												.format('DD/MM/YYYY HH:mm')
										: ''
									stateLocal.situacaoPedido = orderResponse.status
									stateLocal.statusPedido = orderResponse.statusCode
									stateLocal.itens = await formataItens(orderResponse.products)
									//TODO pegar historico
									stateLocal.historico = []
									//TODO pegar ocorrencia
									stateLocal.motivo = ''
									stateLocal.dataOcorrencia = ''
									stateLocal.prioridade = ''
								} else {
									toast.warning(tm('pedidoNaoEncontrado'))
								}
							} else {
								stateLocal.isLoadingListaPedidos = true
								stateLocal.showListaPedido = true
								const response = await OmsHubService.OrdersByClientProperty(mediaId, {
									key: 'cpf',
									value: cpf
										.replaceAll('.', '')
										.replaceAll('.', '')
										.replaceAll('.', '')
										.replaceAll('-', ''),
									page: 1,
									itemsPerpage: 15,
								})
								stateLocal.listaPedidos =
									response.data && response.data.elements.length > 0 ? response.data.elements : []
								if (stateLocal.listaPedidos == []) {
									toast.warning(tm('pedidoNaoEncontrado'))
								}
								//console.log(stateLocal.listaPedidos);
							}
						} else {
							//console.log("Erro: HubOms - getClienteProfile");
							toast.warning(tm('erroBuscaPedido'))
						}
					} else {
						//console.log("Erro: mediaId não infomado.");
						toast.warning(tm('erroBuscaPedido'))
					}
				} catch (err) {
					//console.log(err);
					toast.warning(tm('erroBuscaPedido'))
				} finally {
					stateLocal.isLoadingListaPedidos = false
					stateLocal.isLoadingPedidos = false
				}
			}

			function formataMoeda(value) {
				return Validar.formataMoeda(
					value,
					Validar.getFormatoMoeda(locale.value),
					Validar.getCurrencyMoeda(locale.value),
				)
			}

			async function formataItens(produtos) {
				let itens = []
				for (let [i, produto] of produtos.entries()) {
					stateLocal.produtoItem = {}
					stateLocal.produtoItem.description = produto.description
					stateLocal.produtoItem.image = produto.image
					stateLocal.produtoItem.name = produto.name
					stateLocal.produtoItem.quantity = produto.quantity
					stateLocal.produtoItem.discountValueAsDecimal = produto.discountValueAsDecimal
					stateLocal.produtoItem.valueAsDecimal = produto.valueAsDecimal
					if (
						produto.valueAsDecimal == 0 &&
						produto.discountValueAsDecimal == 0 &&
						produto.innerProducts &&
						produto.innerProducts.length > 0
					) {
						await verificaValor(produto.innerProducts)
					}
					itens.push({...stateLocal.produtoItem})
				}
				return itens
			}

			async function verificaValor(sortimento) {
				for (let [i, element] of sortimento.entries()) {
					if (
						element.valueAsDecimal == 0 &&
						element.discountValueAsDecimal == 0 &&
						element.innerProducts &&
						element.innerProducts.length > 0
					) {
						await verificaValor(element.innerProducts)
					} else {
						stateLocal.produtoItem.discountValueAsDecimal = element.discountValueAsDecimal
						stateLocal.produtoItem.valueAsDecimal = element.valueAsDecimal
						stateLocal.produtoItem.quantity = element.quantity
						stateLocal.produtoItem.description = element.description
						stateLocal.produtoItem.image = element.image
						stateLocal.produtoItem.name = element.name
					}
				}
			}

			function detalheProtocolo(value) {
				//console.log(value);
				stateLocal.showModalHitorico = true
			}

			async function detalhePedidoLista(orderResponse) {
				try {
					stateLocal.isLoadingPedidos = true
					stateLocal.showDetalhe = true
					stateLocal.showListaPedido = false
					stateLocal.order = orderResponse
					stateLocal.numeroPedido = orderResponse.uid
					stateLocal.cpfCliente = Validar.formatCnpjCpf(orderResponse.invoiceCpfCnpj)
					stateLocal.nomeCliente = orderResponse.invoiceName
					stateLocal.totalPedido = formataMoeda(orderResponse.totalAsDecimal)
					stateLocal.totalGeral = formataMoeda(
						orderResponse.totalAsDecimal + orderResponse.deliveryFeeAsDecimal,
					)
					stateLocal.formaPagamento = orderResponse.payment
						? orderResponse.payment.description
							? orderResponse.payment.description
							: orderResponse.payment.name
						: ''
					stateLocal.frete = formataMoeda(orderResponse.deliveryFeeAsDecimal)
					stateLocal.dataPedido = orderResponse.issuedAt
						? moment(orderResponse.issuedAt).tz(props.timezoneDefault).format('DD/MM/YYYY HH:mm')
						: ''
					stateLocal.situacaoPedido = orderResponse.status
					stateLocal.statusPedido = orderResponse.statusCode
					stateLocal.itens = await formataItens(orderResponse.products)
					//TODO pegar historico
					stateLocal.historico = []
					//TODO pegar ocorrencia
					stateLocal.motivo = ''
					stateLocal.dataOcorrencia = ''
					stateLocal.prioridade = ''
				} finally {
					stateLocal.isLoadingPedidos = false
				}
			}

			//created

			return {
				props,
				store,
				stateLocal,
				cancelar,
				buscarPedido,
				convertDataPedido,
				constants,
				tm,
				setPedido,
				formataMoeda,
				detalheProtocolo,
				detalhePedidoLista,
			}
		},
	}
