import {environment} from '../environments/env'
import Api from './Api'
import {route} from './routeDictionary'

export default {
	AddToMarketplace(arr) {
		return Api.post(`${environment.apiMarketplace}`, arr)
	},
	AddToMarketplaceState(arr) {
		return Api.post(`${environment.apiMarketplace}`, arr)
	},
	UpdateStage(claimId) {
		//console.log(claimId)
		return Api.put(`${environment.apiMarketplace}${route.UpdateStage}`, claimId)
	},
	GetListReclamacoes() {
		return Api.get(`${environment.apiMarketplace}${route.GetListReclamacoes}`)
	},
}
