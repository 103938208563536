export default {
	TipoCanal: [
		'DESCONHECIDO',
		'WHATSAPP',
		'WEBCHAT',
		'FACEBOOK',
		'TELEGRAM',
		'WEBSOCKET',
		'EMAIL',
		'WEBFORM',
		'FACILITA_FLOW',
		'WHATSAPPBUSINESS',
		'MERCADOLIVRE',
		'INSTAGRAM',
	],
	PAGE_HEIGHT: 700,
	PAGE_WIDTH: 500,
	ID_REDE: 6, //6 TODO
}
