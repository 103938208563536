
	import {computed, ref, watch} from 'vue'
	import {reactive} from 'vue'
	import Datepicker from 'vue3-datepicker'
	import {pt} from 'date-fns/locale'
	import ChatHistoryItem from './chathistoryitem.vue'
	import ChatHistoryMessages from './chathistorymessages.vue'
	import Modal from '../../components/Modal'
	import differenceInDays from 'date-fns/differenceInDays'
	import ChatService from '../../services/ChatService'
	import {onMounted} from 'vue'
	import {useStore} from 'vuex'
	import {useToast} from 'vue-toastification'
	import {useI18n} from 'vue-i18n'

	const toast = useToast()
	export default {
		name: 'Historico',
		components: {Datepicker, ChatHistoryItem, ChatHistoryMessages, Modal},
		setup() {
			const {tm, locale} = useI18n()
			const store = useStore()
			const limitDays = 90
			const campanhaSelect = ref('')
			const fluxoSelect = ref('')
			const dataSelect = reactive({
				dataInicio: ref(new Date().addDays(-limitDays)),
				dataFinal: ref(new Date()),
			})
			const stateLocal = reactive({
				chatMessages: [],
				chats: [],
				buttonControl: false,
				isloading: false,
				showModalChatHistoryMessages: false,
				campanhas: [],
				fluxos: [],
				valorOriginal: [],
			})

			const langData = computed(() => {
				switch (locale.value) {
					case 'en':
						return enUS
					case 'es':
						return es
					case 'pt':
						return pt
					default:
						return pt
				}
			})

			onMounted(() => {
				// carregarDadosChats();
			})
			//METODOS

			function resetInfo() {
				dataSelect.dataInicio = new Date().addDays(-limitDays)
				dataSelect.dataFinal = new Date()
				stateLocal.chats = []
			}

			async function showMessages(chatItem) {
				let response = await ChatService.getHistoryByChatUUI(chatItem.chatUUID)
				stateLocal.chatMessages = response.data
				stateLocal.showModalChatHistoryMessages = true
			}

			// Watchs
			watch(dataSelect, novaData => {
				if (new Date(novaData.dataInicio) > new Date(novaData.dataFinal)) {
					toast.warning(tm('erroPeriodo01'))
					stateLocal.buttonControl = true
					differenceInDays(new Date(dataSelect.dataInicio), new Date(dataSelect.dataFinal))
				} else if (
					differenceInDays(new Date(dataSelect.dataFinal), new Date(dataSelect.dataInicio)) > limitDays
				) {
					toast.warning(tm('erroPeriodo02') + limitDays + tm('dias') + '!')
					stateLocal.buttonControl = true
				} else {
					let v = differenceInDays(new Date(dataSelect.dataFinal), new Date(dataSelect.dataInicio))
					stateLocal.buttonControl = false
				}
			})

			async function carregarDadosChats() {
				// checar se os dados do cliente já estão carregados
				stateLocal.isloading = true
				let cliente = null
				let chatIds = []
				try {
					if (store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]) {
						cliente = store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
					} else {
						await store.dispatch('cliente/getDadosCliente')
						cliente = store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
					}
					if (cliente) {
						// capturar canais de atendimento
						chatIds = cliente.crmCanalCliente.map(element => {
							return element.valor
						})
					} else {
						// cliente não cadastrado
						chatIds.push(store.state.selectedChatQueue.chatId)
					}
					let response = await ChatService.GetAllChatsFromDataChatIds(
						dataSelect.dataInicio.getTime(),
						dataSelect.dataFinal.getTime(),
						chatIds,
						store.state.selectedChatQueue.empresaId,
					)
					stateLocal.chats = response.data
					stateLocal.valorOriginal = response.data
					stateLocal.campanhas = [...new Set(response.data.map(x => x.queueId))]
					const revArray = response.data.reverse()
					stateLocal.fluxos = [...new Set(revArray.map(x => x.flowUUID))].map(r =>
						revArray.reverse().find(v => v.flowUUID === r),
					)
				} catch (e) {
					//console.log("Historico", e);
				} finally {
					stateLocal.isloading = false
				}
			}

			return {
				onMounted,
				stateLocal,
				pt,
				carregarDadosChats,
				dataSelect,
				resetInfo,
				showMessages,
				tm,
				langData,
				campanhaSelect,
				fluxoSelect,
			}
		},
		watch: {
			campanhaSelect: function (newVal, oldVal) {
				if (newVal != '') {
					this.stateLocal.chats = this.stateLocal.valorOriginal.filter(
						x =>
							x.queueId == newVal &&
							(this.fluxoSelect != '' ? x.flowUUID == this.fluxoSelect : x.flowUUID != null),
					)
				} else {
					this.stateLocal.chats = this.stateLocal.valorOriginal.filter(x =>
						this.fluxoSelect != '' ? x.flowUUID == this.fluxoSelect : x.flowUUID != null,
					)
				}
			},
			fluxoSelect: function (newVal, oldVal) {
				if (newVal != '') {
					this.stateLocal.chats = this.stateLocal.valorOriginal.filter(
						x =>
							x.flowUUID == newVal &&
							(this.campanhaSelect != '' ? x.queueId == this.campanhaSelect : x.queueId != null),
					)
				} else {
					this.stateLocal.chats = this.stateLocal.valorOriginal.filter(x =>
						this.campanhaSelect != '' ? x.queueId == this.campanhaSelect : x.queueId != null,
					)
				}
			},
		},
	}
