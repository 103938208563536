
	import moment from 'moment'
	import Validar from '@/util/Validadores'
	import {reactive, computed, ref, watch} from 'vue'
	import Emoji from '../../components/Emoji.vue'
	import ChatService from '../../services/ChatService'
	import HsmService from '../../services/HsmService'
	import ClienteService from '../../services/ClienteService'
	import HermesApiService from '../../services/HermesApiService'
	import NumeroEnterpriseService from '../../services/NumeroEnterpriseService'
	import Decipher from '../../util/Decipher'
	import ListService from '../../services/ListService'
	import UsuarioService from '../../services/UsuarioService'
	import differenceInHours from 'date-fns/differenceInHours'
	import pdfMake from 'pdfmake/build/pdfmake'
	import pdfFonts from 'pdfmake/build/vfs_fonts'
	import store from '../../store'
	import constants from '../../util/constants'
	import {v4 as uuidv4} from 'uuid'
	import Modal from '../../components/Modal'
	import {useToast} from 'vue-toastification'
	import {useI18n} from 'vue-i18n'
	import {ConsoleLogger} from '@microsoft/signalr/dist/esm/Utils'

	const toast = useToast()
	export default {
		name: 'SearchChatHistoryMessages',
		components: {
			Emoji,
			Modal,
		},
		props: ['messages'],
		emits: ['close'],
		setup(props, {emit}) {
			const {tm} = useI18n()
			pdfMake.vfs = pdfFonts.pdfMake.vfs
			const timezoneDefault = ref('')
			let itemsFound = ref(-1)
			let itemFind = ref(0)
			let lastTermo = ref('')
			let lastIndex = ref('')
			const fileSelected = ref('')
			const fileAnexo = ref('')
			const mediaRecorder = ref(null)
			const timeRecording = ref(null)
			const topicoSelected = ref(0)
			const isRecording = ref(false)
			const timeText = ref('')
			const listTopicos = ref([])
			const listMsgRapidas = ref([])
			const msgRapidaSelected = ref('')
			const listHSM = ref([])
			const selectedWhatsApp = ref('')
			const hsmSelectedAgendamento = ref('')
			const selectedParams = ref([])
			const options = ref('numero')
			const stateLocal = reactive({
				isLoading: false,
				message: '',
				//options: "numero",
				chatMessages: [],
				chatIdClient: [],
				dadosAgendamentoCriado: [],
				selectedChatId: '',
				diferencaData: 25,
				buttonControl: false,
				usersActive: 0,
				dadosQueue: [],
				results: [],
				value: '',
				mascara: '#############',
				search: '',
				isOpen: false,
				arrowCounter: -1,
				listMsgRapidas: [],
			})

			// Functions

			async function onChange() {
				try {
					if (stateLocal.search.length > 4 && options.value == 'nome') {
						let responseClient = await ClienteService.getClienteByName(stateLocal.search)
						stateLocal.results = responseClient.data

						stateLocal.isOpen = true
						stateLocal.isLoading = false
					} else if (stateLocal.search.length == 0 && options.value == 'nome') {
						stateLocal.chatMessages = []
						stateLocal.search = ''
						stateLocal.isOpen = false
						stateLocal.isLoading = false
						stateLocal.usersActive = 1
					}
				} catch (e) {}
			}

			function setResult(value) {
				stateLocal.search = value.nome
				stateLocal.chatIdClient = value
				stateLocal.isOpen = false
			}

			function onArrowDown() {
				if (stateLocal.arrowCounter < stateLocal.results.length) {
					stateLocal.arrowCounter = stateLocal.arrowCounter + 1
				}
			}

			function onArrowUp() {
				if (stateLocal.arrowCounter > 0) {
					stateLocal.arrowCounter = stateLocal.arrowCounter - 1
				}
			}

			function onEnter() {
				stateLocal.search = stateLocal.results[this.arrowCounter].nome
				stateLocal.isOpen = false
				stateLocal.arrowCounter = -1
			}

			timezoneDefault.value = process.env.VUE_APP_TIMEZONE ? process.env.VUE_APP_TIMEZONE : 'America/Sao_Paulo'

			async function searchrDadosChats() {
				stateLocal.message = ''
				stateLocal.isLoading = true
				let response
				if (stateLocal.search == '' || stateLocal.search == null) {
					toast.warning(tm('erroCLiente14'))
					stateLocal.isLoading = false
				} else if (options.value == 'numero') {
					try {
						response = await ChatService.getHistoryByStartDateByEndDate(
							new Date().addDays(-30).toISOString(),
							new Date().toISOString(),
							stateLocal.search,
							store.state.usuario.empresaId,
						)
					} catch (e) {
						//console.log(e);
					} finally {
						stateLocal.isLoading = false
					}
				} else if (options.value == 'nome') {
					try {
						response = await ChatService.getHistoryByStartDateByEndDate(
							new Date().addDays(-30).toISOString(),
							new Date().toISOString(),
							stateLocal.chatIdClient.numero,
							store.state.usuario.empresaId,
						)
					} catch (e) {
					} finally {
						stateLocal.isLoading = false
					}
				}
				stateLocal.chatMessages = response.data

				if (stateLocal.chatMessages.length == 0) {
					this.stateLocal.diferencaData = 25
					toast.warning(tm('erroCLiente15'))
					return
				}

				let lastItemData = stateLocal.chatMessages[stateLocal.chatMessages.length - 1].timestamp

				try {
					let lastMessageEmpresaIdChatId = await ChatService.getCurrentMonthItemsForChatId(
						stateLocal.chatMessages[stateLocal.chatMessages.length - 1].empresaId,
						options.value == 'nome' ? stateLocal.chatIdClient.numero : stateLocal.search,
					)
					stateLocal.dadosQueue = lastMessageEmpresaIdChatId.data
					stateLocal.usersActive = lastMessageEmpresaIdChatId.data.filter(x => x.active == true).length
				} catch (e) {
					//console.log("Error", e);
				} finally {
					const modal = document.getElementById('messageBodyModal')
					$('#messageBodyModal').animate({scrollTop: modal.scrollHeight}, 'slow')
				}

				stateLocal.diferencaData = differenceInHours(new Date(), new Date(lastItemData))
			}

			async function gerarAtendimento(tipo) {
				let myuuid = uuidv4()
				let responseConfigMediaId = []
				try {
					let campanhaDefault = store.state.listConfiguracoes.filter(
						x => x.chave == 'atendimento.ativo.campanha.default',
					)

					let response = await UsuarioService.LoadConfigurationsMediaId(
						campanhaDefault[0].chave,
						campanhaDefault[0].campanhaId,
					)

					responseConfigMediaId = response.data
				} catch (e) {
					//console.log("Error ", e);
				} finally {
					let custom = {
						'@idLoja': store.state.usuario.empresa.idLojaManager
							? store.state.usuario.empresa.idLojaManager
							: '',
						MediaId: responseConfigMediaId[0].mediaId,
					}
					let param = {
						empresaId: stateLocal.chatMessages[stateLocal.chatMessages.length - 1].empresaId,
						flowUUID: stateLocal.chatMessages[stateLocal.chatMessages.length - 1].flowId,
						chatUUID: myuuid,
						flowName: 'ATH Chat',
						channel: stateLocal.chatMessages[stateLocal.chatMessages.length - 1].channel,
						priority: 50,

						GoalPoint: false,
						queueId: responseConfigMediaId[0].nome,
						websocket: null,

						chatId: stateLocal.chatMessages[stateLocal.chatMessages.length - 1].chatId,
						agentId: store.state.usuario.id,
						instanceId: null,
					}

					ChatService.GerarAtendimento(custom, param)
						.then(response => {
							stateLocal.dadosAgendamentoCriado = response.data

							toast.info(tm('msgGerarAth'))
						})
						.catch(error => {
							//console.log("Error ", error);
						})
						.finally(() => {
							emit('closeBusca')
							//getChats();

							if (tipo == 'inputText') {
								sendMessage()
							} else {
								sendAnexo(tipo)
							}
						})
				}
			}

			function sendHsmNovoAtendimento() {
				if (!selectedWhatsApp.value) {
					toast.warning(tm('erroWhatsapp'))
				} else {
					let params = ''
					for (let key in selectedParams.value) {
						params += selectedParams.value[key].valor + '|'
					}
					if (params != '') {
						params = params.substring(0, params.length - 1)
					}
					HsmService.AddHsm({
						destinatario: selectedWhatsApp.value,
						mensagem: hsmSelectedAgendamento.value.mensagem,
						dataInclusao: moment().format(),
						remetente: store.state.usuario.nome,
						parametro: params,
						mensagemsalvaId: hsmSelectedAgendamento.value.id,
						usuarioId: store.state.usuario.id,
					})
						.then(response => {
							toast.info(tm('hsmInserido'))
							clearHsm()
						})
						.catch(error => {
							toast.warning(tm('erroHsm'))
						})

					$(this.$refs.modalHsmNovoAtendimento).modal('toggle')
				}
			}

			const getListHsm = async () => {
				listHSM.value = []
				try {
					const response = await ListService.getMensagensSalvas(store.state.usuario.empresaId)
					listHSM.value = response.data
				} catch (error) {
					//console.log("Error", error);
				}
			}

			function clearHsm() {
				//emit("closeBusca");
				hsmSelectedAgendamento.value = ''
				selectedWhatsApp.value = ''
				selectedParams.value = []
				emit('closeBusca')
			}

			async function sendMessage(event, email) {
				let chatSelecionado = stateLocal.dadosAgendamentoCriado
				updateChatStatus(chatSelecionado)
				if (event) {
					event.preventDefault()
				}

				if (stateLocal.message && stateLocal.message.trim() != '') {
					let msg = stateLocal.message.trim()
					//clearMessage();
					if (
						chatSelecionado.channel === constants.TipoCanal.indexOf('WHATSAPP') ||
						chatSelecionado.channel === constants.TipoCanal.indexOf('WHATSAPPBUSINESS')
					) {
						const resMedia = await NumeroEnterpriseService.GetNumberByMediaId(
							chatSelecionado.customVariables['MediaId'],
						)

						const respToken = await HermesApiService.getToken(
							resMedia.data.url,
							resMedia.data.usuario,
							Decipher.decrypt(resMedia.data.senha),
						)

						let hermesClient = {
							url: resMedia.data.url,
							mediaId: resMedia.data.mediaId,
							usuario: resMedia.data.usuario,
							tokenTimestamp: new Date(),
							token: respToken.data.jwt,
						}
						HermesApiService.sendMessage(
							hermesClient,
							chatSelecionado.chatId,
							msg,
							chatSelecionado.channel,
							hermesClient.mediaId,
						)
							.then(response => {
								return addToChatHistory(msg, chatSelecionado, undefined, response)
							})
							.catch(e => {
								toast.warning(tm('erroEnviarMensagem'))
							})
					} else if (chatSelecionado.channel === constants.TipoCanal.indexOf('TELEGRAM')) {
						store
							.dispatch('sendMessageTelegram', {
								msg: msg,
								chatId: chatSelecionado,
							})
							.then(r => {
								return addToChatHistory(msg, chatSelecionado)
							})
							.catch(e => {
								toast.warning(tm('erroEnviarMensagem'))
							})
					} else if (chatSelecionado.channel === constants.TipoCanal.indexOf('WEBCHAT')) {
						let hub = await store.dispatch('getConectionChat', {
							chatQueue: chatSelecionado,
						})
						const response = await store.dispatch('sendMessageChatHubChatId', {
							msg: msg,
							chatQueue: chatSelecionado,
							hub: hub,
						})
						if (!response) {
							toast.warning(tm('erroEnviarMensagem'))
						} else {
							return addToChatHistory(msg, chatSelecionado)
						}
					} else {
						return addToChatHistory(msg, chatSelecionado, email)
					}
				}
			}

			function addToChatHistory(msg, chatQueue, email, resp) {
				if (chatQueue) {
					let messageId =
						resp && resp.data && resp.data.message && resp.data.message.id ? resp.data.message.id : null
					let message = {
						chatUUID: chatQueue.chatUUID,
						empresaId: chatQueue.empresaId,
						channel: chatQueue.channel,
						message: msg,
						agentId: chatQueue.agentId,
						chatId: chatQueue.chatId,
						flowId: chatQueue.flowUUID,
						stepFlowId: 0,
						stepName: 'Atendimento Humano',
						incoming: false,
						timestamp: moment().format(),
						protocol: chatQueue.protocol,
						messageId: messageId,
					}
					if (email !== undefined) {
						message.email = email
					}
					return ChatService.AddToChatHistory(message)
						.then(response => {
							getChatMessages().then(() => {
								scrollToBottom()
							})
						})
						.catch(error => {
							console.error(error)
						})
				}
			}

			function searchInMessage() {
				let index = 0
				let termo = document.getElementById('searchForm').elements['searchItem'].value.toLowerCase().trim()

				let divs = document.getElementsByClassName('chm')

				lastTermo.value = termo
				let arrFinds = props.messages.filter(x => x.message.toLowerCase().indexOf(termo) > -1)
				itemsFound.value = arrFinds.length
			}

			function clearMsgRapida() {
				topicoSelected.value = 0
				msgRapidaSelected.value = ''
			}

			function enviarMsgRapida() {
				stateLocal.message = msgRapidaSelected.value
			}

			const getTopicosMsgRapidas = async () => {
				listTopicos.value = []
				if (store.state.usuario.empresaId) {
					try {
						const response = await ListService.getTopicosMensagemRapida(1)

						listTopicos.value = response.data
					} catch (error) {
						console.error(error)
					}
				}
			}

			// Envio de Arquivos -- Inicio

			function clearFile() {
				fileSelected.value = ''
				fileAnexo.value = ''
			}

			async function sendAnexo(tipo) {
				let chatSelecionado = stateLocal.dadosAgendamentoCriado
				updateChatStatus(chatSelecionado)
				let msg = ''
				let fileType = ''
				let nomeArquivo = ''
				switch (tipo) {
					case 'inputImage':
						msg = 'IMAGE|'
						fileType = 'image'
						nomeArquivo = fileSelected.value.name
						break
					case 'inputAudio':
						msg = 'AUDIO|'
						fileType = 'audio'
						nomeArquivo = fileSelected.value.name
						break
					case 'inputVideo':
						msg = 'VIDEO|'
						fileType = 'video'
						nomeArquivo = fileSelected.value.name
						break
					case 'inputDocument':
						msg = 'DOCUMENT|'
						fileType = 'document'
						nomeArquivo = fileSelected.value.name
						break
					case 'inputVoice':
						msg = 'VOICE|'
						fileType = 'voice'
						nomeArquivo = 'source.ogg'
						break
					default:
						break
				}
				let splitNome = nomeArquivo.split('.')
				let extension = splitNome[splitNome.length - 1]
				let texto = ''
				for (let i = 0; i < splitNome.length - 1; i++) {
					texto = texto + splitNome[i]
				}
				let path = chatSelecionado.flowUUID + '/' + fileType + '/'
				let base64 = fileAnexo.value.split(';base64,')[1]
				try {
					const data = await ChatService.AddFileChat({
						path: path,
						base64: base64,
						extension: extension,
					})
					msg = msg + data.data.url + '|' + texto

					const resMedia = await NumeroEnterpriseService.GetNumberByMediaId(
						chatSelecionado.customVariables['MediaId'],
					)

					const respToken = await HermesApiService.getToken(
						resMedia.data.url,
						resMedia.data.usuario,
						Decipher.decrypt(resMedia.data.senha),
					)

					let hermesClient = {
						url: resMedia.data.url,
						mediaId: resMedia.data.mediaId,
						usuario: resMedia.data.usuario,
						tokenTimestamp: new Date(),
						token: respToken.data.jwt,
					}

					if (chatSelecionado.channel === constants.TipoCanal.indexOf('WHATSAPP')) {
						HermesApiService.sendFile(
							hermesClient,
							chatSelecionado.chatId,
							msg,
							chatSelecionado.channel,
							hermesClient.mediaId,
						)
							.then(response => {
								return addToChatHistory(msg, chatSelecionado, undefined, response)
							})
							.catch(e => {
								toast.warning(tm('erroEnviarArquivo'))
								message.value = msg
							})
					} else if (chatSelecionado.channel === constants.TipoCanal.indexOf('WHATSAPPBUSINESS')) {
						HermesApiService.sendFile(
							null,
							chatSelecionado.chatId,
							msg,
							chatSelecionado.channel,
							hermesClient.mediaId,
						)
							.then(response => {
								return addToChatHistory(msg, chatSelecionado, undefined, response)
							})
							.catch(e => {
								toast.warning(tm('erroEnviarArquivo'))
								message.value = msg
							})
					} else if (chatSelecionado.channel === constants.TipoCanal.indexOf('TELEGRAM')) {
						store
							.dispatch('sendMessageTelegram', {
								msg: msg,
								chatId: chatSelecionado,
							})
							.then(r => {
								return addToChatHistory(msg, chatSelecionado)
							})
							.catch(e => {
								toast.warning(tm('erroEnviarArquivo'))
							})
					} else if (chatSelecionado.channel === constants.TipoCanal.indexOf('WEBCHAT')) {
						let hub = await store.dispatch('getConectionChat', {
							chatQueue: chatSelecionado,
						})

						const resp = await store.dispatch('sendMessageChatHubChatId', {
							msg: msg,
							chatQueue: chatSelecionado,
							hub: hub,
						})

						// const resp = await sendMessageChatHub(msg);
						if (!resp) {
							toast.warning(tm('erroEnviarArquivo'))
						} else {
							addToChatHistory(msg, chatSelecionado)
						}
					} else {
						addToChatHistory(msg, chatSelecionado)
					}
				} catch (err) {
					toast.warning(tm('erroEnviarArquivo'))
				}
			}

			async function updateChatStatus(chatSelecionado) {
				try {
					let response = await ChatService.StatusDates2(
						new Date().addDays(-2).getTime(),
						new Date().getTime(),
						chatSelecionado.empresaId,
					)

					let filtro = response.data.filter(x => x.chatId == chatSelecionado.chatId)

					let timelineId =
						filtro[filtro.length - 1].timeline[filtro[filtro.length - 1].timeline.length - 1].id

					let newTimeLine = {
						Id: timelineId + 1,
						Status: 'Transferido p/ Humano',
						DataHora: new Date().toISOString(),
					}

					ChatService.UpdateChatStatus(filtro[filtro.length - 1].chatUUID, false, newTimeLine).then(res => {
						////console.log("Reposne update", res);
					})
				} catch (e) {
					//console.log("Error ", e);
				}
			}

			function onFileChange(event) {
				var files = event.target.files || event.dataTransfer.files
				if (!files.length) return

				if (!Validar.tamanhoArquivo(files[0])) {
					toast.warning(tm('erroArquivoTamanho') + files[0].name)
					return
				}
				if (!Validar.tipoArquivosPermitidos(files[0].name, event.target.name)) {
					toast.warning(tm('erroArquivoFormato') + files[0].name)
					return
				}
				fileSelected.value = files[0]
				createAnexo(files[0], event.target.name)
				toast.info(tm('arquivo') + ' ' + files[0].name + ' ' + tm('carregado') + '!')
			}

			function createAnexo(file, tipo) {
				switch (tipo) {
					case 'inputImage':
						fileAnexo.value = new Image()
						break
					case 'inputAudio':
						fileAnexo.value = new Audio()
						break
					case 'inputVideo':
						fileAnexo.value = document.createElement('video')
						break
					default:
						break
				}
				var reader = new FileReader()
				reader.onload = e => {
					fileAnexo.value = e.target.result
				}
				reader.readAsDataURL(file)
			}

			function startRecord() {
				let start = new Date()
				mediaRecorder.value.start()
				timeRecording.value = setInterval(function () {
					let sec = parseInt((new Date() - start) / 1000)
					let min = parseInt(sec / 60)
					timeText.value = (min < 10 ? '0' : '') + min + ':' + ((sec < 10 ? '0' : '') + (sec - 60 * min))
				}, 1000)
				isRecording.value = !isRecording.value
			}

			function stopRecord() {
				mediaRecorder.value.stop()
				clearInterval(timeRecording.value)
				isRecording.value = !isRecording.value
				timeText.value = '00:00'
			}

			function getPngDimensions(base64) {
				const header = atob(base64.slice(22, 70)).slice(16, 24)
				const uint8 = Uint8Array.from(header, c => c.charCodeAt(0))
				const dataView = new DataView(uint8.buffer)
				return {
					width: dataView.getInt32(0),
					height: dataView.getInt32(4),
				}
			}

			const splitImage = (img, callback) => () => {
				const canvas = document.createElement('canvas')
				const ctx = canvas.getContext('2d')
				const printHeight = (img.height * constants.PAGE_WIDTH) / img.width
				canvas.width = constants.PAGE_WIDTH
				for (let pages = 0; printHeight > pages * constants.PAGE_HEIGHT; pages++) {
					canvas.height = Math.min(constants.PAGE_HEIGHT, printHeight - pages * constants.PAGE_HEIGHT)
					ctx.drawImage(img, 0, -pages * constants.PAGE_HEIGHT, canvas.width, printHeight)
					content.push({
						image: canvas.toDataURL(),
						margin: [0, 5],
						width: constants.PAGE_WIDTH,
					})
				}
				callback()
			}

			function downloadPdf() {
				var docDefinition = {
					content: content,
				}
				try {
					let nome =
						(stateLocal.dadosAgendamentoCriado.contactName
							? stateLocal.dadosAgendamentoCriado.contactName +
							  ' (' +
							  stateLocal.dadosAgendamentoCriado.chatId +
							  ')'
							: sstateLocal.dadosAgendamentoCriado.chatId) +
						' _ ' +
						new Date().toLocaleString('pt-br') +
						'.pdf'
					pdfMake.createPdf(docDefinition).download(nome)
					toast.clear()
					toast.success(tm('msgExportar02'))
				} catch (e) {
					toast.error(tm('erroExportar'))
				}
			}

			// Envio de Arquivos -- Fim

			getListHsm()
			getTopicosMsgRapidas()

			return {
				moment,
				Validar,
				timezoneDefault,
				searchInMessage,
				lastTermo,
				lastIndex,
				itemsFound,
				itemFind,
				tm,
				stateLocal,
				selectedParams,
				hsmSelectedAgendamento,
				selectedWhatsApp,
				listHSM,
				searchrDadosChats,
				gerarAtendimento,
				sendMessage,
				fileSelected,
				sendHsmNovoAtendimento,
				fileAnexo,
				onFileChange,
				sendAnexo,
				stopRecord,
				startRecord,
				splitImage,
				downloadPdf,
				getPngDimensions,
				timeText,
				clearFile,
				clearMsgRapida,
				topicoSelected,
				listTopicos,
				ListService,
				enviarMsgRapida,
				listMsgRapidas,
				msgRapidaSelected,
				clearHsm,
				onChange,
				setResult,
				onArrowDown,
				onArrowUp,
				onEnter,
				options,
			}
		},
		watch: {
			topicoSelected: function (newVal, oldVal) {
				if (newVal) {
					this.ListService.getMensagensRapida(newVal)
						.then(response => {
							this.stateLocal.listMsgRapidas = response.data
						})
						.catch(error => {
							console.debug(error)
						})
				}
			},
			options: function (newVal, oldVal) {
				this.stateLocal.search = ''
				this.stateLocal.message = ''
				this.stateLocal.isOpen = false
				this.stateLocal.isLoading = false
				this.stateLocal.chatMessages = []
				this.stateLocal.diferencaData = 25

				if (newVal == 'nome') {
					this.stateLocal.mascara = 'X* X* X* X* X*'
				} else {
					this.stateLocal.mascara = '#############'
				}
			},
		},
	}
