import {createApp} from 'vue'

import CKEditor from '@ckeditor/ckeditor5-vue'
import Toast from 'vue-toastification'
import Maska from 'maska'
import {createI18n} from 'vue-i18n'
import CountryFlag from 'vue-country-flag-next'
import VueTagsInput from '@sipec/vue3-tags-input'

import 'intro.js/introjs.css'
import 'vue-toastification/dist/index.css'

import App from './App.vue'
import router from './router'
import UsuarioService from './services/UsuarioService'
import store from './store'

const DEFAULT_LANG = process.env.VUE_APP_LANG ? process.env.VUE_APP_LANG : 'pt'
sessionStorage.setItem('lang', DEFAULT_LANG)

const locales = {
	en: require('./lang/i18n/en.json'),
	es: require('./lang/i18n/es.json'),
	pt: require('./lang/i18n/pt.json'),
}

const i18n = createI18n({
	legacy: false,
	locale: DEFAULT_LANG,
	fallbackLocale: 'pt',
	messages: locales,
})

const options = {
	showCloseButtonOnHover: true,
	transition: 'Vue-Toastification__fade',
	maxToasts: 7,
	transitionDuration: 400,
	filterBeforeCreate: (toast, toasts) => {
		if (toasts.some(t => t.type === toast.type)) return false

		return toast
	},
}

const app = createApp(App)

app.use(store)
app.use(router)
app.use(CKEditor)
app.use(Toast, options)
app.use(Maska)
app.use(i18n)
app.use(CountryFlag)
app.use(VueTagsInput)

app.mount('#app')

// Verify if token is valid
const getToken = () => localStorage.getItem('token')
const getUser = () => JSON.parse(localStorage.getItem('usuario'))

setInterval(() => {
	const token = getToken()
	const user = getUser()

	if (token) {
		UsuarioService.VerificaToken(user.id, token)
	}
}, 15000)
