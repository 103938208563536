import Api from './Api'
import {environment} from '../environments/env'
import {route} from './routeDictionary'

export default {
	ClientProfile(mediaId, user) {
		return Api.get(`${environment.apiOms}${route.ClientProfile}`, {
			headers: {'X-API-MediaId': mediaId},
			params: {uid: user},
		})
	},
	PluginConfigurationListByCompanyId(companyId) {
		return Api.get(`${environment.apiOms}${route.PluginConfigurationListByCompanyId}`, {
			params: {companyId: companyId},
		})
	},
	GetOrderByUid(mediaId, userData, channelId, arr) {
		return Api.get(`${environment.apiOms}${route.GetOrderByUid}`, {
			headers: {'X-API-MediaId': mediaId, 'X-API-UserData': userData, 'X-API-ChannelId': channelId},
			params: arr,
		})
	},
	OrdersByClientProperty(mediaId, arr) {
		//console.log("qqq", mediaId, arr)
		return Api.get(`${environment.apiOms}${route.OrdersByClientProperty}`, {
			headers: {'X-API-MediaId': mediaId},
			params: arr,
		})
	},
	UpdateOrder(mediaId, channelId, userData, orderUid, origin, orderBody) {
		return Api.put(`${environment.apiOms}${route.UpdateOrder}`, orderBody, {
			headers: {'X-API-MediaId': mediaId, 'X-API-UserData': userData, 'X-API-ChannelId': channelId},
			params: {orderUid: orderUid, origin: origin},
		})
	},
}
