
	import {computed, reactive, ref, watch} from 'vue'
	import {useToast} from 'vue-toastification'
	import {useStore} from 'vuex'
	import Validar from '../../util/Validadores'
	import ClienteService from '../../services/ClienteService'
	import ChatService from '../../services/ChatService'
	import EnderecoService from '../../services/EnderecoService'
	import NumeroEnterpriseService from '../../services/NumeroEnterpriseService'
	import Modal from '../../components/Modal'
	import constants from '../../util/constants'
	import {useI18n} from 'vue-i18n'

	const toast = useToast()
	export default {
		name: 'Cliente',
		components: {Modal},
		setup() {
			const {tm} = useI18n()
			const store = useStore()
			const stateLocal = reactive({
				id: 0,
				nome: '',
				sobrenome: '',
				rg: '',
				cpf: '',
				nomeMae: '',
				observacao: '',
				codClienteZappag: null,
				crmEnderecoCliente: {},
				crmCanalCliente: [],
				empresaId: 0,
				isLoadingCEP: false,
				isLoadingCidade: false,
				isloading: false,
				showModalExcluir: false,
				showModalIncluir: false,
				canalExcluir: null,
				canalIncluir: {
					valor: '',
					clienteId: 0,
					nome: '',
				},
				informacaoAdicional1: '',
				informacaoAdicional2: '',
				informacaoAdicional3: '',
				informacaoAdicional4: '',
				informacaoAdicional5: '',
				informacaoAdicional6: '',
				informacaoAdicional7: '',
				informacaoAdicional8: '',
				informacaoAdicional9: '',
				informacaoAdicional10: '',
				canais: [{nome: 'WHATSAPP'}, {nome: 'EMAIL'}],
			})
			const clienteTemCadastro = ref(false)
			const buscarNovamente = ref(true)
			const canalcadastro = ref({clienteId: 0, tipo: 0, valor: ''})
			const habilitarCadastroCliente = ref(false)
			const showCriarRegistro = ref(false)
			const buscaCliente = ref({nome: '', cpf: ''})
			const resultBuscaClientes = ref([])
			const clienteEncontrado = ref(null)
			const selectedChatQueue = computed(() => {
				return store.state.selectedChatQueue
			})
			const fileSelected = ref('')
			const fileAnexo = ref('')
			const paisList = ref([])
			const paisSelect = ref(null)
			const estadoList = ref([])
			const estadoSelect = ref(null)
			const cidadeList = ref([])
			const cidadeSelect = ref(null)
			const autocadastrozappag = ref(false)
			const ath = ref(false)
			const numeroEnterprise = ref(null)

			//Watch
			watch(estadoSelect, newVal => {
				stateLocal.isLoadingCidade = true
				if (newVal) {
					EnderecoService.GetCidades(newVal)
						.then(response => {
							cidadeList.value = response.data
						})
						.catch(error => {
							//console.log(error);
						})
						.finally(() => {
							stateLocal.isLoadingCidade = false
						})
				} else {
					cidadeList.value = []
					cidadeSelect.value = null
					stateLocal.isLoadingCidade = false
				}
			})

			async function clienteApi(numeroExiste) {
				stateLocal.informacaoAdicional1 = ''
				stateLocal.informacaoAdicional2 = ''
				stateLocal.informacaoAdicional3 = ''
				stateLocal.informacaoAdicional4 = ''
				stateLocal.informacaoAdicional5 = ''
				stateLocal.informacaoAdicional6 = ''
				stateLocal.informacaoAdicional7 = ''
				stateLocal.informacaoAdicional8 = ''
				stateLocal.informacaoAdicional9 = ''
				stateLocal.informacaoAdicional10 = ''

				if (numeroExiste != '') {
					try {
						var response = await ClienteService.PostAccessToken()
						var responseConsulta = await ClienteService.PostConsultarClientePorTelefone(
							store.state.selectedChatQueue.chatId,
							response.data.access_token,
						)

						if (
							responseConsulta != '' &&
							responseConsulta.data.status != 'error' &&
							numeroExiste.empresaId == 209
						) {
							stateLocal.id = numeroExiste.id
							stateLocal.nome = responseConsulta.data.data.nome
							stateLocal.sobrenome = responseConsulta.data.data.sobrenome
							stateLocal.cpf = responseConsulta.data.data.cpf
							stateLocal.rg = responseConsulta.data.data.rg
							stateLocal.observacao = responseConsulta.data.data.observacao
							stateLocal.informacaoAdicional1 = responseConsulta.data.data.email
							stateLocal.informacaoAdicional2 = responseConsulta.data.data.id_suprema
							stateLocal.informacaoAdicional3 = responseConsulta.data.data.id_upoker
							stateLocal.informacaoAdicional4 = responseConsulta.data.data.id_cacheta
							stateLocal.informacaoAdicional5 = responseConsulta.data.data.ultimo_clube_suprema
							stateLocal.informacaoAdicional6 = responseConsulta.data.data.ultimo_clube_upoker
							stateLocal.informacaoAdicional7 = responseConsulta.data.data.ultimo_clube_cacheta
							stateLocal.informacaoAdicional8 = responseConsulta.data.data.ultimo_dia_jogado
							stateLocal.crmEnderecoCliente.cep = responseConsulta.data.data.cep
							stateLocal.crmCanalCliente = numeroExiste.crmCanalCliente
							stateLocal.nomeMae = numeroExiste.nomeMae
							let cepCheck = await verificaCEP()

							if (stateLocal.crmEnderecoCliente.cep != '' && cepCheck != undefined) {
								await verificaCEP()
								await salvarCliente()
							} else {
								const resUp = await ClienteService.UpdateCliente({
									id: numeroExiste.id,
									nome: stateLocal.nome,
									sobrenome: stateLocal.sobrenome,
									nomeMae: numeroExiste.nomeMae,
									cpf: stateLocal.cpf ? stateLocal.cpf.replaceAll('.', '').replaceAll('-', '') : '',
									rg: stateLocal.rg,
									observacao: stateLocal.observacao,
									empresaId: numeroExiste.empresaId,
									crmCanalCliente: numeroExiste.crmCanalCliente,
									crmEnderecoCliente: numeroExiste.crmEnderecoCliente,
									informacaoAdicional1: stateLocal.informacaoAdicional1,
									informacaoAdicional2: stateLocal.informacaoAdicional2,
									informacaoAdicional3: stateLocal.informacaoAdicional3,
									informacaoAdicional4: stateLocal.informacaoAdicional4,
									informacaoAdicional5: stateLocal.informacaoAdicional5,
									informacaoAdicional6: stateLocal.informacaoAdicional6,
									informacaoAdicional7: stateLocal.informacaoAdicional7,
									informacaoAdicional8: stateLocal.informacaoAdicional8,
									informacaoAdicional9: stateLocal.informacaoAdicional9,
									informacaoAdicional10: stateLocal.informacaoAdicional10,
								})
							}
						} else {
							//console.log('clientapi', responseConsulta.data)
						}
					} catch (e) {
						console.error(e)
						console.error('Update Cliente ', e)
					}
				}
			}

			async function getDadosCliente() {
				stateLocal.isloading = true
				habilitarCadastroCliente.value = false

				await store.dispatch('cliente/getDadosCliente')
				await clienteApi(store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId])
				if (store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]) {
					clienteTemCadastro.value = true
					buscarNovamente.value = true
					let dado = store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
					clienteEncontrado.value = store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
					stateLocal.id = dado.id
					stateLocal.nome = dado.nome
					stateLocal.sobrenome = dado.sobrenome
					stateLocal.rg = dado.rg
					stateLocal.cpf = dado.cpf ? Validar.formatCnpjCpf(dado.cpf) : ''
					stateLocal.nomeMae = dado.nomeMae
					stateLocal.observacao = dado.observacao
					stateLocal.codClienteZappag = dado.codClienteZappag
					stateLocal.empresaId = dado.empresaId
					stateLocal.crmCanalCliente = dado.crmCanalCliente
					stateLocal.crmEnderecoCliente = dado.crmEnderecoCliente[0] ? dado.crmEnderecoCliente[0] : {}
					stateLocal.crmEnderecoCliente.cep = stateLocal.crmEnderecoCliente.cep
						? Validar.formatCEP(stateLocal.crmEnderecoCliente.cep)
						: ''
					paisSelect.value = stateLocal.crmEnderecoCliente.codEstado
						? stateLocal.crmEnderecoCliente.codPais
						: getCodigoPais('brasil')
					estadoSelect.value = stateLocal.crmEnderecoCliente.codEstado
					cidadeSelect.value = stateLocal.crmEnderecoCliente.codCidade
					stateLocal.informacaoAdicional1 = dado.informacaoAdicional1
					stateLocal.informacaoAdicional2 = dado.informacaoAdicional2
					stateLocal.informacaoAdicional3 = dado.informacaoAdicional3
					stateLocal.informacaoAdicional4 = dado.informacaoAdicional4
					stateLocal.informacaoAdicional5 = dado.informacaoAdicional5
					stateLocal.informacaoAdicional6 = dado.informacaoAdicional6
					stateLocal.informacaoAdicional7 = dado.informacaoAdicional7
					stateLocal.informacaoAdicional8 = dado.informacaoAdicional8
					stateLocal.informacaoAdicional9 = dado.informacaoAdicional9
					stateLocal.informacaoAdicional10 = dado.informacaoAdicional10
					await verificaCEP()
				} else {
					try {
						var response = await ClienteService.PostAccessToken()
						var responseConsulta = await ClienteService.PostConsultarClientePorTelefone(
							store.state.selectedChatQueue.chatId,
							response.data.access_token,
						)
						if (
							responseConsulta != '' &&
							responseConsulta.data.status != 'error' &&
							stateLocal.empresaId == 209
						) {
							buscarCliente()
							stateLocal.id = stateLocal.id
							stateLocal.nome = responseConsulta.data.data.nome
							stateLocal.sobrenome = responseConsulta.data.data.sobrenome
							stateLocal.cpf = responseConsulta.data.data.cpf
							stateLocal.rg = responseConsulta.data.data.rg
							stateLocal.observacao = responseConsulta.data.data.observacao
							stateLocal.informacaoAdicional1 = responseConsulta.data.data.email
							stateLocal.informacaoAdicional2 = responseConsulta.data.data.id_suprema
							stateLocal.informacaoAdicional3 = responseConsulta.data.data.id_upoker
							stateLocal.informacaoAdicional4 = responseConsulta.data.data.id_cacheta
							stateLocal.informacaoAdicional5 = responseConsulta.data.data.ultimo_clube_suprema
							stateLocal.informacaoAdicional6 = responseConsulta.data.data.ultimo_clube_upoker
							stateLocal.informacaoAdicional7 = responseConsulta.data.data.ultimo_clube_cacheta
							stateLocal.informacaoAdicional8 = responseConsulta.data.data.ultimo_dia_jogado
							stateLocal.crmEnderecoCliente.cep = responseConsulta.data.data.cep

							let cepCheck = await verificaCEP()
							if (stateLocal.crmEnderecoCliente.cep != '' && cepCheck != undefined) {
								await verificaCEP()
								await salvarCliente()
							} else {
								const resAdd = await ClienteService.AddCliente({
									nome: stateLocal.nome,
									nomeMae: stateLocal.nomeMae,
									sobrenome: stateLocal.sobrenome,
									cpf: stateLocal.cpf ? stateLocal.cpf.replaceAll('.', '').replaceAll('-', '') : '',
									rg: stateLocal.rg,
									observacao: stateLocal.observacao,
									empresaId: stateLocal.empresaId,
									crmEnderecoCliente: [],
									informacaoAdicional1: stateLocal.informacaoAdicional1,
									informacaoAdicional2: stateLocal.informacaoAdicional2,
									informacaoAdicional3: stateLocal.informacaoAdicional3,
									informacaoAdicional4: stateLocal.informacaoAdicional4,
									informacaoAdicional5: stateLocal.informacaoAdicional5,
									informacaoAdicional6: stateLocal.informacaoAdicional6,
									informacaoAdicional7: stateLocal.informacaoAdicional7,
									informacaoAdicional8: stateLocal.informacaoAdicional8,
									informacaoAdicional9: stateLocal.informacaoAdicional9,
									informacaoAdicional10: stateLocal.informacaoAdicional10,
									crmCanalCliente: [
										{
											tipo: store.state.selectedChatQueue.channel,
											valor: store.state.selectedChatQueue.chatId,
										},
									],
								})
								stateLocal.id = resAdd.data.id
								stateLocal.crmCanalCliente = resAdd.data.crmCanalCliente
								stateLocal.crmEnderecoCliente = resAdd.data.crmEnderecoCliente[0]
								store.commit('cliente/setClienteSelected', {
									chatId: store.state.selectedChatQueue.chatId,
									dados: resAdd.data,
								})
								toast.info(tm('msgCliente01'))
								buscarNovamente.value = false
							}
							await getDadosCliente()
							stateLocal.isloading = false
						} else {
							stateLocal.informacaoAdicional1 = ''
							stateLocal.informacaoAdicional2 = ''
							stateLocal.informacaoAdicional3 = ''
							stateLocal.informacaoAdicional4 = ''
							stateLocal.informacaoAdicional5 = ''
							stateLocal.informacaoAdicional6 = ''
							stateLocal.informacaoAdicional7 = ''
							stateLocal.informacaoAdicional8 = ''
							stateLocal.informacaoAdicional9 = ''
							stateLocal.informacaoAdicional10 = ''
							stateLocal.crmEnderecoCliente.cep = ''
							buscarCliente()
						}
					} catch (e) {
						console.error(e)
						console.error('Response Access_token Error ', e)
					}
				}
				stateLocal.isloading = false
			}

			function apagarCampo(campo) {
				buscaCliente.value[campo] = ''
			}

			function incluirCanal() {
				stateLocal.canalIncluir.valor = ''
				stateLocal.canalIncluir.nome = 'WHATSAPP'
				stateLocal.showModalIncluir = true
			}

			async function confirmarIncluir() {
				const dadosOk = await dadosCanal()
				if (!dadosOk) {
					return false
				}
				let tipo =
					numeroEnterprise.value && numeroEnterprise.value.usuario != 'smbdummy'
						? constants.TipoCanal.indexOf('WHATSAPP')
						: constants.TipoCanal.indexOf('WHATSAPPBUSINESS')
				let canal = {
					tipo:
						stateLocal.canalIncluir.nome === 'WHATSAPP'
							? tipo
							: constants.TipoCanal.indexOf(stateLocal.canalIncluir.nome),
					valor:
						stateLocal.canalIncluir.nome === 'WHATSAPP'
							? stateLocal.canalIncluir.valor
									.replaceAll('+', '')
									.replaceAll('(', '')
									.replaceAll(')', '')
									.replaceAll('-', '')
									.replaceAll(' ', '')
							: stateLocal.canalIncluir.valor,
					clienteId: stateLocal.id,
				}
				try {
					const response = await store.dispatch('cliente/SalvarCanal', canal)
					let chatId = store.state.selectedChatQueue.chatId
					store.state.cliente.dadosCliente[chatId].crmCanalCliente.push(response.data)
					toast.info(tm('msgCanal01'))
				} catch (error) {
					//console.log(error);
					toast.warning(tm('erroCanal01'))
				} finally {
					stateLocal.showModalIncluir = false
				}
			}

			async function dadosCanal() {
				if (!stateLocal.canalIncluir.valor) {
					document.getElementById('inputCanal').focus()
					toast.warning(tm('erroCanal02'))
					return false
				}
				try {
					if (stateLocal.canalIncluir.nome === 'EMAIL') {
						const resEmail = await ClienteService.getClienteByChannel(
							store.state.usuario.empresaId,
							stateLocal.canalIncluir.valor,
							constants.TipoCanal.indexOf('EMAIL'),
						)
						if (resEmail.data) {
							document.getElementById('inputCanal').focus()
							toast.warning(tm('erroCanal03') + resEmail.data.nome + ' CPF: ' + resEmail.data.cpf)
							return false
						}
					} else {
						const resCelular = await ClienteService.getClienteByChannel(
							store.state.usuario.empresaId,
							stateLocal.canalIncluir.valor
								.replaceAll('+', '')
								.replaceAll('(', '')
								.replaceAll(')', '')
								.replaceAll('-', '')
								.replaceAll(' ', ''),
							constants.TipoCanal.indexOf('WHATSAPP'),
						)
						if (resCelular.data) {
							document.getElementById('inputCanal').focus()
							toast.warning(tm('erroCanal04') + resCelular.data.nome + ' CPF: ' + resCelular.data.cpf)
							return false
						}
						const resCelularB = await ClienteService.getClienteByChannel(
							store.state.usuario.empresaId,
							stateLocal.canalIncluir.valor
								.replaceAll('+', '')
								.replaceAll('(', '')
								.replaceAll(')', '')
								.replaceAll('-', '')
								.replaceAll(' ', ''),
							constants.TipoCanal.indexOf('WHATSAPPBUSINESS'),
						)
						if (resCelularB.data) {
							document.getElementById('inputCanal').focus()
							toast.warning(tm('erroCanal04') + resCelularB.data.nome + ' CPF: ' + resCelularB.data.cpf)
							return false
						}
					}
				} catch (error) {
					//console.log(error);
					toast.warning(tm('erroCanal01'))
					return false
				}
				//
				return true
			}

			function excluirCanal(obj) {
				stateLocal.canalExcluir = obj
				stateLocal.showModalExcluir = true
			}

			async function confirmarExcluir() {
				if (stateLocal.canalExcluir && stateLocal.canalExcluir.id) {
					let idCanal = stateLocal.canalExcluir.id
					try {
						const response = await store.dispatch('cliente/RemoveCanalCliente', idCanal)
						let chatId = store.state.selectedChatQueue.chatId
						let idx = store.state.cliente.dadosCliente[chatId].crmCanalCliente.findIndex(
							x => x.id == idCanal,
						)
						store.state.cliente.dadosCliente[chatId].crmCanalCliente.splice(idx, 1)
						toast.info(tm('msgCanal02'))
					} catch (error) {
						console.debug(error)
						toast.warning(tm('erroCanal05'))
					} finally {
						stateLocal.showModalExcluir = false
					}
				} else {
					stateLocal.showModalExcluir = false
				}
			}

			function buscarCliente() {
				clienteTemCadastro.value = false
				buscarNovamente.value = true
				buscaCliente.value = {nome: '', cpf: ''}
				resultBuscaClientes.value = []
				stateLocal.id = 0
				stateLocal.nome = ''
				stateLocal.sobrenome = ''
				stateLocal.rg = ''
				stateLocal.cpf = ''
				stateLocal.nomeMae = ''
				stateLocal.observacao = ''
				stateLocal.codClienteZappag = null
				stateLocal.empresaId = store.state.usuario.empresaId
				stateLocal.crmEnderecoCliente = {}
				stateLocal.crmCanalCliente = []
				paisSelect.value = getCodigoPais('brasil')
				estadoSelect.value = null
				cidadeList.value = []
				cidadeSelect.value = null
			}

			async function selecionarCliente(cliente) {
				stateLocal.isloading = true
				clienteTemCadastro.value = true
				buscarNovamente.value = true
				let res = await store.dispatch('cliente/GetClienteById', cliente.id)
				stateLocal.id = res.id
				stateLocal.nome = res.nome
				stateLocal.sobrenome = res.sobrenome
				stateLocal.rg = res.rg
				stateLocal.cpf = res.cpf ? Validar.formatCnpjCpf(res.cpf) : ''
				stateLocal.nomeMae = res.nomeMae
				stateLocal.observacao = res.observacao
				stateLocal.empresaId = res.empresaId
				stateLocal.codClienteZappag = res.codClienteZappag
				stateLocal.crmCanalCliente = res.crmCanalCliente
				stateLocal.crmEnderecoCliente =
					res.crmEnderecoCliente && res.crmEnderecoCliente.length > 0 ? res.crmEnderecoCliente[0] : {}
				stateLocal.crmEnderecoCliente.cep = stateLocal.crmEnderecoCliente.cep
					? Validar.formatCEP(stateLocal.crmEnderecoCliente.cep)
					: ''
				paisSelect.value = stateLocal.crmEnderecoCliente.codPais
					? stateLocal.crmEnderecoCliente.codPais
					: getCodigoPais('brasil')
				estadoSelect.value = stateLocal.crmEnderecoCliente.codEstado
					? stateLocal.crmEnderecoCliente.codEstado
					: null
				cidadeSelect.value = stateLocal.crmEnderecoCliente.codCidade
					? stateLocal.crmEnderecoCliente.codCidade
					: null
				await verificaCEP()
				stateLocal.isloading = false
			}

			async function buscarClientePorNomeCpf() {
				clienteTemCadastro.value = false
				buscarNovamente.value = true
				stateLocal.id = 0
				stateLocal.nome = ''
				stateLocal.sobrenome = ''
				stateLocal.rg = ''
				stateLocal.cpf = ''
				stateLocal.nomeMae = ''
				stateLocal.observacao = ''
				stateLocal.codClienteZappag = null
				stateLocal.empresaId = store.state.usuario.empresaId
				stateLocal.crmEnderecoCliente = {}
				stateLocal.crmCanalCliente = []
				paisSelect.value = getCodigoPais('brasil')
				estadoSelect.value = null
				cidadeSelect.value = null
				cidadeList.value = []
				store.commit('cliente/setBuscaCliente', buscaCliente)
				let retorno
				try {
					if (buscaCliente.value.nome) {
						retorno = await ClienteService.GetClienteByNomeOrCpf(
							store.state.usuario.empresaId,
							buscaCliente.value.nome,
						)
					} else if (buscaCliente.value.cpf) {
						retorno = await ClienteService.GetClienteByNomeOrCpf(
							store.state.usuario.empresaId,
							buscaCliente.value.cpf,
						)
					}
					resultBuscaClientes.value = retorno ? retorno.data : []
				} catch (e) {
					//console.log(e);
					resultBuscaClientes.value = []
				} finally {
					if (resultBuscaClientes.value.length == 0) {
						showCriarRegistro.value = true
					}
				}
			}

			async function sendAnexo() {
				let chatSelecionado = selectedChatQueue.value
				let msg = 'DOCUMENT|'
				let fileType = 'document'
				let nomeArquivo = fileSelected.value.name
				let splitNome = nomeArquivo.split('.')
				let extension = splitNome[splitNome.length - 1]
				let texto = ''
				let x = document.getElementsByClassName('avatar')
				for (let i = 0; i < splitNome.length - 1; i++) {
					texto = texto + splitNome[i]
				}
				let path = chatSelecionado.flowUUID + '/' + fileType + '/'
				let base64 = fileAnexo.value.split(';base64,')[1]
				try {
					const data = await ChatService.AddFileChat({
						path: path,
						base64: base64,
						extension: extension,
					})
					//msg = msg + data.data.url + "|" + texto;
					stateLocal.adicional01 = data.data.url
				} catch (err) {
					console.debug(err)
					toast.warning(tm('erroEnviarArquivo'))
				}
			}

			function onFileChange(event) {
				var files = event.target.files || event.dataTransfer.files
				if (!files.length) return

				if (!Validar.tamanhoArquivo(files[0])) {
					toast.warning(tm('erroArquivoTamanho') + files[0].name)
					return
				}
				if (!Validar.tipoArquivosPermitidos(files[0].name, event.target.name)) {
					toast.warning(tm('erroArquivoFormato') + files[0].name)
					return
				}
				fileSelected.value = files[0]
				createAnexo(files[0], event.target.name)
				toast.info(tm('arquivo') + ' ' + files[0].name + ' ' + tm('carregado') + '!')
			}

			function createAnexo(file, tipo) {
				var reader = new FileReader()
				reader.onload = e => {
					fileAnexo.value = e.target.result
					sendAnexo()
				}
				reader.readAsDataURL(file)
			}

			function clearFile() {
				fileSelected.value = ''
				fileAnexo.value = ''
			}

			async function salvarCliente() {
				if (!dadosCliente()) {
					return false
				}
				try {
					stateLocal.isLoading = true
					let canalChat = stateLocal.crmCanalCliente.find(
						y =>
							y.valor == store.state.selectedChatQueue.chatId &&
							y.tipo == store.state.selectedChatQueue.channel,
					)
					if (!canalChat) {
						const res = await ClienteService.getClienteByChannel(
							store.state.usuario.empresaId,
							store.state.selectedChatQueue.chatId,
							store.state.selectedChatQueue.channel,
						)
						if (res.data && res.data.id != stateLocal.id) {
							let canalExcluir = res.data.crmCanalCliente.find(
								y =>
									y.valor == store.state.selectedChatQueue.chatId &&
									y.tipo == store.state.selectedChatQueue.channel,
							)
							if (canalExcluir) {
								const response = await store.dispatch('cliente/RemoveCanalCliente', canalExcluir.id)
							}
						}
					}
					await verificaCEP()
					stateLocal.crmEnderecoCliente.pais = paisSelect.value ? getNomePais(paisSelect.value) : ' '
					stateLocal.crmEnderecoCliente.estado = estadoSelect.value ? getNomeEstado(estadoSelect.value) : ' '
					stateLocal.crmEnderecoCliente.cidade = cidadeSelect.value ? getNomeCidade(cidadeSelect.value) : ' '
					stateLocal.crmEnderecoCliente.codPais = paisSelect.value
					stateLocal.crmEnderecoCliente.codEstado = estadoSelect.value
					stateLocal.crmEnderecoCliente.codCidade = cidadeSelect.value
					let endereco = {...stateLocal.crmEnderecoCliente}
					endereco.cep = endereco.cep ? endereco.cep.replaceAll('-', '') : ''
					if (stateLocal.id == 0) {
						const resAdd = await ClienteService.AddCliente({
							nome: stateLocal.nome,
							nomeMae: stateLocal.nomeMae,
							sobrenome: stateLocal.sobrenome,
							cpf: stateLocal.cpf ? stateLocal.cpf.replaceAll('.', '').replaceAll('-', '') : '',
							rg: stateLocal.rg,
							observacao: stateLocal.observacao,
							empresaId: stateLocal.empresaId,
							crmEnderecoCliente: [endereco],
							informacaoAdicional1: stateLocal.informacaoAdicional1,
							informacaoAdicional2: stateLocal.informacaoAdicional2,
							informacaoAdicional3: stateLocal.informacaoAdicional3,
							informacaoAdicional4: stateLocal.informacaoAdicional4,
							informacaoAdicional5: stateLocal.informacaoAdicional5,
							informacaoAdicional6: stateLocal.informacaoAdicional6,
							informacaoAdicional7: stateLocal.informacaoAdicional7,
							informacaoAdicional8: stateLocal.informacaoAdicional8,
							informacaoAdicional9: stateLocal.informacaoAdicional9,
							informacaoAdicional10: stateLocal.informacaoAdicional10,
							crmCanalCliente: [
								{
									tipo: store.state.selectedChatQueue.channel,
									valor: store.state.selectedChatQueue.chatId,
								},
							],
						})
						stateLocal.id = resAdd.data.id
						stateLocal.crmCanalCliente = resAdd.data.crmCanalCliente
						stateLocal.crmEnderecoCliente = resAdd.data.crmEnderecoCliente[0]
						store.commit('cliente/setClienteSelected', {
							chatId: store.state.selectedChatQueue.chatId,
							dados: resAdd.data,
						})
						toast.info(tm('msgCliente01'))
						buscarNovamente.value = false
					} else {
						let registroCanal = null

						if (Object.keys(canalChat).length == 0) {
							registroCanal = {
								id: 0,
								clienteId: stateLocal.id,
								tipo: store.state.selectedChatQueue.channel,
								valor: store.state.selectedChatQueue.chatId,
							}
							stateLocal.crmCanalCliente.push(registroCanal)
						} else {
							registroCanal = {
								id: 0,
								clienteId: stateLocal.id,
								tipo: store.state.selectedChatQueue.channel,
								valor: store.state.selectedChatQueue.chatId,
							}
						}
						const resUp = await ClienteService.UpdateCliente({
							id: stateLocal.id,
							nome: stateLocal.nome,
							sobrenome: stateLocal.sobrenome,
							nomeMae: stateLocal.nomeMae,
							cpf: stateLocal.cpf ? stateLocal.cpf.replaceAll('.', '').replaceAll('-', '') : '',
							rg: stateLocal.rg,
							observacao: stateLocal.observacao,
							empresaId: stateLocal.empresaId,
							crmCanalCliente: registroCanal ? [registroCanal] : [],
							crmEnderecoCliente: [endereco],
							informacaoAdicional1: stateLocal.informacaoAdicional1,
							informacaoAdicional2: stateLocal.informacaoAdicional2,
							informacaoAdicional3: stateLocal.informacaoAdicional3,
							informacaoAdicional4: stateLocal.informacaoAdicional4,
							informacaoAdicional5: stateLocal.informacaoAdicional5,
							informacaoAdicional6: stateLocal.informacaoAdicional6,
							informacaoAdicional7: stateLocal.informacaoAdicional7,
							informacaoAdicional8: stateLocal.informacaoAdicional8,
							informacaoAdicional9: stateLocal.informacaoAdicional9,
							informacaoAdicional10: stateLocal.informacaoAdicional10,
						})
						let dados = {}
						if (store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]) {
							dados = {
								...store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId],
							}
						} else {
							dados.id = stateLocal.id
						}
						dados.crmEnderecoCliente[0] = endereco
						;(dados.nome = stateLocal.nome),
							(dados.sobrenome = stateLocal.sobrenome),
							(dados.nomeMae = stateLocal.nomeMae),
							(dados.cpf = stateLocal.cpf ? stateLocal.cpf.replaceAll('.', '').replaceAll('-', '') : ''),
							(dados.rg = stateLocal.rg),
							(dados.observacao = stateLocal.observacao),
							(dados.informacaoAdicional1 = stateLocal.informacaoAdicional1),
							(dados.informacaoAdicional2 = stateLocal.informacaoAdicional2),
							(dados.informacaoAdicional3 = stateLocal.informacaoAdicional3),
							(dados.informacaoAdicional4 = stateLocal.informacaoAdicional4),
							(dados.informacaoAdicional5 = stateLocal.informacaoAdicional5),
							(dados.informacaoAdicional6 = stateLocal.informacaoAdicional6),
							(dados.informacaoAdicional7 = stateLocal.informacaoAdicional7),
							(dados.informacaoAdicional8 = stateLocal.informacaoAdicional8),
							(dados.informacaoAdicional9 = stateLocal.informacaoAdicional9),
							(dados.informacaoAdicional10 = stateLocal.informacaoAdicional10),
							(dados.empresaId = stateLocal.empresaId),
							(dados.crmCanalCliente = stateLocal.crmCanalCliente)
						dados.codClienteZappag = stateLocal.codClienteZappag
						store.commit('cliente/setClienteSelected', {
							chatId: store.state.selectedChatQueue.chatId,
							dados: dados,
						})
						toast.info(tm('msgCliente01'))
						buscarNovamente.value = false
					}
					if (ath.value) {
						await store.dispatch('getClienteAdress')
					}
				} catch (e) {
					//console.log(e);
					toast.error(tm('erroCliente01'))
				} finally {
					stateLocal.isLoading = false
				}
			}

			function cadastrarCliente() {
				clienteTemCadastro.value = true
				showCriarRegistro.value = false
				buscarNovamente.value = true
				stateLocal.id = 0
				stateLocal.nome = ''
				stateLocal.sobrenome = ''
				stateLocal.rg = ''
				stateLocal.cpf = ''
				stateLocal.nomeMae = ''
				stateLocal.observacao = ''
				stateLocal.codClienteZappag = null
				stateLocal.empresaId = store.state.usuario.empresaId
				stateLocal.crmEnderecoCliente = {}
				stateLocal.crmCanalCliente = []
				paisSelect.value = getCodigoPais('brasil')
				estadoSelect.value = null
				cidadeSelect.value = null
				cidadeList.value = []
			}

			function getPaises() {
				EnderecoService.GetPaises()
					.then(response => {
						paisList.value = response.data
						paisSelect.value = getCodigoPais('brasil')
						getEstados()
					})
					.catch(error => {})
			}

			function getEstados() {
				EnderecoService.GetEstados(paisSelect.value)
					.then(response => {
						estadoList.value = response.data
					})
					.catch(error => {
						//console.log(error);
					})
			}

			function getNomePais(value) {
				if (paisList.value) {
					let pais = paisList.value.find(x => x.codigo === value)
					return pais ? pais.nome.trim() : ''
				} else {
					return 'Brasil'
				}
			}

			function getCodigoPais(value) {
				if (paisList.value) {
					let pais = paisList.value.find(x => x.nome.toLowerCase() === value.toLowerCase())
					return pais ? pais.codigo : 1
				} else {
					return 1
				}
			}

			function getNomeEstado(value) {
				let estado = estadoList.value.find(x => x.codigo === value)
				return estado ? estado.uf.trim() : ''
			}

			function getNomeCidade(value) {
				let cidade = cidadeList.value.find(x => x.codigo === value)
				return cidade ? cidade.nome.trim() : ''
			}

			async function buscarCep() {
				if (stateLocal.crmEnderecoCliente.cep) {
					try {
						stateLocal.isLoadingCEP = true
						const res = await EnderecoService.ConsultaEndereco(stateLocal.crmEnderecoCliente.cep)
						paisSelect.value = res.data.codPais
						estadoSelect.value = res.data.codEstado
						cidadeSelect.value = res.data.codCidade
						stateLocal.crmEnderecoCliente.endereco = res.data.viaCep.logradouro
						stateLocal.crmEnderecoCliente.bairro = res.data.viaCep.bairro
						stateLocal.crmEnderecoCliente.cidade = res.data.viaCep.localidade
						stateLocal.crmEnderecoCliente.estado = res.data.viaCep.uf
					} catch (err) {
						//console.log(err);
						estadoSelect.value = null
						cidadeSelect.value = null
					} finally {
						stateLocal.isLoadingCEP = false
					}
				}
			}
			async function verificaCEP() {
				if (
					stateLocal.crmEnderecoCliente.cep &&
					(!paisSelect.value || !estadoSelect.value || !cidadeSelect.value)
				) {
					try {
						const res = await EnderecoService.ConsultaEndereco(stateLocal.crmEnderecoCliente.cep)
						paisSelect.value = res.data.codPais
						estadoSelect.value = res.data.codEstado
						cidadeSelect.value = res.data.codCidade
						stateLocal.crmEnderecoCliente.cidade = res.data.viaCep.localidade
						stateLocal.crmEnderecoCliente.estado = res.data.viaCep.uf
					} catch (err) {
						//console.log(err);
					}
				}
			}

			function dadosCliente() {
				if (!stateLocal.nome) {
					document.getElementById('input-nome').focus()
					toast.warning(tm('erroCliente03'))
					return false
				}
				if (!stateLocal.sobrenome) {
					document.getElementById('inputSobrenome').focus()
					toast.warning(tm('erroCliente04'))
					return false
				}
				if (autocadastrozappag.value && !stateLocal.cpf) {
					document.getElementById('cpfCliente').focus()
					toast.warning(tm('erroCliente05'))
					return false
				}
				if (autocadastrozappag.value && !stateLocal.crmEnderecoCliente.cep) {
					document.getElementById('input-cep').focus()
					toast.warning(tm('erroCliente06'))
					return false
				}
				if (autocadastrozappag.value && !estadoSelect.value) {
					toast.warning(tm('erroCliente07'))
					return false
				}
				if (autocadastrozappag.value && !cidadeSelect.value) {
					toast.warning(tm('erroCliente08'))
					return false
				}
				return true
			}

			function setaAtocadastrozappag(value) {
				autocadastrozappag.value = value
			}

			function setAth(value) {
				ath.value = value
			}
			function getNumeroEnterprise() {
				NumeroEnterpriseService.GetNumberByEmpresa({
					empresaId: store.state.usuario.empresaId,
					noSmbFilter: true,
				})
					.then(response => {
						if (response.data && response.data.length >= 0) {
							numeroEnterprise.value = response.data[0]
						}
					})
					.catch(error => {})
			}

			//created
			// getPaises()
			getNumeroEnterprise()
			stateLocal.empresaId = store.state.usuario.empresaId

			return {
				store,
				stateLocal,
				selectedChatQueue,
				getDadosCliente,
				clienteTemCadastro,
				buscaCliente,
				apagarCampo,
				showCriarRegistro,
				buscarClientePorNomeCpf,
				habilitarCadastroCliente,
				resultBuscaClientes,
				selecionarCliente,
				buscarCliente,
				salvarCliente,
				canalcadastro,
				incluirCanal,
				excluirCanal,
				cadastrarCliente,
				buscarNovamente,
				paisSelect,
				estadoList,
				estadoSelect,
				cidadeList,
				cidadeSelect,
				buscarCep,
				setaAtocadastrozappag,
				autocadastrozappag,
				setAth,
				confirmarExcluir,
				confirmarIncluir,
				constants,
				tm,
				fileSelected,
				fileAnexo,
				sendAnexo,
				onFileChange,
				clearFile,
			}
		},
		methods: {
			toggleOpen({target}) {
				if (target)
					if (!target.classList.contains('topico')) {
						if (target.parentNode) this.toggleOpen({target: target.parentNode})
					} else {
						var isOpen = target.classList.contains('open')
						target.parentNode.children.forEach(sib => sib.classList.remove('open'))
						if (!isOpen) target.classList.toggle('open')
					}
			},
		},
	}
