export const mutationsTypes = {
	CHATS: 'chats',
	PROFILE: 'profile',
	TRANSLATOR: 'translator',
	NOTES: 'notes',
	TODO: 'todo',
	SETTINGS: 'settings',
	ATENDIMENTO: 'atendimento',
	CLIENTE: 'cliente',
	AGENDA: 'Agenda',
	HISTORICO: 'historico',
	MARKETPLACE: 'marketplace',
}
