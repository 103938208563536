//Dados para validações de arquivos
import constants from './constants'

import { ref } from 'vue';
const DEFAULT_PROPS_FILES = {
	size: 5,
	allowed_image_types: ['JPG', 'JPEG', 'PNG', 'BMP'],
	allowed_audio_types: ['AAC', 'M4A', 'AMR', 'MP3', 'OGG', 'GSM'],
	allowed_video_types: ['MP4'],
	allowed_document_types: ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'ZIP'],
}

var PROPS_FILES = {...DEFAULT_PROPS_FILES}

export default {
	DEFAULT_PROPS_FILES,
	isText(value) {
		if (
			value.indexOf('IMAGE|') == -1 &&
			value.indexOf('VOICE|') == -1 &&
			value.indexOf('VIDEO|') == -1 &&
			value.indexOf('AUDIO|') == -1 &&
			value.indexOf('DOCUMENT|') == -1 &&
			!this.isBase64(value) &&
			value.indexOf('WaitingForPayment|') == -1 &&
			value.indexOf('IntegrationFunctions|') == -1
		) {
			return true
		} else {
			return false
		}
	},

	isImage(value) {
		if (value.indexOf('IMAGE|') != -1 || this.isBase64(value)) {
			return true
		} else {
			return false
		}
	},

	isVideo(value) {
		if (value.indexOf('VIDEO|') != -1) {
			return true
		} else {
			return false
		}
	},

	isVoice(value) {
		if (value.indexOf('VOICE|') != -1) {
			return true
		} else {
			return false
		}
	},

	isAudio(value) {
		if (value.indexOf('AUDIO|') != -1) {
			return true
		} else {
			return false
		}
	},

	isDocument(value) {
		if (value.indexOf('DOCUMENT|') != -1) {
			return true
		} else {
			return false
		}
	},

	isSystem(value) {
		if (value.indexOf('WaitingForPayment|') != -1 || value.indexOf('IntegrationFunctions|') != -1) {
			return true
		} else {
			return false
		}
	},

	returnImageURL(value) {
		let message = ''
		let url = ''
		if (value.indexOf('IMAGE|') != -1) {
			url = value.split('|')[1]
		} else {
			if (value.indexOf('|') != -1) {
				message = value.split('|')[0]
			} else {
				message = value
			}
			if (this.isBase64(message)) {
				url = 'data:image/jpg;base64,' + message
			} else {
				url = value
			}
		}
		return url
	},

	returnImageText(value) {
		return value.split('|')[2]
	},

	isBase64(value) {
		if (value.length < 100 || value.startsWith('DOCUMENT|')) {
			return false
		}
		let message = ''
		if (value.indexOf('|') != -1) {
			message = value.split('|')[0]
		} else {
			message = value
		}
		try {
			return btoa(atob(message)) === message ? true : false
		} catch (e) {
			return false
		}
	},

	returnURL(value) {
		return value.split('|')[1]
	},

	tamanhoArquivo(file, allowed_size = PROPS_FILES.size) {
		let size = file.size && (file.size / Math.pow(1024, 2)).toFixed(2)
		if (size > allowed_size) return false
		return true
	},

	tipoArquivosPermitidos(file_name, tipo) {
		var allowed_types
		switch (tipo) {
			case 'inputImage':
				allowed_types = PROPS_FILES.allowed_image_types
				break
			case 'inputAudio':
				allowed_types = PROPS_FILES.allowed_audio_types
				break
			case 'inputVideo':
				allowed_types = PROPS_FILES.allowed_video_types
				break
			case 'inputDocument':
				allowed_types = PROPS_FILES.allowed_document_types
				break
			default:
				allowed_types = PROPS_FILES.allowed_image_types.concat(
					PROPS_FILES.allowed_audio_types,
					PROPS_FILES.allowed_video_types,
					PROPS_FILES.allowed_document_types,
				)
				break
		}
		let type = file_name.split('.').pop()
		if (type == undefined) return false
		const findType = allowed_types.find(types => types == type.toUpperCase())
		if (findType == undefined) return false
		return true
	},

	validarSemCaractersEspecias(strNome) {
		for (let c of strNome) {
			if (!c.match(/[a-zA-Z]/i) && c != ' ') {
				return false
			}
		}
		return true
	},

	validarCPF(strCPF) {
		var Soma
		var Resto
		Soma = 0
		strCPF = strCPF.replace(/[^\d]+/g, '')
		if (
			strCPF.length != 11 ||
			strCPF == '00000000000' ||
			strCPF == '11111111111' ||
			strCPF == '22222222222' ||
			strCPF == '33333333333' ||
			strCPF == '44444444444' ||
			strCPF == '55555555555' ||
			strCPF == '66666666666' ||
			strCPF == '77777777777' ||
			strCPF == '88888888888' ||
			strCPF == '99999999999'
		) {
			return false
		}

		for (i = 1; i <= 9; i++) {
			Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
		}
		Resto = (Soma * 10) % 11
		if (Resto == 10 || Resto == 11) {
			Resto = 0
		}
		if (Resto != parseInt(strCPF.substring(9, 10))) {
			return false
		}
		Soma = 0
		for (var i = 1; i <= 10; i++) {
			Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
		}
		Resto = (Soma * 10) % 11
		if (Resto == 10 || Resto == 11) {
			Resto = 0
		}
		if (Resto != parseInt(strCPF.substring(10, 11))) {
			return false
		}

		return true
	},

	formatCnpjCpf(value) {
		if (!value) {
			return value
		}
		const cnpjCpf = value.replace(/\D/g, '')

		if (cnpjCpf.length === 11) {
			return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
		}

		return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
	},
	formatCEP(value) {
		const cep = value.replace(/\D/g, '')

		if (cep.length === 8) {
			return cep.replace(/(\d{5})(\d{3})/g, '$1-$2')
		}

		return cep
	},
	formatDadoSensivel(value, ref, qtd) {
		if (ref == 'i') {
			return '*'.repeat(qtd) + value.substring(qtd)
		} else {
			let pos = value.length - qtd
			return value.substring(0, pos) + '*'.repeat(qtd)
		}
	},
	validarCanalVendas(value) {
		var idCanalVendas

		switch (value) {
			case constants.TipoCanal.indexOf('DESCONHECIDO'):
				idCanalVendas = 0
				break
			case constants.TipoCanal.indexOf('WHATSAPP'):
				idCanalVendas = 2
				break
			case constants.TipoCanal.indexOf('WEBCHAT'):
				idCanalVendas = 0
				break
			case constants.TipoCanal.indexOf('FACEBOOK'):
				idCanalVendas = 4
				break
			case constants.TipoCanal.indexOf('TELEGRAM'):
				idCanalVendas = 3
				break
			case constants.TipoCanal.indexOf('WEBSOCKET'):
				idCanalVendas = 0
				break
			case constants.TipoCanal.indexOf('EMAIL'):
				idCanalVendas = 0
				break
			case constants.TipoCanal.indexOf('WEBFORM'):
				idCanalVendas = 0
				break
			case constants.TipoCanal.indexOf('FACILITA_FLOW'):
				idCanalVendas = 0
				break
			case constants.TipoCanal.indexOf('WHATSAPPBUSINESS'):
				idCanalVendas = 2
				break
		}

		return idCanalVendas
	},

	getCurrencyMoeda(locale) {
		switch (locale) {
			case 'es':
				return 'EUR'
			case 'en':
				return 'USD'
			default:
				return 'BRL'
		}
	},

	getFormatoMoeda(locale) {
		switch (locale) {
			case 'es':
				return 'es-ES'
			case 'en':
				return ' en-IN'
			default:
				return 'pt-BR'
		}
	},

	formataDecimal(value, locale) {
		return new Intl.NumberFormat(locale, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(value)
	},

	formataMoeda(value, locale = 'pt-br', currency = 'BRL') {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currency,
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		}).format(value)
	},
}
