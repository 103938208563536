import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"


import { reactive } from 'vue';
const _withScopeId = n => (_pushScopeId("data-v-5e09f646"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "tabbed" }
const _hoisted_3 = ["id", "checked"]
const _hoisted_4 = { class: "tabs m-0" }
const _hoisted_5 = ["for", "title"]
const _hoisted_6 = { class: "tableEmoji" }
const _hoisted_7 = ["onClick"]


export default {
  __name: 'Emoji',
  emits: ['emojiAppend'],
  setup(__props, { emit: __emit }) {

	const emit = __emit

	const categories = reactive([
		{
			title: 'Smileys e Pessoas',
			icon: '😀',
			emojis: [
				'😀',
				'😃',
				'😄',
				'😁',
				'😆',
				'😅',
				'🤣',
				'😂',
				'🙂',
				'🙃',
				'😉',
				'😊',
				'😇',
				'🥰',
				'😍',
				'🤩',
				'😘',
				'😗',
				'😚',
				'😙',
				'😋',
				'😛',
				'😜',
				'🤪',
				'😝',
				'🤑',
				'🤗',
				'🤭',
				'🤫',
				'🤔',
				'🤐',
				'🤨',
				'😐',
				'😑',
				'😶',
				'😏',
				'😒',
				'🙄',
				'😬',
				'🤥',
				'😌',
				'😔',
				'😪',
				'🤤',
				'😴',
				'😷',
				'🤒',
				'🤕',
				'🤢',
				'🤮',
				'🤧',
				'🥵',
				'🥶',
				'🥴',
				'😵',
				'🤯',
				'🤠',
				'🥳',
				'😎',
				'🤓',
				'🧐',
				'😕',
				'😟',
				'🙁',
				'😮',
				'😯',
				'😲',
				'😳',
				'🥺',
				'😦',
				'😧',
				'😨',
				'😰',
				'😥',
				'😢',
				'😭',
				'😱',
				'😖',
				'😣',
				'😞',
				'😓',
				'😩',
				'😫',
				'🥱',
				'😤',
				'😡',
				'😠',
				'🤬',
				'😈',
				'👿',
				'💀',
				'💩',
				'🤡',
				'👹',
				'👺',
				'👻',
				'👽',
				'👾',
				'🤖',
				'😺',
				'😸',
				'😹',
				'😻',
				'😼',
				'😽',
				'🙀',
				'😿',
				'😾',
				'💋',
				'👋',
				'🤚',
				'🖐',
				'✋',
				'🖖',
				'👌',
				'🤏',
				'✌',
				'🤞',
				'🤟',
				'🤘',
				'🤙',
				'👈',
				'👉',
				'👆',
				'👇',
				'☝',
				'👍',
				'👎',
				'✊',
				'👊',
				'🤛',
				'🤜',
				'👏',
				'🙌',
				'👐',
				'🤲',
				'🤝',
				'🙏',
				'✍',
				'💅',
				'🤳',
				'💪',
				'🦾',
				'🦿',
				'🦵',
				'🦶',
				'👂',
				'🦻',
				'👃',
				'🧠',
				'🦷',
				'🦴',
				'👀',
				'👅',
				'👄',
				'👶',
				'🧒',
				'👦',
				'👧',
				'🧑',
				'👱',
				'👨',
				'🧔',
				'👨‍🦰',
				'👨‍🦱',
				'👨‍🦳',
				'👨‍🦲',
				'👩',
				'👩‍🦰',
				'👩‍🦱',
				'👩‍🦳',
				'👩‍🦲',
				'👱‍♀️',
				'👱‍♂️',
				'🧓',
				'👴',
				'👵',
				'🙍',
				'🙎',
				'🙅',
				'🙆',
				'💁',
				'🙋',
				'🧏',
				'🙇',
				'🤦',
				'🤷',
				'👨‍⚕️',
				'👩‍⚕️',
				'👨‍🎓',
				'👩‍🎓',
				'👨‍🏫',
				'👩‍🏫',
				'👨‍⚖️',
				'👩‍⚖️',
				'👨‍🌾',
				'👩‍🌾',
				'👨‍🍳',
				'👩‍🍳',
				'👨‍🔧',
				'👩‍🔧',
				'👨‍🏭',
				'👩‍🏭',
				'👨‍💼',
				'👩‍💼',
				'👨‍🔬',
				'👩‍🔬',
				'👨‍💻',
				'👩‍💻',
				'👨‍🎤',
				'👩‍🎤',
				'👨‍🎨',
				'👩‍🎨',
				'👨‍✈️',
				'👩‍✈️',
				'👨‍🚀',
				'👩‍🚀',
				'👨‍🚒',
				'👩‍🚒',
				'👮',
				'🕵',
				'💂',
				'👷',
				'🤴',
				'👸',
				'👳',
				'👲',
				'🧕',
				'🤵',
				'👰',
				'🤰',
				'🤱',
				'👼',
				'🎅',
				'🤶',
				'🦸',
				'🦹',
				'🧙',
				'🧚',
				'🧜',
				'🧝',
				'🧞',
				'🧟',
				'💆',
				'💇',
				'🚶',
				'🧍',
				'🧎',
				'👨‍🦯',
				'👩‍🦯',
				'👨‍🦼',
				'👩‍🦼',
				'👨‍🦽',
				'👩‍🦽',
				'🏃',
				'💃',
				'🕺',
				'🕴',
				'👯',
				'🧖',
				'🧘',
				'🧑‍🤝‍🧑',
				'👭',
				'👫',
				'👬',
				'💏',
				'👨‍❤️‍💋‍👨',
				'👩‍❤️‍💋‍👩',
				'💑',
				'👨‍❤️‍👨',
				'👩‍❤️‍👩',
				'👪',
				'👨‍👩‍👦',
				'👨‍👩‍👧',
				'👨‍👩‍👧‍👦',
				'👨‍👩‍👦‍👦',
				'👨‍👩‍👧‍👧',
				'👨‍👨‍👦',
				'👨‍👨‍👧',
				'👨‍👨‍👧‍👦',
				'👨‍👨‍👦‍👦',
				'👨‍👨‍👧‍👧',
				'👩‍👩‍👦',
				'👩‍👩‍👧',
				'👩‍👩‍👧‍👦',
				'👩‍👩‍👦‍👦',
				'👩‍👩‍👧‍👧',
				'👨‍👦',
				'👨‍👦‍👦',
				'👨‍👧',
				'👨‍👧‍👦',
				'👨‍👧‍👧',
				'👩‍👦',
				'👩‍👦‍👦',
				'👩‍👧',
				'👩‍👧‍👦',
				'👩‍👧‍👧',
				'👤',
				'👥',
				'👣',
				'🧳',
				'🌂',
				'🧵',
				'🧶',
				'👓',
				'🥽',
				'🥼',
				'🦺',
				'👔',
				'👕',
				'👖',
				'🧣',
				'🧤',
				'🧥',
				'🧦',
				'👗',
				'👘',
				'🥻',
				'🩱',
				'🩲',
				'🩳',
				'👙',
				'👚',
				'👛',
				'👜',
				'👝',
				'🎒',
				'👞',
				'👟',
				'🥾',
				'🥿',
				'👠',
				'👡',
				'🩰',
				'👢',
				'👑',
				'👒',
				'🎩',
				'🎓',
				'🧢',
				'💄',
				'💍',
				'💼',
			],
		},
		{
			title: 'Animais e Natureza',
			icon: '🐻',
			emojis: [
				'🐻',
				'🙈',
				'🙉',
				'🙊',
				'💥',
				'💫',
				'💦',
				'💨',
				'🐵',
				'🐒',
				'🦍',
				'🦧',
				'🐶',
				'🐕',
				'🦮',
				'🐕‍🦺',
				'🐩',
				'🐺',
				'🦊',
				'🦝',
				'🐱',
				'🐈',
				'🦁',
				'🐯',
				'🐅',
				'🐆',
				'🐴',
				'🐎',
				'🦄',
				'🦓',
				'🦌',
				'🐮',
				'🐂',
				'🐃',
				'🐄',
				'🐷',
				'🐖',
				'🐗',
				'🐽',
				'🐏',
				'🐑',
				'🐐',
				'🐪',
				'🐫',
				'🦙',
				'🦒',
				'🐘',
				'🦏',
				'🦛',
				'🐭',
				'🐁',
				'🐀',
				'🐹',
				'🐰',
				'🐇',
				'🦔',
				'🦇',
				'🐻',
				'🐨',
				'🐼',
				'🦥',
				'🦦',
				'🦨',
				'🦘',
				'🦡',
				'🐾',
				'🦃',
				'🐔',
				'🐓',
				'🐣',
				'🐤',
				'🐥',
				'🐦',
				'🐧',
				'🦅',
				'🦆',
				'🦢',
				'🦉',
				'🦩',
				'🦚',
				'🦜',
				'🐸',
				'🐊',
				'🐢',
				'🦎',
				'🐍',
				'🐲',
				'🐉',
				'🦕',
				'🦖',
				'🐳',
				'🐋',
				'🐬',
				'🐟',
				'🐠',
				'🐡',
				'🦈',
				'🐙',
				'🐚',
				'🐌',
				'🦋',
				'🐛',
				'🐜',
				'🐝',
				'🐞',
				'🦗',
				'🦂',
				'🦟',
				'🦠',
				'💐',
				'🌸',
				'💮',
				'🌹',
				'🥀',
				'🌺',
				'🌻',
				'🌼',
				'🌷',
				'🌱',
				'🌲',
				'🌳',
				'🌴',
				'🌵',
				'🌾',
				'🌿',
				'☘',
				'🍀',
				'🍁',
				'🍂',
				'🍃',
				'🍄',
				'🌰',
				'🦀',
				'🦞',
				'🦐',
				'🦑',
				'🌍',
				'🌎',
				'🌏',
				'🌐',
				'🌑',
				'🌒',
				'🌓',
				'🌔',
				'🌕',
				'🌖',
				'🌗',
				'🌘',
				'🌙',
				'🌚',
				'🌛',
				'🌜',
				'🌝',
				'🌞',
				'⭐',
				'🌟',
				'🌠',
				'⛅',
				'🌈',
				'☔',
				'⚡',
				'⛄',
				'🔥',
				'💧',
				'🌊',
				'🎄',
				'✨',
				'🎋',
				'🎍',
			],
		},
		{
			title: 'Comidas e Bebidas',
			icon: '🍔',
			emojis: [
				'🍔',
				'🍇',
				'🍈',
				'🍉',
				'🍊',
				'🍋',
				'🍌',
				'🍍',
				'🥭',
				'🍎',
				'🍏',
				'🍐',
				'🍑',
				'🍒',
				'🍓',
				'🥝',
				'🍅',
				'🥥',
				'🥑',
				'🍆',
				'🥔',
				'🥕',
				'🌽',
				'🥒',
				'🥬',
				'🥦',
				'🧄',
				'🧅',
				'🍄',
				'🥜',
				'🌰',
				'🍞',
				'🥐',
				'🥖',
				'🥨',
				'🥯',
				'🥞',
				'🧇',
				'🧀',
				'🍖',
				'🍗',
				'🥩',
				'🥓',
				'🍔',
				'🍟',
				'🍕',
				'🌭',
				'🥪',
				'🌮',
				'🌯',
				'🥙',
				'🧆',
				'🍳',
				'🥘',
				'🍲',
				'🥣',
				'🥗',
				'🍿',
				'🧈',
				'🧂',
				'🥫',
				'🍱',
				'🍘',
				'🍙',
				'🍚',
				'🍛',
				'🍜',
				'🍝',
				'🍠',
				'🍢',
				'🍣',
				'🍤',
				'🍥',
				'🥮',
				'🍡',
				'🥟',
				'🥠',
				'🥡',
				'🦪',
				'🍦',
				'🍧',
				'🍨',
				'🍩',
				'🍪',
				'🎂',
				'🍰',
				'🧁',
				'🥧',
				'🍫',
				'🍬',
				'🍭',
				'🍮',
				'🍯',
				'🍼',
				'🥛',
				'☕',
				'🍵',
				'🍶',
				'🍾',
				'🍷',
				'🍸',
				'🍹',
				'🍺',
				'🍻',
				'🥂',
				'🥃',
				'🥤',
				'🧃',
				'🧉',
				'🧊',
				'🥢',
				'🍴',
				'🥄',
			],
		},
		{
			title: 'Atividades',
			icon: '⚽',
			emojis: [
				'🕴',
				'🧗',
				'🏇',
				'🏂',
				'🏌',
				'🏄',
				'🚣',
				'🏊',
				'⛹',
				'🏋',
				'🚴',
				'🚵',
				'🤸',
				'🤼',
				'🤽',
				'🤾',
				'🤹',
				'🧘',
				'🎪',
				'🛹',
				'🎫',
				'🏆',
				'🏅',
				'🥇',
				'🥈',
				'🥉',
				'⚽',
				'⚾',
				'🥎',
				'🏀',
				'🏐',
				'🏈',
				'🏉',
				'🎾',
				'🥏',
				'🎳',
				'🏏',
				'🏑',
				'🏒',
				'🥍',
				'🏓',
				'🏸',
				'🥊',
				'🥋',
				'⛳',
				'🎣',
				'🎽',
				'🎿',
				'🛷',
				'🥌',
				'🎯',
				'🎱',
				'🎮',
				'🎰',
				'🎲',
				'🧩',
				'🎭',
				'🎨',
				'🧵',
				'🧶',
				'🎼',
				'🎤',
				'🎧',
				'🎷',
				'🎸',
				'🎹',
				'🎺',
				'🎻',
				'🥁',
				'🎬',
				'🏹',
			],
		},
		{
			title: 'Viagens e Locais',
			icon: '🌇',
			emojis: [
				'🚣',
				'🗾',
				'🌋',
				'🗻',
				'🏠',
				'🏡',
				'🏢',
				'🏣',
				'🏤',
				'🏥',
				'🏦',
				'🏨',
				'🏩',
				'🏪',
				'🏫',
				'🏬',
				'🏭',
				'🏯',
				'🏰',
				'💒',
				'🗼',
				'🗽',
				'⛪',
				'🕌',
				'🛕',
				'🕍',
				'🕋',
				'⛲',
				'⛺',
				'🌁',
				'🌃',
				'🌄',
				'🌅',
				'🌆',
				'🌇',
				'🌉',
				'🎠',
				'🎡',
				'🎢',
				'🚂',
				'🚃',
				'🚄',
				'🚅',
				'🚆',
				'🚇',
				'🚈',
				'🚉',
				'🚊',
				'🚝',
				'🚞',
				'🚋',
				'🚌',
				'🚍',
				'🚎',
				'🚐',
				'🚑',
				'🚒',
				'🚓',
				'🚔',
				'🚕',
				'🚖',
				'🚗',
				'🚘',
				'🚚',
				'🚛',
				'🚜',
				'🛵',
				'🛺',
				'🚲',
				'🛴',
				'🚏',
				'⛽',
				'🚨',
				'🚥',
				'🚦',
				'🚧',
				'⚓',
				'⛵',
				'🚤',
				'🚢',
				'🛫',
				'🛬',
				'🪂',
				'💺',
				'🚁',
				'🚟',
				'🚠',
				'🚡',
				'🚀',
				'🛸',
				'🪐',
				'🌠',
				'🌌',
				'🎆',
				'🎇',
				'🎑',
				'💴',
				'💵',
				'💶',
				'💷',
				'🗿',
				'🛂',
				'🛃',
				'🛄',
				'🛅',
			],
		},
		{
			title: 'Objetos',
			icon: '💡',
			emojis: [
				'💌',
				'💣',
				'🛀',
				'🛌',
				'🔪',
				'🏺',
				'🧭',
				'🧱',
				'💈',
				'🦽',
				'🦼',
				'🧳',
				'⌛',
				'⏳',
				'⌚',
				'⏰',
				'⏱',
				'⏲',
				'🧨',
				'🎈',
				'🎉',
				'🎊',
				'🎎',
				'🎏',
				'🎐',
				'🧧',
				'🎀',
				'🎁',
				'🤿',
				'🪀',
				'🪁',
				'🔮',
				'🧿',
				'🧸',
				'🧵',
				'🧶',
				'📿',
				'💎',
				'📯',
				'📻',
				'🪕',
				'📱',
				'📲',
				'📞',
				'📟',
				'📠',
				'🔋',
				'🔌',
				'💻',
				'💽',
				'💾',
				'💿',
				'📀',
				'🧮',
				'🎥',
				'📺',
				'📷',
				'📸',
				'📹',
				'📼',
				'🔍',
				'🔎',
				'💡',
				'🔦',
				'🏮',
				'🪔',
				'📔',
				'📕',
				'📖',
				'📗',
				'📘',
				'📙',
				'📚',
				'📓',
				'📃',
				'📜',
				'📄',
				'📰',
				'📑',
				'🔖',
				'💰',
				'💴',
				'💵',
				'💶',
				'💷',
				'💸',
				'💳',
				'🧾',
				'📧',
				'📨',
				'📩',
				'📤',
				'📥',
				'📦',
				'📫',
				'📪',
				'📬',
				'📭',
				'📮',
				'📝',
				'📁',
				'📂',
				'📅',
				'📆',
				'📇',
				'📈',
				'📉',
				'📊',
				'📋',
				'📌',
				'📍',
				'📎',
				'📏',
				'📐',
				'🔒',
				'🔓',
				'🔏',
				'🔐',
				'🔑',
				'🔨',
				'🪓',
				'🔫',
				'🔧',
				'🔩',
				'🦯',
				'🔗',
				'🧰',
				'🧲',
				'🧪',
				'🧫',
				'🧬',
				'🔬',
				'🔭',
				'📡',
				'💉',
				'🩸',
				'💊',
				'🩹',
				'🩺',
				'🚪',
				'🪑',
				'🚽',
				'🚿',
				'🛁',
				'🪒',
				'🧴',
				'🧷',
				'🧹',
				'🧺',
				'🧻',
				'🧼',
				'🧽',
				'🧯',
				'🚬',
				'🗿',
				'🚰',
				'🏁',
				'🚩',
				'🎌',
				'🏴',
			],
		},
		{
			title: 'Simbolos',
			icon: '🔣',
			emojis: [
				'💘',
				'💝',
				'💖',
				'💗',
				'💓',
				'💞',
				'💕',
				'💟',
				'💔',
				'❤️',
				'🧡',
				'💛',
				'💚',
				'💙',
				'💜',
				'🤎',
				'🖤',
				'🤍',
				'💯',
				'💢',
				'💬',
				'👁️‍🗨️',
				'💭',
				'💤',
				'💮',
				'💈',
				'🛑',
				'🕛',
				'🕧',
				'🕐',
				'🕜',
				'🕑',
				'🕝',
				'🕒',
				'🕞',
				'🕓',
				'🕟',
				'🕔',
				'🕠',
				'🕕',
				'🕡',
				'🕖',
				'🕢',
				'🕗',
				'🕣',
				'🕘',
				'🕤',
				'🕙',
				'🕥',
				'🕚',
				'🕦',
				'🌀',
				'🃏',
				'🀄',
				'🎴',
				'🔇',
				'🔈',
				'🔉',
				'🔊',
				'📢',
				'📣',
				'📯',
				'🔔',
				'🔕',
				'🎵',
				'🎶',
				'🏧',
				'🚮',
				'🚰',
				'♿',
				'🚹',
				'🚺',
				'🚻',
				'🚼',
				'🚾',
				'🚸',
				'⛔',
				'🚫',
				'🚳',
				'🚭',
				'🚯',
				'🚱',
				'🚷',
				'🔞',
				'🔃',
				'🔄',
				'🔙',
				'🔚',
				'🔛',
				'🔜',
				'🔝',
				'🛐',
				'🕎',
				'🔯',
				'♈',
				'♉',
				'♊',
				'♋',
				'♌',
				'♍',
				'♎',
				'♏',
				'♐',
				'♑',
				'♒',
				'♓',
				'⛎',
				'🔀',
				'🔁',
				'🔂',
				'⏩',
				'⏪',
				'🔼',
				'⏫',
				'🔽',
				'⏬',
				'⏹',
				'🎦',
				'🔅',
				'🔆',
				'📶',
				'📳',
				'📴',
				'➕',
				'➖',
				'➗',
				'❓',
				'❔',
				'❕',
				'❗',
				'🔱',
				'📛',
				'🔰',
				'⭕',
				'✅',
				'❌',
				'❎',
				'➰',
				'➿',
				'#️⃣',
				'0️⃣',
				'1️⃣',
				'2️⃣',
				'3️⃣',
				'4️⃣',
				'5️⃣',
				'6️⃣',
				'7️⃣',
				'8️⃣',
				'9️⃣',
				'🔟',
				'🔠',
				'🔡',
				'🔢',
				'🔣',
				'🔤',
				'🅰',
				'🆎',
				'🅱',
				'🆑',
				'🆒',
				'🆓',
				'🆔',
				'🆕',
				'🆖',
				'🅾',
				'🆗',
				'🅿',
				'🆘',
				'🆙',
				'🆚',
				'🈁',
				'🈶',
				'🈯',
				'🉐',
				'🈹',
				'🈚',
				'🈲',
				'🉑',
				'🈸',
				'🈴',
				'🈳',
				'🈺',
				'🈵',
				'🔴',
				'🟠',
				'🟡',
				'🟢',
				'🔵',
				'🟣',
				'🟤',
				'⚫',
				'⚪',
				'🟥',
				'🟧',
				'🟨',
				'🟩',
				'🟦',
				'🟪',
				'🟫',
				'⬛',
				'⬜',
				'🔶',
				'🔷',
				'🔸',
				'🔹',
				'🔺',
				'🔻',
				'💠',
				'🔳',
				'🔲',
			],
		},
	])

	function appendEmoji(emoji) {
		emit('emojiAppend', emoji)
	}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category, index) => {
        return (_openBlock(), _createElementBlock("input", {
          id: 'tab' + (index + 1),
          key: index,
          type: "radio",
          name: "css-tabs",
          checked: index === 0
        }, null, 8, _hoisted_3))
      }), 128)),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "tab"
          }, [
            _createElementVNode("label", {
              for: 'tab' + (index + 1),
              title: category.title
            }, _toDisplayString(category.icon), 9, _hoisted_5)
          ]))
        }), 128))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "tab-content m-1 scrolling"
        }, [
          _createElementVNode("table", _hoisted_6, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.emojis, (emoji) => {
                  return (_openBlock(), _createElementBlock("td", { key: emoji }, [
                    _createElementVNode("a", {
                      onClick: $event => (appendEmoji(emoji))
                    }, _toDisplayString(emoji), 9, _hoisted_7)
                  ]))
                }), 128))
              ])
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

}