import {environment} from '../environments/env'
import Api from './Api'
import {route} from './routeDictionary'

export default {
	chatQueueActive(agentId) {
		return Api.get(`${environment.apiUrl}${route.OnlineChatQueueActive}/${agentId}`)
	},
	chatQueueActiveAgent(agentId) {
		return Api.get(`${environment.apiUrl}${route.OnlineChatQueueActiveAgent}/${agentId}`)
	},
	getHistoryByChatUUI(chatUUID, chatId) {
		return Api.get(`${environment.apiUrl}${route.GetHistoryByChatUUID}/${chatUUID}`, {params: {chatId: chatId}})
	},
	getHistoryByStartDateByEndDate(dataInicial, dataFinal, chatId, empresaId) {
		return Api.get(
			`${environment.apiUrl}${route.GetHistoryByStartDateByEndDate.replace('{dataInicial}', dataInicial)
				.replace('{dataFinal}', dataFinal)
				.replace('{chatID}', chatId)
				.replace('{empresaId}', empresaId)}`,
		)
	},
	getCurrentMonthItemsForChatId(empresaId, chatId) {
		return Api.get(
			`${environment.apiUrl}${route.GetCurrentMonthItemsForChatId.replace('{empresaId}', empresaId).replace(
				'{chatId}',
				chatId,
			)}`,
		)
	},
	getCurrentMonthItems(empresaId) {
		return Api.get(`${environment.apiUrl}${route.GetCurrentMonthItems.replace('{empresaId}', empresaId)}`)
	},
	getLast48hHistory(chatId, flowUUID) {
		return Api.get(`${environment.apiUrl}${route.GetLast48hHistory}`, {
			params: {chatId: chatId, flowUUID: flowUUID},
		})
	},
	async AddToChatHistory(arr) {
		return await Api.post(`${environment.apiUrl}${route.AddToChatHistory}`, arr)
	},
	SendTelegramMessage(arr) {
		return Api.post(`${environment.apiUrl}${route.SendTelegramMessage}`, null, {params: arr})
	},
	GetChatsByCampaign(campaignName) {
		return Api.get(`${environment.apiUrl}${route.GetChatsByCampaign}/${campaignName}`)
	},
	GetChatsByCampaignList(arrCampaigns) {
		return Api.post(`${environment.apiUrl}${route.GetChatsByCampaignList}`, arrCampaigns)
	},
	TransferChat(param) {
		return Api.post(`${environment.apiUrl}${route.TransferChat}`, null, {params: param})
	},
	GetNextChatByCampaign(agentId, campaignName) {
		return Api.get(`${environment.apiUrl}${route.GetNextChatByCampaign}`, {
			params: {AgentId: agentId, QueueId: campaignName},
		})
	},
	FinishItemWithTab(chatuuid, tab) {
		return Api.put(`${environment.apiUrl}${route.FinishItemWithTab}/${chatuuid}`, tab)
	},
	UpdateCustomVars(chatUuid, customVars) {
		return Api.put(`${environment.apiUrl}${route.UpdateCustomVars}`, customVars, {params: {chatUuid: chatUuid}})
	},
	UpdateCustomVarsByUuid(uuid, customVars) {
		return Api.put(`${environment.apiUrl}${route.UpdateCustomVarsByUuid}`, customVars, {params: {uuid: uuid}})
	},
	UpdateForceClose(uuid) {
		return Api.put(`${environment.apiUrl}${route.UpdateForceClose}`, null, {params: {uuid: uuid, forceClose: true}})
	},
	AddFileChat(arr) {
		return Api.post(`${environment.apiUrl}${route.AddFileChat}`, arr)
	},
	GetChatsNext(arr, agentId) {
		return Api.post(`${environment.apiUrl}${route.GetChatsNext}`, arr, {params: {agentId: agentId}})
	},
	FinalizarBot(uuid, chatId, idLoja, mediaId, idRetailer, idExternoVtex) {
		console.info({
			UUID: uuid,
			chatId: chatId,
			idLoja: idLoja,
			mediaid: mediaId,
			idRetailer: idRetailer,
			idExternoVtex: idExternoVtex,
		})

		return Api.post(`${environment.apiUrl}${route.FinishAthBackoffice}`, null, {
			params: {
				UUID: uuid,
				numeroCliente: chatId,
				idLoja: idLoja,
				mediaid: mediaId,
				idRetailer: idRetailer,
				idExternoVtex: idExternoVtex,
			},
		})
	},
	getHistoryByChatIdCompany(chatId, companyId, items) {
		return Api.get(`${environment.apiUrl}${route.GetHistoryByChatIdCompany}`, {
			params: {chatId: chatId, companyId: companyId, items: items},
		})
	},
	GerarAtendimento(arr, param) {
		return Api.post(`${environment.apiUrl}${route.GerarAtendimento}`, arr, {params: param})
	},
	TransferChatMotivo(arr, param) {
		return Api.post(`${environment.apiUrl}${route.TransferChatMotivo}`, arr, {params: param})
	},
	GetAllChatsFromDataChatIds(dataInicial, dataFinal, chatIds, empresaId) {
		return Api.post(`${environment.apiUrl}${route.GetAllChatsFromDataChatIds}`, chatIds, {
			params: {dataInicial: dataInicial, dataFinal: dataFinal, empresaId: empresaId},
		})
	},
	UpdateChatStatus(chatUUID, finished, timeline) {
		return Api.post(`${environment.apiUrl}${route.UpdateChatStatus}`, timeline, {
			params: {chatUUID: chatUUID, finished: finished},
		})
	},
	StatusDates2(dataInicial, dataFinal, empresaId) {
		return Api.post(`${environment.apiUrl}${route.StatusDate2}`, null, {
			params: {dataInicial: dataInicial, dataFinal: dataFinal, empresaId: empresaId},
		})
	},
	InsertIndicadoresLogin(usuarioId, empresaId, campanhasId) {
		return Api.post(`${environment.apiUrl}${route.InsertIndicadoresLogin}`, null, {
			params: {campanhas_id: campanhasId, empresa_id: empresaId, usuario_id: usuarioId},
		})
	},
	IndicadoresFimAtendimento(usuario_id, empresa_id, campanha_id, tempoAtendimento) {
		return Api.post(`${environment.apiUrl}${route.IndicadoresFimAtendimento}`, null, {
			params: {
				usuario_id: usuario_id,
				empresa_id: empresa_id,
				campanha_id: campanha_id,
				tempoAtendimento: tempoAtendimento,
			},
		})
	},
	IndicadoresLogout(usuario_id, empresa_id) {
		return Api.post(`${environment.apiUrl}${route.IndicadoresLogout}`, null, {
			params: {usuario_id: usuario_id, empresa_id: empresa_id},
		})
	},
	nextIndicadores(arr, agentId, empresaId) {
		return Api.post(`${environment.apiUrl}${route.nextIndicadores}`, arr, {
			params: {agentId: agentId, empresaId: empresaId},
		})
	},
	IndicadoresFimAtendimentoCliente(uuid) {
		return Api.put(`${environment.apiUrl}${route.IndicadoresFimAtendimentoCliente.replace('{UUID}', uuid)}`)
	},
	async UpdateLastAgentMessage(chatUUID) {
		console.info('UpdateLastAgentMessage: ', chatUUID)
		try {
			await Api.put(`${environment.apiUrl}${route.UpdateLastAgentMessage.replace('{ChatUUID}', chatUUID)}`)
		} catch (error) {
			console.error('UpdateLastAgentMessage: ', error)
		}
	},
}
