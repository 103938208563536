
	import {useI18n} from 'vue-i18n'
	export default {
		props: {
			title: {
				type: String,
				default: '',
			},
			largeModal: {
				type: Boolean,
				default: false,
			},
		},

		setup(props) {
			const {tm} = useI18n()
			return {
				props,
				tm,
			}
		},
	}
