import {environment} from '../environments/env'
import Api from './Api'
import {route} from './routeDictionary'

export default {
	GetNumberByCampanhaOrEmpresa(params) {
		return Api.get(`${environment.apiUrl}${route.GetNumberByCampanhaOrEmpresa}`, {params: params})
	},
	GetNumberByEmpresa(parms) {
		return Api.get(`${environment.apiUrl}${route.GetNumberByEmpresa}`, {params: parms})
	},
	GetNumberByMediaId(mediaId) {
		return Api.get(`${environment.apiUrl}${route.GetNumberByMediaId}`, {params: {mediaId: mediaId}})
	},
}
