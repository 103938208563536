
	import moment from 'moment'
	import Validar from '@/util/Validadores'
	import {reactive, ref} from 'vue'
	import {useI18n} from 'vue-i18n'
	export default {
		name: 'ChatHistoryMessages',
		props: ['messages'],
		setup(props) {
			const {tm} = useI18n()
			const timezoneDefault = ref('')
			let itemsFound = ref(-1)
			let itemFind = ref(0)
			let lastTermo = ref('')
			let lastIndex = ref('')

			timezoneDefault.value = process.env.VUE_APP_TIMEZONE ? process.env.VUE_APP_TIMEZONE : 'America/Sao_Paulo'

			function searchInMessage() {
				let index = 0
				let termo = document.getElementById('searchForm').elements['searchItem'].value.toLowerCase().trim()

				let divs = document.getElementsByClassName('chm')

				if (termo == lastTermo.value && termo.trim() != '') {
					index = lastIndex
				} else {
					//desmarca qualquer texto que tenha sido marcado
					for (let i = 0; i < divs.length; i++) {
						let para = divs[i].getElementsByTagName('span')
						let innerHTML = para[0].innerHTML
							.replace('<span style="background-color: yellow">', '')
							.replace('</span>', '')
						para[0].innerHTML = innerHTML
					}
					itemFind.value = 0
					itemsFound.value = -1
					lastTermo.value = termo.trim()
					if (termo.trim() == '') {
						return
					}
				}

				lastTermo.value = termo
				let arrFinds = props.messages.filter(x => x.message.toLowerCase().indexOf(termo) > -1)
				itemsFound.value = arrFinds.length

				for (let i = index; i.value < itemsFound.value; i.value++) {
					let element = document.getElementById(arrFinds[i].internalId).getElementsByTagName('span')[0]
					let innerHTML = element.innerHTML
					let textIndex = element.innerText.toLowerCase().indexOf(termo)

					innerHTML =
						innerHTML.substring(0, textIndex) +
						"<span style='background-color: yellow'>" +
						innerHTML.substring(textIndex, textIndex + termo.length) +
						'</span>' +
						innerHTML.substring(textIndex + termo.length)
					element.innerHTML = innerHTML
					element.scrollIntoView()
					itemFind.value = i.value + 1
					lastIndex.value = i.value + 1
					break
				}
			}

			return {
				moment,
				Validar,
				timezoneDefault,
				searchInMessage,
				lastTermo,
				lastIndex,
				itemsFound,
				itemFind,
				tm,
			}
		},
	}
