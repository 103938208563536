
	import {useI18n} from 'vue-i18n'
	import {computed, ref} from 'vue'
	import Validar from '../util/Validadores'
	import ChatService from '../services/ChatService'
	import {number} from '@intlify/core-base'
	import store from '../store'
	import {useStore} from 'vuex'
	export default {
		props: {
			ticket: {
				type: Object,
				default() {
					return {}
				},
			},
		},

		setup(props) {
			const {tm, locale} = useI18n()
			const store = useStore()
			const tipo = computed(() => {
				if (props.ticket == null) {
					return ''
				} else {
					if (props.ticket.customVariables !== undefined) {
						return props.ticket.customVariables['@tipoMsg'] ? props.ticket.customVariables['@tipoMsg'] : ''
					} else {
						return ''
					}
				}
			})

			const obsMudanca = ref('')
			const protocoloPedido = ref('')

			const pedido = computed(() => {
				return props.tickets.customVariables['orderid'] ? props.ticket.customVariables['orderid'] : ''
			})
			const ticketDataList = computed(() => {
				return props.ticket.ticket && props.ticket.ticket.ticketDataList
					? props.ticket.ticket.ticketDataList
					: []
			})

			const isDisabled = ref(true)
			const statusReclamacao = ref('')

			function viewBtnAtt(e) {
				if (e === '-1') {
					isDisabled.value = true
				} else {
					isDisabled.value = false
				}
				statusReclamacao.value = e
			}

			function formataMoeda(value) {
				return Validar.formataMoeda(
					value,
					Validar.getFormatoMoeda(locale.value),
					Validar.getCurrencyMoeda(locale.value),
				)
			}
			const listaStatus = computed(() => {
				const listaRetorno = ['Pendente'].sort()
				if (listaRetorno.length == 1 && listaRetorno[0] == null) return []
				return listaRetorno
			})

			function UpdateStage() {
				props.ticket.customVariables['@motivo'] = statusReclamacao.value
				props.ticket.customVariables['@obsMUdanca'] = obsMudanca
				props.ticket.customVariables['@protocoloPedido'] = protocoloPedido
				const response = ChatService.UpdateCustomVars(props.ticket.chatUUID, props.ticket.customVariables)
			}

			return {
				tm,
				tipo,
				pedido,
				ticketDataList,
				formataMoeda,
				UpdateStage,
				listaStatus,
				isDisabled,
				viewBtnAtt,
				obsMudanca,
				protocoloPedido,
			}
		},
	}
