import ApiBotService from '../../services/ApiBotService'
import constants from '../../util/constants'
import ChatService from '../../services/ChatService'

const state = {
	apiBotHub: [],
	enderecosClientes: [],
	secoesLoja: [],
	selectedLoja: [],
	lojasCliente: [],
	carrinhoCliente: [],
	pedidos: [],
	pedidoFinalizado: [],
	selectedLojaDados: [],
	idExternoVtex: [],
	currentOpcao: 'secoes',
	listaPedidos: [],
	verificaEstoque: false,
	existeSecoesLoja: [],
	vtex: false,
	isLoadingConection: false,
	loading: {
		token: false,
	},
}
const mutations = {
	setApiBotHub(state, botHubs) {
		state.apiBotHub = botHubs
	},
	setEnderecosCliente(state, enderecos) {
		state.enderecosClientes = enderecos
	},
	setSecoesLoja(state, secoes) {
		state.secoesLoja = secoes
	},
	setLojaList(state, loja) {
		state.selectedLoja = loja
	},
	setLojaSelected(state, {loja, chatId}) {
		state.selectedLoja[chatId] = loja
	},
	setLojaDadosList(state, list) {
		state.selectedLojaDados = list
	},
	setLojaSelectedDados(state, {dados, chatId}) {
		state.selectedLojaDados[chatId] = dados
	},
	setLojasCliente(state, lojas) {
		state.lojasCliente = lojas
	},
	setCarrinhoList(state, list) {
		state.carrinhoCliente = list
	},
	setPedidos(state, pedidos) {
		state.pedidos = pedidos
	},
	setListaPedidos(state, listaPedidos) {
		state.listaPedidos = listaPedidos
	},
	setPedidoFinalizado(state, pedido) {
		state.pedidoFinalizado = pedido
	},
	setOpcao(state, opcao) {
		state.currentOpcao = opcao
	},
	setVerificaEstoque(state, value) {
		state.verificaEstoque = value
	},
	setVetex(state, value) {
		state.vtex = value
	},
	setLoadingConection(state, value) {
		state.isLoadingConection = value
	},
	setIdExternoVtex(state, value) {
		state.idExternoVtex = value
	},
}

const actions = {
	async getCustomerAddresses(context) {
		const {chatId} = context.rootState.selectedChatQueue
		const token = context.state.apiBotHub[chatId]?.token

		if (token) {
			try {
				const response = await ApiBotService.GetClientAdressesBot(token)
				context.state.enderecosClientes[chatId] = response.data.addresses
			} catch (error) {
				console.error('getCustomerAddresses | error => ', error)
			}
		}
	},

	async getSessoesLoja(context) {
		try {
			let chatId = context.rootState.selectedChatQueue.chatId
			let idLoja = context.state.selectedLoja[chatId]
			let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
			let idChannel = 2
			let categoriasExternas = context.state.vtex
			context.state.existeSecoesLoja[idLoja] = null
			if (token) {
				const response = await ApiBotService.GetSessionsFromStore(token, idLoja, {
					q: null,
					idChannel: idChannel,
					categoriasExternas: categoriasExternas,
					produtoExterno: true,
				})
				if (response.data.sessions.length > 0) {
					context.state.existeSecoesLoja[idLoja] = true
				} else {
					context.state.existeSecoesLoja[idLoja] = null
				}
				context.state.secoesLoja[idLoja] = response.data.sessions.sort(function (a, b) {
					if (a.sessionName < b.sessionName) return -1
					if (a.sessionName > b.sessionName) return 1
					return 0
				})
			}
		} catch (error) {
			console.error('getSessoesLoja | error => ', error)
		}
	},

	async getLojasCliente(context) {
		let chatId = context.rootState.selectedChatQueue.chatId
		let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
		if (token && !context.state.lojasCliente[chatId]) {
			context.state.lojasCliente[chatId] = []
			let list = context.state.enderecosClientes[chatId]
			for (let i = 0; i < list.length; i++) {
				try {
					let idrede = process.env.VUE_APP_COD_REDE ? process.env.VUE_APP_COD_REDE : constants.ID_REDE
					const response = await ApiBotService.GetStoresByRede(token, idrede, {addressId: list[i].idAddress})
					for (let j = 0; j < response.data.length; j++) {
						if (
							!context.state.lojasCliente[chatId].some(
								x => x.restaurantId == response.data[j].restaurantId,
							)
						) {
							context.state.lojasCliente[chatId].push(response.data[j])
						}
					}
				} catch (error) {
					console.error('getLojasCliente | error => ', error)
				}
			}
		}
	},

	async getPedidoCliente(context, fromCart = false) {
		const {rootState, state} = context
		const {selectedChatQueue} = rootState
		const {chatId} = selectedChatQueue
		const idLoja = state.selectedLoja[chatId]
		const token = context.state.apiBotHub[chatId]?.token ?? null
		const hasIdExternoVtexProperty = !!selectedChatQueue.customVariables['@idExternoVtex']

		if (token && idLoja && hasIdExternoVtexProperty) {
			try {
				const idExternoVtex = selectedChatQueue.customVariables['@idExternoVtex']
				const {data} = await ApiBotService.GetPedido(token, idLoja, idExternoVtex)

				const allProductsDetails = await Promise.all(
					data.preOrder_List_Results.map(async product => {
						const {data} = await ApiBotService.GetProductDetails(
							token,
							idLoja,
							product.cod_Produto_Lojista_var.substring(0, 6),
						)

						return {
							...data.productSubItems[0],
							productItemSpecification: data.productSubItems[0].productItemSpecification
								?.trim()
								.split('\r\n'),
						}
					}),
				)

				const produtos = allProductsDetails.reduce((result, product) => {
					const matchingProduct = data.preOrder_List_Results.find(p =>
						p.nome_Produto_var.includes(product.productSubItemName),
					)

					if (matchingProduct) {
						const specifications =
							product.productItemSpecification
								.filter(specification =>
									matchingProduct.nome_Produto_var.split(' ').includes(specification),
								)
								.join(', ') || 'Único'

						matchingProduct.productItemSpecification = specifications
						result.push(matchingProduct)
					}

					return result
				}, [])

				if (produtos && produtos.length) {
					state.pedidos[chatId] = data.idPreOrder

					await Promise.all(
						produtos.map(async prod => {
							const produto = {
								id: prod.cod_Produto_int,
								sku: prod.cod_Produto_Lojista_var,
								desc: `${prod.nome_Produto_var} ${
									prod.nome_Item_Produto_var ? prod.nome_Item_Produto_var + ' ' : ''
								}${prod.nome_SubItem_Produto_var || ''}`,
								idItem: prod.cod_Item_Produto_Loja_int,
								idSubItem: prod.cod_SubItem_Produto_int,
								previousValue: prod.valor_PreOrdem_num + (prod.valor_Desconto_Produto_num ?? 0),
								sortimento: prod.productItemSpecification,
								valor: prod.valor_PreOrdem_num,
								qtd: prod.qtd_PreOrdem_int,
								desconto: prod.valor_Desconto_Produto_num ?? 0,
								estoque: 0,
							}

							produto.estoque = await context.dispatch('getEstoque', {
								produto,
								chatId,
								idLoja,
							})

							await context.dispatch('addProdutoCarrinho', {
								produto,
								chatId,
								preOrder: false,
								fromCart: fromCart,
							})
						}),
					)
				}
			} catch (error) {
				console.error('getPedidoCliente | error => ', error)
			}
		}
	},

	async addProdutoCarrinho(context, param) {
		let idLoja = context.state.selectedLoja[param.chatId]
		if (param.preOrder) {
			const response = await ApiBotService.AddProduto(
				context.state.apiBotHub[param.chatId].token,
				{
					idClient: context.state.apiBotHub[param.chatId].idClient,
					storeId: idLoja,
					value: param.produto.valor,
					quantity: param.produto.qtd,
					deliveryFee: 0,
					idRetailer: 0,
					idProduct: param.produto.id,
					idProductItem: param.produto.idItem,
					idProductSubitem: param.produto.idSubItem,
				},
				{
					idExterno: param.idExterno,
					qtdProduct: param.produto.qtd,
					itemIdExternal: param.produto.id,
					idWebSession: context.rootState.selectedChatQueue.chatUUID,
					idChannel: context.rootState.selectedChatQueue.customVariables['@idChannel'],
					adicionarCarrinhoExterno: true,
				},
			)

			context.state.pedidos[param.chatId] = response.data.idPreOrder

			let customVariables = context.rootState.selectedChatQueue.customVariables
			customVariables['@idExternoVtex'] = response.data.externalId

			await ChatService.UpdateCustomVarsByUuid(context.rootState.selectedChatQueue.uuid, customVariables)

			context.rootState.selectedChatQueue.customVariables = customVariables

			context.state.idExternoVtex[param.chatId] = response.data.externalId
		}

		if (!param.fromCart) {
			if (!context.state.carrinhoCliente[param.chatId]) {
				let produtos = []
				produtos.push(param.produto)
				context.state.carrinhoCliente[param.chatId] = produtos
			} else {
				const carrinhoCliente = context.state.carrinhoCliente
				const chatId = param.chatId
				const produto = param.produto

				const existingProduct = carrinhoCliente[chatId].find(item => item.id === produto.id)

				if (existingProduct) {
					existingProduct.qtd++
				} else {
					carrinhoCliente[chatId].push(produto)
				}
			}
		}
	},

	async deleteProdutoCarrinho(context, param) {
		return await ApiBotService.DeleteProduto(
			context.state.pedidos[param.chatId],
			context.state.apiBotHub[param.chatId].token,
			{
				idExterno: context.rootState.selectedChatQueue.customVariables['@idExternoVtex'],
				itemIdExternal: param.produto.id,
				adicionarCarrinhoExterno: true,
			},
			{
				idProduct: param.produto.id,
				idProductItem: param.produto.idItem,
				idProductSubitem: param.produto.idSubItem,
			},
		)
	},

	async getEstoque(context, param) {
		let token = context.state.apiBotHub[param.chatId].token
		let qtd = 0

		try {
			const response = await ApiBotService.GetProduto(token, param.idLoja, param.produto.sku.substring(0, 6), {
				itemProductId: param.produto.idItem === 0 ? null : param.produto.idItem,
				subitemProductId: param.produto.idSubItem === 0 ? null : param.produto.idSubItem,
				produtoExterno: true,
			})
			if (response.data.itemProductValues.length === 0) {
				qtd = response.data.qtdProdutoEstoque
			} else if (response.data.itemProductValues[0].subItemProductValues.length === 0) {
				qtd = response.data.qtdItemEstoque
			} else {
				qtd = response.data.itemProductValues[0].subItemProductValues[0].qtdSubItemEstoque
			}
		} catch (error) {
		} finally {
			return qtd
		}
	},

	async changeLoja(context, param) {
		let chatId = context.rootState.selectedChatQueue.chatId
		let idLoja = context.state.selectedLoja[chatId]
		let token = context.state.apiBotHub[chatId].token
		if (
			token &&
			idLoja &&
			context.state.carrinhoCliente[chatId] &&
			context.state.carrinhoCliente[chatId].length > 0
		) {
			await ApiBotService.DeletePedido(token, param.idLoja)
			context.state.pedidos[chatId] = 0
			context.state.carrinhoCliente[chatId] = []
			context.state.idExternoVtex[chatId] = []
			let produtos = param.carrinho
			let produto = {}
			for (let [i, prod] of produtos.entries()) {
				produto = {
					id: prod.id,
					sku: prod.sku,
					desc: prod.desc,
					idItem: prod.idItem,
					idSubItem: prod.idSubItem,
					valor: prod.valor,
					qtd: prod.qtd,
					estoque: prod.estoque,
					desconto: prod.desconto,
				}
				produto.estoque = await context.dispatch('getEstoque', {
					produto: produto,
					chatId: chatId,
					idLoja: idLoja,
				})
				produto.qtd = produto.qtd <= produto.estoque ? produto.qtd : produto.estoque

				const hasIdExternoVtexProperty = context.rootState.selectedChatQueue.customVariables['@idExternoVtex']
					? true
					: false

				context.dispatch('addProdutoCarrinho', {
					produto: produto,
					chatId: chatId,
					preOrder: true,
					idExterno: hasIdExternoVtexProperty
						? context.rootState.selectedChatQueue.customVariables['@idExternoVtex']
						: null,
				})
			}
		}
	},

	async finalizarPedido(context, param) {
		let idExternoVtex = context.rootState.selectedChatQueue.customVariables['@idExternoVtex']
		let token = context.state.apiBotHub[param.chatId].token
		let end = context.state.enderecosClientes[param.chatId]
		if (end == undefined && param.dadosCliente != null) {
			end =
				param.dadosCliente.crmEnderecoCliente && param.dadosCliente.crmEnderecoCliente.length > 0
					? param.dadosCliente.crmEnderecoCliente
					: null
		}
		if (token && context.state.carrinhoCliente[param.chatId] && end) {
			const response = await ApiBotService.FinalizarPedido(token, idExternoVtex, {
				idAddress: end[0].idAddress ? end[0].idAddress : end[0].codEnderecoZappag,
				idPaymentMethod: param.idPaymentMethod,
				requestedCashChange: 0,
				nameInInvoice: '',
				noteOrder: '',
				paymentMode: param.paymentMode,
				idUserATH: param.idUserATH,
				idCampaign: param.idCampaign,
				idExternalOrder: context.rootState.selectedChatQueue.customVariables['@idExternoVtex'],
				idRetailer: context.rootState.selectedChatQueue.customVariables['@idRevendedor'],
			})
			context.state.pedidoFinalizado[param.chatId] = {...response.data}
			context.rootState.selectedChatQueue.customVariables['@codPedido'] = response.data.idOrder

			if (param.cpfTerceiro) {
				await ApiBotService.AddOrderAddendum(token, response.data.idOrder, {
					addendumKey: 'terceiro.cpf',
					addendumValue: param.cpfTerceiro,
				})
			}
			if (param.terceiro) {
				await ApiBotService.AddOrderAddendum(token, response.data.idOrder, {
					addendumKey: 'terceiro.nome',
					addendumValue: param.terceiro,
				})
			}
		}
	},

	async finalizarPedidoVtex(context, param) {
		let idExternoVtex = context.rootState.selectedChatQueue.customVariables['@idExternoVtex']
		let token = context.state.apiBotHub[param.chatId].token

		if (token && context.state.carrinhoCliente[param.chatId]) {
			const response = await ApiBotService.FinalizarPedidoVetx(token, {
				idCartExterno: idExternoVtex,
				carrinhoExterno: true,
			})
			context.state.pedidoFinalizado[param.chatId] = {...response.data}
			context.rootState.selectedChatQueue.customVariables['@codPedido'] = idExternoVtex
		}
	},

	async clearCompra(context, chatId) {
		context.state.carrinhoCliente[chatId] = []
		context.state.idExternoVtex[chatId] = []
		context.state.pedidos[chatId] = null
		context.state.pedidoFinalizado[chatId] = null
	},

	async getLojasClienteDefault(context) {
		let chatId = context.rootState.selectedChatQueue.chatId
		let token = context.state.apiBotHub[chatId] ? context.state.apiBotHub[chatId].token : null
		let idLoja = context.state.selectedLoja[chatId]
		if (token && !context.state.lojasCliente[chatId]) {
			context.state.lojasCliente[chatId] = []
			try {
				const response = await ApiBotService.GetLoja(token, idLoja)
				context.state.lojasCliente[chatId].push(response.data)
			} catch (error) {}
		}
	},

	async getStore(context, id) {
		const {chatId} = context.rootState.selectedChatQueue
		const token = context.state.apiBotHub[chatId]?.token

		if (token) {
			try {
				const response = await ApiBotService.GetLoja(token, id)

				return response.data
			} catch (error) {
				console.error('getStore | error => ', error)
			}
		}
	},

	clearCountProduct(context, chatId) {
		let countProduct = localStorage.getItem('countProduct') ? JSON.parse(localStorage.getItem('countProduct')) : []

		if (countProduct.length > 0) {
			let ind = countProduct.findIndex(x => x.chave == chatId)
			if (ind != -1) {
				countProduct.splice(ind, 1)
				localStorage.setItem('countProduct', JSON.stringify(countProduct))
			}
		}
	},

	async incrementCountProduct(context, chatId) {
		let countProduct = localStorage.getItem('countProduct') ? JSON.parse(localStorage.getItem('countProduct')) : []
		let qtd = 1
		if (countProduct.length > 0) {
			let ind = countProduct.findIndex(x => x.chave == chatId)
			if (ind != -1) {
				countProduct[ind].valor++
				qtd = countProduct[ind].valor
				localStorage.setItem('countProduct', JSON.stringify(countProduct))
				return qtd
			}
		}
		let item = {chave: chatId, valor: qtd}
		countProduct.push(item)
		localStorage.setItem('countProduct', JSON.stringify(countProduct))
		return qtd
	},

	async clearIdExternoVetex(context) {
		let customVariables = context.rootState.selectedChatQueue.customVariables
		delete customVariables['@idExternoVtex']

		await ChatService.UpdateCustomVarsByUuid(context.rootState.selectedChatQueue.uuid, customVariables)

		context.rootState.selectedChatQueue.customVariables = customVariables

		delete context.state.idExternoVtex[context.rootState.selectedChatQueue.chatId]
	},
	async getShortenerUrlVtex(context) {
		try {
			const {chatId} = context.rootState.selectedChatQueue
			const {tokenVTEX, token} = context.rootState.ath.apiBotHub[chatId]
			const idExternoVtex = context.rootState.selectedChatQueue.customVariables['@idExternoVtex']

			const response = await ApiBotService.PostShortenerUrlVtex(token, {
				orderFormId: idExternoVtex,
				sessionVtex: tokenVTEX.sessionVtex,
				sessionGerCli: tokenVTEX.sessionGerCli,
			})

			return response.data
		} catch (error) {
			console.error('getShortenerUrlVtex | error => ', error)
		}
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
