import {environment} from '../environments/env'
import Api from './Api'
import {route} from './routeDictionary'

export default {
	SendMessageFacebook(arr) {
		return Api.post(`${environment.apiMessenger}${route.SendMessage}`, arr)
	},
	SendMessageInstagram(arr) {
		return Api.post(`${environment.apiInstagram}${route.SendMessage}`, arr)
	},
}
