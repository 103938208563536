
	import moment from 'moment'
	import {computed, reactive, ref, watch} from 'vue'
	import {useToast} from 'vue-toastification'
	import {useStore} from 'vuex'
	import Validar from '../../util/Validadores'
	import constants from '../../util/constants'
	import ClienteService from '../../services/ClienteService'
	import CalendarioService from '../../services/CalendarioService'
	import NumeroEnterpriseService from '../../services/NumeroEnterpriseService'
	import ChatService from '../../services/ChatService'
	import VueCal from 'vue-cal'
	import 'vue-cal/dist/vuecal.css'
	import Datepicker from 'vue3-datepicker'
	import {pt, es, enUS} from 'date-fns/locale'
	import Modal from '../../components/Modal'
	import EnderecoService from '../../services/EnderecoService'
	import {v4 as uuidv4} from 'uuid'
	import {useI18n} from 'vue-i18n'

	const toast = useToast()
	export default {
		components: {VueCal, Datepicker, Modal},
		props: {
			timezoneDefault: String,
		},
		setup(props, context) {
			const {tm, locale} = useI18n()
			const selectedDate = ref(new Date())
			const store = useStore()
			const stateLocal = reactive({
				title: '',
				id: null,
				observacao: '',
				clientId: null,
				dataInicio: new Date(),
				horaIni: '',
				nomeAgenda: '',
				nomeServico: '',
				eventsList: [],
				intervaloList: [],
				intervalo: 15,
				startWeekOnSunday: false,
				start: '',
				end: '',
				serviceId: 0,
				agendaId: 0,
				isLoading: false,
				isLoadingHorarios: false,
				timeFrom: 360,
				timeTo: 1380,
				selectedCliente: {},
				searchText: '',
				search: '',
				results: [],
				isOpen: false,
				showEvento: false,
				showHorario: false,
				showModalExcluir: false,
				modalCliente: false,
				cpf: '',
				isLoadingCEP: false,
				isLoadingCidade: false,
				isLoadingHorarios: false,
			})
			const agendaFilter = ref([])
			const selectedChatQueue = computed(() => {
				return store.state.selectedChatQueue
			})
			const cliente = reactive({
				id: 0,
				nome: '',
				sobrenome: '',
				rg: '',
				cpf: '',
				nomeMae: '',
				email: '',
				celular: '',
				empresaId: 0,
				crmEnderecoCliente: {},
				crmCanalCliente: [],
			})
			const paisList = ref([])
			const paisSelect = ref(1)
			const estadoList = ref([])
			const estadoSelect = ref(null)
			const cidadeList = ref([])
			const cidadeSelect = ref(null)
			const autocadastrozappag = ref(false)
			const numeroEnterprise = ref(null)
			const agendaList = ref([])
			const servicoList = ref([])
			const agendaId = ref(0)
			const serviceId = ref(0)
			const horariosDisponiveis = ref([])
			const dataInicio = ref(new Date())

			const events = computed(() => {
				return stateLocal.eventsList.filter(filterByAgenda)
			})
			const langCal = computed(() => {
				return locale.value == 'pt' ? 'pt-br' : locale.value
			})
			const langData = computed(() => {
				switch (locale.value) {
					case 'en':
						return enUS
					case 'es':
						return es
					case 'pt':
						return pt
					default:
						return pt
				}
			})

			//Watch
			watch(estadoSelect, newVal => {
				stateLocal.isLoadingCidade = true
				if (newVal) {
					EnderecoService.GetCidades(newVal)
						.then(response => {
							cidadeList.value = response.data
						})
						.catch(error => {
							//console.log(error);
						})
						.finally(() => {
							stateLocal.isLoadingCidade = false
						})
				} else {
					cidadeList.value = []
					cidadeSelect.value = null
					stateLocal.isLoadingCidade = false
				}
			})

			watch(serviceId, newVal => {
				if (newVal != 0) {
					getAgenda(newVal)
				} else {
					getAgendaAll()
				}
				getHorariosDisponiveis()
			})

			watch(agendaId, newVal => {
				if (newVal != 0) {
					getServico(newVal)
				} else {
					getServicoAll()
				}
				getHorariosDisponiveis()
			})

			watch(dataInicio, newVal => {
				getHorariosDisponiveis()
			})

			async function getAgendaAll() {
				try {
					agendaList.value = []
					const response = await CalendarioService.GetAgendaByAgent(store.state.usuario.id)
					agendaList.value = response.data
				} catch (error) {
					//console.log(error);
				}
			}

			async function getAgenda(servico) {
				try {
					agendaList.value = []
					const response = await CalendarioService.GetAgendaListByService(servico, store.state.usuario.id)
					agendaList.value = response.data
				} catch (error) {
					//console.log(error);
				}
			}

			async function getServicoAll() {
				try {
					const response = await CalendarioService.GetServicoByEmpresa(store.state.usuario.empresaId)
					servicoList.value = response.data
				} catch (error) {
					//console.log(error);
					servicoList.value = []
				}
			}

			async function getServico(agenda) {
				try {
					const response = await CalendarioService.GetServiceListByAgenda(agenda)
					servicoList.value = response.data
				} catch (error) {
					//console.log(error);
					servicoList.value = []
				}
			}

			async function getHorariosDisponiveis() {
				if (
					!stateLocal.id &&
					(serviceId.value != stateLocal.serviceId || agendaId.value != stateLocal.agendaId)
				) {
					clearHorario()
				}
				horariosDisponiveis.value = []
				if (dataInicio.value && (agendaId.value != 0 || serviceId.value != 0)) {
					try {
						stateLocal.isLoadingHorarios = true
						const response = await CalendarioService.GetHorariosDisponiveis({
							empresaId: store.state.usuario.empresaId,
							agendaId: agendaId.value,
							servicoId: serviceId.value,
							date: moment(dataInicio.value).format('YYYY-MM-DD'),
							singleDay: true,
						})
						horariosDisponiveis.value = response.data
					} catch (e) {
						//console.log(e);
					} finally {
						stateLocal.isLoadingHorarios = false
					}
				}
			}

			function setHorario(val) {
				stateLocal.start = val.start
				stateLocal.end = val.end
				stateLocal.horaIni = val.timeSpan
				agendaId.value = val.agendaId
				serviceId.value = val.serviceId
				stateLocal.serviceId = val.serviceId
				stateLocal.agendaId = val.agendaId
				stateLocal.nomeAgenda = val.agendaName
				stateLocal.nomeServico = val.serviceName
			}

			function clearHorario() {
				stateLocal.start = ''
				stateLocal.end = ''
				stateLocal.horaIni = ''
				stateLocal.nomeAgenda = ''
				stateLocal.nomeServico = ''
				stateLocal.serviceId = 0
				stateLocal.agendaId = 0
			}

			function filterByAgenda(obj) {
				if (agendaFilter.value.find(element => element.id === obj.agendaId)) {
					return true
				} else {
					return false
				}
			}

			function setAgendaFilter(value) {
				agendaFilter.value = value
				limparDados()
			}

			async function fetchEvents({view, startDate, endDate, week}) {
				selectedDate.value = new Date(startDate)
				try {
					stateLocal.isLoading = true
					const response = await CalendarioService.GetEventoListByAgent(
						store.state.usuario.id,
						startDate,
						endDate,
					)
					stateLocal.eventsList = response.data.events
					stateLocal.intervalo = response.data.timeResolution != 0 ? response.data.timeResolution : 30
					stateLocal.timeFrom =
						response.data.calendarTimeFrom && response.data.calendarTimeFrom != 0
							? response.data.calendarTimeFrom
							: 360
					stateLocal.timeTo =
						response.data.calendarTimeTo && response.data.calendarTimeTo != 0
							? response.data.calendarTimeTo
							: 1380
					stateLocal.startWeekOnSunday = response.data.weekStartDay === 7
				} catch (error) {
					//console.log(error);
				} finally {
					stateLocal.isLoading = false
				}
			}
			function getNumeroEnterprise() {
				NumeroEnterpriseService.GetNumberByEmpresa({
					empresaId: store.state.usuario.empresaId,
					noSmbFilter: true,
				})
					.then(response => {
						if (response.data && response.data.length >= 0) {
							numeroEnterprise.value = response.data[0]
						}
					})
					.catch(error => {})
			}

			async function criarEvento() {
				limparDados()
				stateLocal.showEvento = true
			}

			function limparDados() {
				stateLocal.id = null
				stateLocal.title = ''
				stateLocal.start = ''
				stateLocal.end = ''
				agendaId.value = 0
				stateLocal.serviceId = 0
				stateLocal.agendaId = 0
				serviceId.value = 0
				stateLocal.observacao = ''
				dataInicio.value = new Date()
				stateLocal.horaIni = ''
				stateLocal.nomeAgenda = ''
				stateLocal.nomeServico = ''
				stateLocal.results = []
				stateLocal.search = ''
				stateLocal.isOpen = false
				stateLocal.clientId = null
				stateLocal.selectedCliente = {}
				stateLocal.cpf = ''
				if (
					store.state.selectedChatQueue &&
					store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
				) {
					stateLocal.clientId = store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId].id
					stateLocal.selectedCliente = store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId]
					setResult(store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId])
					stateLocal.results.push(store.state.cliente.dadosCliente[store.state.selectedChatQueue.chatId])
					stateLocal.cpf = stateLocal.selectedCliente.cpf
						? Validar.formatCnpjCpf(stateLocal.selectedCliente.cpf)
						: ''
				}
			}

			function onEventClick(event, e) {
				stateLocal.id = event.id
				stateLocal.title = event.title
				agendaId.value = event.agendaId
				stateLocal.nomeAgenda = event.agenda ? event.agenda.name : ''
				serviceId.value = event.serviceId ? event.serviceId : 0
				stateLocal.nomeServico = event.service ? event.service.name : ''
				stateLocal.observacao = event.observation
				dataInicio.value = event.start
				stateLocal.horaIni = moment(event.start).format('HH:mm') + '/' + moment(event.end).format('HH:mm')
				stateLocal.clientId = event.clientId
				stateLocal.selectedCliente = {}
				stateLocal.results = []
				stateLocal.search = ''
				stateLocal.isOpen = false
				if (stateLocal.clientId) {
					getCliente()
				}
				stateLocal.start = moment(event.start).format('YYYY-MM-DD HH:mm')
				stateLocal.end = moment(event.end).format('YYYY-MM-DD HH:mm')
				stateLocal.showEvento = true
				toast.info(tm('msgAgenda08'))
				e.stopPropagation()
			}

			function getCliente() {
				ClienteService.GetClienteById(stateLocal.clientId)
					.then(response => {
						setResult(response.data)
						stateLocal.results.push(response.data)
					})
					.catch(error => {})
			}
			function onChange() {
				searchClientes()
				stateLocal.isOpen = true
			}

			function searchClientes() {
				stateLocal.selectedCliente = {}
				if (stateLocal.search && stateLocal.search.trim().length > 0) {
					ClienteService.GetClienteByNomeOrCpf(store.state.usuario.empresaId, stateLocal.search)
						.then(response => {
							stateLocal.results = response.data
						})
						.catch(error => {
							stateLocal.results = []
						})
				} else {
					stateLocal.results = []
				}
			}

			function setResult(result) {
				stateLocal.selectedCliente = result
				stateLocal.search = result.nome
				stateLocal.cpf = result.cpf ? Validar.formatCnpjCpf(result.cpf) : ''
				stateLocal.isOpen = false
			}

			async function salvar() {
				if (!dadosEvento()) {
					return false
				}

				let args = {
					title: stateLocal.title,
					startDate: stateLocal.start,
					endDate: stateLocal.end,
					color: null,
					observation: stateLocal.observacao,
					extraData: null,
					crmClientId: stateLocal.selectedCliente.id ? stateLocal.selectedCliente.id : null,
					serviceId: serviceId.value,
				}
				try {
					if (!stateLocal.id) {
						const res = await CalendarioService.AddEvento(agendaId.value, args)
						toast.info(tm('msgAgenda09'))
					} else {
						const resp = await CalendarioService.UpdateEvento(stateLocal.id, args)
						toast.info(tm('msgAgenda10'))
					}
				} catch (err) {
					//console.log(err);
					toast.warning(tm('erroAgenda01'))
				} finally {
					await fetchEvents({
						view: 'day',
						startDate: moment(selectedDate.value).format('YYYY-MM-DD') + ' 00:00',
						endDate: moment(selectedDate.value).format('YYYY-MM-DD') + ' 23:59',
						week: '',
					})
					limparDados()
					stateLocal.showEvento = false
				}
			}

			function dadosEvento() {
				if (!stateLocal.title) {
					document.getElementById('title').focus()
					toast.warning(tm('erroAgenda02'))
					return false
				}
				if (!agendaId.value || agendaId.value == 0) {
					document.getElementById('agendaId').focus()
					toast.warning(tm('erroAgenda03'))
					return false
				}
				if (!serviceId.value || serviceId.value == 0) {
					document.getElementById('serviceId').focus()
					toast.warning(tm('erroAgenda04'))
					return false
				}
				if (!dataInicio.value) {
					toast.warning(tm('erroAgenda05'))
					return false
				}
				if (!stateLocal.start) {
					toast.warning(tm('erroAgenda06'))
					return false
				}
				if (!stateLocal.search) {
					document.getElementById('search').focus()
					toast.warning(tm('erroAgenda07'))
					return false
				}
				return true
			}

			async function excluir() {
				if (!stateLocal.id) {
					return false
				}
				try {
					const res = await CalendarioService.DeleteEvento(stateLocal.id)
					toast.info(tm('msgAgenda11'))
				} catch (err) {
					//console.log(err);
					toast.warning(tm('erroAgenda08'))
				} finally {
					await fetchEvents({
						view: 'day',
						startDate: moment(selectedDate.value).format('YYYY-MM-DD') + ' 00:00',
						endDate: moment(selectedDate).format('YYYY-MM-DD') + ' 23:59',
						week: '',
					})
					limparDados()
					stateLocal.showEvento = false
					stateLocal.showModalExcluir = false
				}
			}

			function criarCliente() {
				cliente.id = 0
				cliente.nome = ''
				cliente.sobrenome = ''
				cliente.rg = ''
				cliente.cpf = ''
				cliente.nomeMae = ''
				cliente.email = ''
				cliente.celular = ''
				cliente.empresaId = store.state.usuario.empresaId
				cliente.crmEnderecoCliente = {}
				cliente.crmCanalCliente = []
				paisSelect.value = getCodigoPais('brasil')
				estadoSelect.value = null
				cidadeSelect.value = null
				stateLocal.modalCliente = true
			}

			async function salvarCliente() {
				const dadosOk = await dadosCliente()
				if (!dadosOk) {
					return false
				}
				let canais = []
				if (cliente.email) {
					canais.push({tipo: constants.TipoCanal.indexOf('EMAIL'), valor: cliente.email})
				}
				if (cliente.celular) {
					let tipo =
						numeroEnterprise.value && numeroEnterprise.value.usuario != 'smbdummy'
							? constants.TipoCanal.indexOf('WHATSAPP')
							: constants.TipoCanal.indexOf('WHATSAPPBUSINESS')
					canais.push({
						tipo: tipo,
						valor: cliente.celular
							.replaceAll('+', '')
							.replaceAll('(', '')
							.replaceAll(')', '')
							.replaceAll('-', '')
							.replaceAll(' ', ''),
					})
				}
				await verificaCEP()
				cliente.crmEnderecoCliente.pais = paisSelect.value ? getNomePais(paisSelect.value) : ' '
				cliente.crmEnderecoCliente.estado = estadoSelect.value ? getNomeEstado(estadoSelect.value) : ' '
				cliente.crmEnderecoCliente.cidade = cidadeSelect.value ? getNomeCidade(cidadeSelect.value) : ' '
				cliente.crmEnderecoCliente.codPais = paisSelect.value
				cliente.crmEnderecoCliente.codEstado = estadoSelect.value
				cliente.crmEnderecoCliente.codCidade = cidadeSelect.value
				let endereco = {...cliente.crmEnderecoCliente}
				endereco.cep = endereco.cep ? endereco.cep.replaceAll('-', '') : ''
				ClienteService.AddCliente({
					nome: cliente.nome,
					nomeMae: cliente.nomeMae,
					sobrenome: cliente.sobrenome,
					cpf: cliente.cpf ? cliente.cpf.replaceAll('.', '').replaceAll('-', '') : '',
					rg: cliente.rg,
					empresaId: cliente.empresaId,
					crmEnderecoCliente: [endereco],
					crmCanalCliente: canais,
				})
					.then(res => {
						toast.info(tm('msgCliente01'))
						setResult(res.data)
						stateLocal.results.push(res.data)
						stateLocal.modalCliente = false
					})
					.catch(error => {
						//console.log(error);
						toast.warning(tm('erroCliente01'))
					})
			}

			async function dadosCliente() {
				if (!cliente.nome) {
					document.getElementById('nomeCliente').focus()
					toast.warning(tm('erroCliente03'))
					return false
				}
				if (!cliente.sobrenome) {
					document.getElementById('sobrenome').focus()
					toast.warning(tm('erroCliente04'))
					return false
				}
				if (autocadastrozappag.value && !cliente.celular) {
					document.getElementById('celularCliente').focus()
					toast.warning(tm('erroCliente09'))
					return false
				}
				if (autocadastrozappag.value && !cliente.crmEnderecoCliente.cep) {
					document.getElementById('inputCep').focus()
					toast.warning(tm('erroCliente06') + '!')
					return false
				}
				if (autocadastrozappag.value && !estadoSelect.value) {
					document.getElementById('inputEstado').focus()
					toast.warning(tm('erroCliente07'))
					return false
				}
				if (autocadastrozappag.value && !cidadeSelect.value) {
					document.getElementById('inputCidade').focus()
					toast.warning(tm('erroCliente08'))
					return false
				}
				if (cliente.email) {
					try {
						const resEmail = await ClienteService.getClienteByChannel(
							store.state.usuario.empresaId,
							cliente.email,
							constants.TipoCanal.indexOf('EMAIL'),
						)
						if (resEmail.data) {
							document.getElementById('emailCliente').focus()
							toast.warning(tm('erroCliente10') + resEmail.data.nome + ' CPF: ' + resEmail.data.cpf)
							return false
						}
					} catch (error) {
						//console.log(error);
						toast.warning(tm('erroCliente11'))
						return false
					}
				}
				if (cliente.celular) {
					try {
						const resCelular = await ClienteService.getClienteByChannel(
							store.state.usuario.empresaId,
							cliente.celular
								.replaceAll('+', '')
								.replaceAll('(', '')
								.replaceAll(')', '')
								.replaceAll('-', '')
								.replaceAll(' ', ''),
							constants.TipoCanal.indexOf('WHATSAPP'),
						)
						if (resCelular.data) {
							document.getElementById('celularCliente').focus()
							toast.warning(tm('erroCliente12') + resCelular.data.nome + ' CPF: ' + resCelular.data.cpf)
							return false
						}
						const resCelularB = await ClienteService.getClienteByChannel(
							store.state.usuario.empresaId,
							cliente.celular
								.replaceAll('+', '')
								.replaceAll('(', '')
								.replaceAll(')', '')
								.replaceAll('-', '')
								.replaceAll(' ', ''),
							constants.TipoCanal.indexOf('WHATSAPPBUSINESS'),
						)
						if (resCelularB.data) {
							document.getElementById('celularCliente').focus()
							toast.warning(tm('erroCliente12') + resCelularB.data.nome + ' CPF: ' + resCelularB.data.cpf)
							return false
						}
					} catch (error) {
						console.debug(error)
						toast.warning(tm('erroCliente11'))
						return false
					}
				}
				return true
			}

			function setaAtocadastrozappag(value) {
				autocadastrozappag.value = value
			}

			async function buscarCep() {
				if (cliente.crmEnderecoCliente.cep) {
					try {
						stateLocal.isLoadingCEP = true
						const res = await EnderecoService.ConsultaEndereco(cliente.crmEnderecoCliente.cep)
						paisSelect.value = res.data.codPais
						estadoSelect.value = res.data.codEstado
						cidadeSelect.value = res.data.codCidade
						cliente.crmEnderecoCliente.endereco = res.data.viaCep.logradouro
						cliente.crmEnderecoCliente.bairro = res.data.viaCep.bairro
						cliente.crmEnderecoCliente.cidade = res.data.viaCep.localidade
						cliente.crmEnderecoCliente.estado = res.data.viaCep.uf
					} catch (err) {
						//console.log(err);
						estadoSelect.value = null
						cidadeSelect.value = null
						toast.warning(tm('erroCLiente13'))
					} finally {
						stateLocal.isLoadingCEP = false
					}
				}
			}

			function getPaises() {
				EnderecoService.GetPaises()
					.then(response => {
						paisList.value = response.data
						paisSelect.value = getCodigoPais('brasil')
						getEstados()
					})
					.catch(error => {
						//console.log(error);
					})
			}

			function getEstados() {
				EnderecoService.GetEstados(paisSelect.value)
					.then(response => {
						estadoList.value = response.data
					})
					.catch(error => {
						//console.log(error);
					})
			}

			function getNomePais(value) {
				let pais = paisList.value.find(x => x.codigo === value)
				return pais ? pais.nome : ''
			}
			function getCodigoPais(value) {
				let pais = paisList.value.find(x => x.nome.toLowerCase() === value.toLowerCase())
				return pais ? pais.codigo : 1
			}
			function getNomeEstado(value) {
				let estado = estadoList.value.find(x => x.codigo === value)
				return estado ? estado.uf : ''
			}

			function getNomeCidade(value) {
				let cidade = cidadeList.value.find(x => x.codigo === value)
				return cidade ? cidade.nome : ''
			}

			async function verificaCEP() {
				if (
					cliente.crmEnderecoCliente.cep &&
					(!paisSelect.value || !estadoSelect.value || !cidadeSelect.value)
				) {
					try {
						const res = await EnderecoService.ConsultaEndereco(cliente.crmEnderecoCliente.cep)
						paisSelect.value = res.data.codPais
						estadoSelect.value = res.data.codEstado
						cidadeSelect.value = res.data.codCidade
						cliente.crmEnderecoCliente.cidade = res.data.viaCep.localidade
						cliente.crmEnderecoCliente.estado = res.data.viaCep.uf
					} catch (err) {
						//console.log(err);
					}
				}
			}

			async function gerarAtendimento(obj) {
				if (!obj.valor) {
					toast.warning(tm('erroGerarAth02'))
					return false
				}
				for (const chat of store.state.chatsTableData) {
					if (chat.chatId === obj.valor) {
						toast.warning(tm('erroGerarAth03'))
						return false
					}
				}
				let myuuid = uuidv4()
				let mediaId = localStorage.getItem('mediaId')
				let custom = {
					'@idLoja': store.state.usuario.empresa.idLojaManager
						? store.state.usuario.empresa.idLojaManager
						: '',
					MediaId: numeroEnterprise.value ? numeroEnterprise.value.mediaId : mediaId ? mediaId : '',
				}
				let param = {
					empresaId: store.state.usuario.empresaId,
					flowUUID: numeroEnterprise.value ? numeroEnterprise.value.roboFlowId : 'ATH Chat',
					chatUUID: myuuid,
					flowName: 'ATH Chat',
					channel: obj.tipo,
					priority: 50,
					GoalPoint: false,
					queueId: 'ATH' + store.state.usuario.empresaId,
					websocket: '',
					chatId: obj.valor,
					agentId: store.state.usuario.id,
					instanceId: 'SMB',
				}
				ChatService.GerarAtendimento(custom, param)
					.then(response => {
						toast.info(tm('msgGerarAth'))
					})
					.catch(error => {
						console.debug(error)
					})
					.finally(() => {
						context.emit('getChats')
					})
			}

			//created
			// getPaises()
			getNumeroEnterprise()
			getAgendaAll()
			getServicoAll()

			return {
				moment,
				props,
				store,
				stateLocal,
				selectedChatQueue,
				events,
				fetchEvents,
				onEventClick,
				criarEvento,
				selectedDate,
				pt,
				es,
				enUS,
				agendaFilter,
				onChange,
				setAgendaFilter,
				setResult,
				salvar,
				excluir,
				criarCliente,
				salvarCliente,
				cliente,
				paisSelect,
				estadoList,
				estadoSelect,
				cidadeList,
				cidadeSelect,
				buscarCep,
				setaAtocadastrozappag,
				autocadastrozappag,
				gerarAtendimento,
				constants,
				tm,
				agendaId,
				serviceId,
				agendaList,
				servicoList,
				setHorario,
				horariosDisponiveis,
				dataInicio,
				clearHorario,
				langCal,
				langData,
			}
		},
	}
