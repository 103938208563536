import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cbf6a07"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "chip-container rounded scrolling",
  style: {"max-height":"6rem !important","overflow-y":"auto"}
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["placeholder"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.chips, (chip, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: chip.label,
        class: "chip"
      }, [
        _createTextVNode(_toDisplayString(chip) + " ", 1),
        _createElementVNode("b", {
          class: "clear-icon my-auto ml-2",
          onClick: $event => ($setup.deleteChip(i))
        }, "x", 8, _hoisted_2)
      ]))
    }), 128)),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.currentInput) = $event)),
      placeholder: $setup.tm('msgEmail02'),
      type: "email",
      pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      autofocus: "",
      required: "",
      onKeypress: _cache[1] || (_cache[1] = _withKeys((...args) => ($setup.saveChip && $setup.saveChip(...args)), ["enter","space"])),
      onKeydown: [
        _cache[2] || (_cache[2] = _withKeys((...args) => ($setup.saveChip && $setup.saveChip(...args)), ["tab"])),
        _cache[3] || (_cache[3] = _withKeys((...args) => ($setup.backspaceDelete && $setup.backspaceDelete(...args)), ["delete"]))
      ]
    }, null, 40, _hoisted_3), [
      [_vModelText, $setup.currentInput]
    ])
  ]))
}