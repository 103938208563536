
	import {computed} from 'vue'
	import differenceInDays from 'date-fns/differenceInDays'
	import differenceInHours from 'date-fns/differenceInHours'
	import moment from 'moment'
	import {useI18n} from 'vue-i18n'

	export default {
		name: 'ChatHistoryItem',
		props: {
			chatItem: {
				type: Object,
			},
		},
		setup(props) {
			const {tm} = useI18n()
			const calcularDiferencaDias = computed(() => {
				let diferenca = differenceInDays(new Date(), new Date(props.chatItem.dateStarted))

				if (diferenca < 1) {
					diferenca = differenceInHours(new Date(), new Date(props.chatItem.dateStarted))
					return diferenca + tm('horasAtras')
				}
				return diferenca + tm('diasAtras')
			})

			return {
				moment,
				calcularDiferencaDias,
				tm,
			}
		},
	}
